
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

@Component
export default class ButtonBase extends Vue {
    @Prop(Boolean)
    confirmsAction: boolean

    @Prop()
    icon: string

    @Prop({ default: "!text-white" })
    iconClass: string

    @Prop()
    text: string

    @Prop({ default: "bg-orange100" })
    bgClass: string

    @Prop({ default: "text-white" })
    textClass: string

    @Prop()
    confirmText: string

    @Prop()
    confirmTextClass: string

    @Prop()
    confirmIconClass: string

    @Prop()
    confirmBg: string

    @Prop(Boolean)
    disabled: boolean

    @Prop()
    to: string

    async click() {
        this.$emit('preclick');
        if(this.confirmsAction) {
            if(!await this.$openDialog(import("../components-internal/ConfirmDialog.vue"), {
                title: this.confirmText || this.$t('basic.doYouWantTo', { action: this.text }),
                confirm: this.text,
                confirmText: this.confirmTextClass || this.textClass,
                confirmIcon: this.icon,
                confirmIconClass: this.confirmIconClass || this.iconClass,
                confirmBg: this.confirmBg || this.bgClass,
                
            }, {
                maxWidth: '400px',
            })) {
                return;
            }
        }

        this.$emit('click');
        if(this.to) {
          this.$router.push(this.to)
        }
    }
}

