

import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class MenuListInput extends Vue {

    @VModel()
    inputValue: string

    @Prop()
    enterkeyhint: string

    @Prop(Boolean)
    selectAll: boolean

    @Prop({ type: String, default: 'text' })
    type: string

    goNext() {
        if(this.enterkeyhint === 'next') {
            const items = Array.from(document.querySelectorAll('.menu-list-input')) as HTMLInputElement[];
            const curIdx = items.indexOf(this.$el as HTMLInputElement);
            if(curIdx !== -1) {
                items[curIdx + 1]?.focus?.();
            }
        } else if(this.enterkeyhint === 'done') {
            (this.$el as HTMLInputElement).blur();
        }
        this.$emit('submit');
    }

    onFocus() {
        if(this.selectAll) {
            (this.$el as HTMLInputElement)?.select?.();
        }
        this.$emit('focus');
    }

    onBackspace() {
        if(!this.inputValue) {
            this.$emit('backspace');
        }
    }

    focus() {
        (this.$el as HTMLInputElement)?.focus?.();
    }

    blur() {
        (this.$el as HTMLInputElement)?.blur?.();
    }
}

