
import { Component, Prop, Vue } from "@feathers-client";

@Component
export default class extends Vue {
    @Prop({ type: String })
    value: string;

    get verifyStatusStyle() {
        switch (this.value) {
            case "needDocument":
                return "";
            case "pending":
                return "error";
            case "verified":
                return "teal white--text";
            default:
                return "";
        }
    }

    get icon() {
        switch (this.value) {
            case "needDocument":
                return "";
            case "pending":
                return "error";
            case "verified":
                return "check";
            default:
                return "";
        }
    }
}
