var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "event",
          rawName: "v-event.swipeOpen",
          value: _vm.opened && _vm.onSwipeOpen,
          expression: "opened && onSwipeOpen",
          modifiers: { swipeOpen: true },
        },
      ],
      staticClass: "relative swipe-action",
      on: {
        "!touchstart": function ($event) {
          return _vm.mousedown.apply(null, arguments)
        },
        "!mousedown": function ($event) {
          return _vm.mousedown.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          ref: "right",
          staticClass: "swipe-right-container",
          on: { click: _vm.onSwipeOpen },
        },
        [_vm._t("right")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }