

import { Component, Prop, Vue, Watch, mixins } from "@feathers-client";
import type {
    SearchField,
} from "./plugin";
import moment from 'moment'

@Component
export default class EditorDatePickerPreset extends Vue {

    @Prop()
    item: SearchField

    @Prop({ default: '00:00' })
    dateCutOffTime: string

    get withinDay() {
        return this.item.cond === 'inRange' ? this.item.value1 ? moment.duration(moment(this.item.value2).diff(this.item.value1)).asHours() <= 24 : false : !!this.item.value1;
    }

    get current() {
        const now = this.items.find(it => +it.start === +this.item.value1 && (this.item.cond !== 'inRange' || +it.end === +this.item.value2));

        return now?._id;
    }

    set current(id: string) {
        const now = this.items.find(it => it._id === id);
        if(now) {
            this.item.value1 = now.start;
            this.item.value2 = now.end;
        } else {
            this.item.value1 = '';
            this.item.value2 = '';
        }
    }

    normalizedDay(date) {
        const time = this.dateCutOffTime?.split?.(':');
        const hh = isNaN(+time?.[0]) ? 0 : +time?.[0];
        const mm = isNaN(+time?.[1]) ? 0 : +time?.[1];

        const now = moment();
        const day = moment().startOf('day').add(hh, 'hours').add(mm, 'minutes');
        const cur = moment(date).add(hh, 'hours').add(mm, 'minutes');

        if(day.isAfter(now)) {
            return cur.subtract(1, 'day').toDate();
        }
        return cur.toDate();
    }

    get items() {
        const today = moment().startOf('day');

        const todayStart = this.normalizedDay(today);
        const todayEnd = moment(todayStart).add(1, 'day').toDate();
        
        const yesterdayStart = moment(todayStart).subtract(1, 'day').toDate();
        const yesterdayEnd = moment(yesterdayStart).add(1, 'day').toDate();

        const weekStart = moment(todayStart).subtract(moment(todayStart).get('isoWeek') - 1, 'day').toDate();
        const weekEnd = moment(weekStart).add(7, 'day').toDate();

        const lastWeekStart = moment(weekStart).subtract(7, 'day').toDate();
        const lastWeekEnd = moment(lastWeekStart).add(7, 'day').toDate();

        const monthStart = moment(todayStart).subtract(moment(todayStart).get('month') - 1, 'day').toDate();
        const monthEnd = moment(monthStart).add(1, 'month').toDate();

        const lastMonthStart = moment(monthStart).subtract(1, 'month').toDate();
        const lastMonthEnd = moment(lastMonthStart).add(1, 'month').toDate();

        return [
            { _id: 'today', name: this.$t('today'), start: todayStart, end: todayEnd },
            { _id: 'yesterday', name: this.$t('yesterday'), start: yesterdayStart, end: yesterdayEnd },

            ...(this.item.cond === 'inRange' ? [
                { _id: 'week', name: this.$t('week'), start: weekStart, end: weekEnd },
                { _id: 'lastWeek', name: this.$t('lastWeek'), start: lastWeekStart, end: lastWeekEnd },
                { _id: 'month', name: this.$t('month'), start: monthStart, end: monthEnd },
                { _id: 'lastMonth', name: this.$t('lastMonth'), start: lastMonthStart, end: lastMonthEnd },
            ] : []),
        ]
    }

    navPrev() {
        this.item.value1 = moment(this.item.value1).subtract(1, 'day').toDate();
        this.item.value2 = moment(this.item.value2).subtract(1, 'day').toDate();
    }

    navNext() {
        this.item.value1 = moment(this.item.value1).add(1, 'day').toDate();
        this.item.value2 = moment(this.item.value2).add(1, 'day').toDate();
    }

}


