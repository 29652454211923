import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import Vue from 'vue'
import { Component, Prop, Watch, mixins } from "nuxt-property-decorator";
import type VueRouter from 'vue-router'
import VueI18n from 'vue-i18n';
import url from 'url'
import qs from 'querystring'
//@ts-ignore
import { loadLanguageAsync } from '@nuxt-root/nuxt-i18n/plugin.utils'

Vue.filter('tf', function(ctx, value, item, parent, header) {
    const t = _.get(value, header.tpath + 't');
    const v = (!_.isEmpty(t) && t) || _.get(value, header.tpath) ;
    return ctx.$td(v);
})

Vue.filter('t', function(ctx, value, item, parent, header) {
    return ctx.$td(value);
})

export type LangArrType = { lang: string, value: string }[];
export type LangObjType = { $t?: string, $join? : LangType[], $a? : any, $ta?: { [key : string] : LangType} }
export type LangType = LangArrType | LangObjType | string;
type Fallback = { path: LangType, args?: { [key : string] : LangType} };
export type LangFallbackType = LangType | Fallback

export type LangGenericType<T = any> = { lang: string, value: T }[];
export type LangInlineType<T = any> = (T & { lang: string })[];

declare let PRODUCTION_MODE : boolean;

export function bind(el, binding, vnode) {
    t(el, binding, vnode)
}

function localeEqual(el, vnode) {
    const vm = vnode.context
    return el._locale === vm.$i18n.locale
}

export function update(el, binding, vnode, oldVNode) {
    if (localeEqual(el, vnode) && _.isEqualWith(binding.value, binding.oldValue)) return;
    t(el, binding, vnode)
}

function t(el, binding, vnode) {
    let value = binding.value
    let result = '';

    const vm = vnode.context
    if (!vm) {
        console.warn('not exist Vue instance in VNode context')
        return
    }
    const i18n = vm.$i18n;
    if (!i18n) {
        console.warn('not exist VueI18n instance in Vue instance')
        return
    }
    const mappedLocale = mappedLocales[i18n.locale] || i18n.locale;

    if (value) {
        if (value.$join) {
            return _.map(value.$join, it => vm.$td(it)).join('');
        }
        let $ta = value.$ta;
        let $a = value.$a;
        if (value.path) {
            if (value.args) $ta = value.args;
            value = value.path;
        }

        if (typeof value === 'string') {
            result = value;
        } else if (_.isArray(value)) {
            let enValue, locValue, defValue;
            _.each(value, v => {
                if (v.lang === mappedLocale) locValue = v.value;
                if (v.lang === 'en') enValue = v.value;
                defValue = v.value;
            })
            result = locValue || enValue || defValue;
        } else if (typeof value === 'object') {
            if (value.$t) {
                if ($ta) {
                    const args = {
                        ...$a,
                        ..._.mapValues($ta, it => vm.$td(it))
                    }
                    result = vm.$i18n.t(value.$t, args);
                } else if ($a) {
                    result = vm.$i18n.t(value.$t, $a);
                } else {
                    result = i18n.t(value.$t);
                }
            } else {
                result = value[mappedLocale] || value['en'] || _.map(value, it => it)[0] || '';
            }
        }
    }

    el._vt = el.textContent = result;
    el._locale = mappedLocale;
}

Vue.directive('td', {
    bind,
    update,
})

let i18NInit = false;

Vue.prototype.$ensureI18N = function() {
    if (process.browser) {
        if (i18NInit) return;
        const m = this.$i18n.locales.find(it => it.code ===this.$i18n.locale);
        moment.locale(m.moment || m.iso);
        i18NInit = true;
    }
}

const mappedLocales = {
    'zh-hk': 'cht',
    'zh-cn': 'chs',
    'en-us': 'en',
    'en-hk': 'en',
    'en': 'en',
}
const unmappedLocales = {
    'cht': 'zh-hk',
    'chs': 'zh-cn',
}

Vue.prototype.$td = function(item, locale) {
    if (typeof item === 'string') return item;
    else if (!item) return '';

    const i18n : VueI18n = this.$i18n || this.$root.$i18n;
    const unmapLocale = locale ? unmappedLocales[locale] || locale : i18n.locale;
    locale = locale || i18n.locale;
    locale = mappedLocales[locale] || locale;

    if (item.$join) {
        return _.map(item.$join, it => this.$td(it, locale)).join('');
    } else if (item.$t) {
        if (item.$ta) {
            const args = {
                ...item.$a,
                ..._.mapValues(item.$ta, it => this.$td(it, locale))
            }
            return i18n.t(item.$t, unmapLocale, args);
        } else if (item.$a) {
            return i18n.t(item.$t, unmapLocale, item.$a);
        } else {
            return i18n.t(item.$t, unmapLocale, {});
        }
    } else if (_.isArray(item)) {
        let enValue, locValue, defValue;
        _.each(item, v => {
            if (v.lang === locale) locValue = v.value;
            if (v.lang === 'en') enValue = v.value;
            defValue = v.value;
        })
        return locValue || enValue || defValue;
    }

    return item[locale] || item['en'] || _.map(item, it => it)[0] || '';
};

Vue.prototype.$tdf = function(item, fallback) {
    return (!_.isEmpty(item) && this.$td(item)) || fallback;
}

Vue.prototype.$tObj = function<T extends { lang: string }> (item : T[], locale? : string, fallback = true): T {
    if (typeof item === 'string') return item;
    else if (!item) return null;
    
    const i18n : VueI18n = this.$i18n || this.$root.$i18n;
    locale = locale || i18n.locale;
    locale = mappedLocales[locale] || locale;

    if(Array.isArray(item)) {
        let enValue: T, locValue: T, defValue: T;
        _.each(item, v => {
            if(v.lang === locale) locValue = v;
            if(v.lang === 'cht') enValue = v;
            defValue = v;
        })
        if(!fallback) return locValue;
        return locValue || enValue || defValue || null;
    }
}

Vue.prototype.$i18nPush = function(url) {
    this.$router.push(this.$localePath(url));
}

Vue.prototype.$i18nReplace = function(url) {
    this.$router.replace(this.$localePath(url));
}

Vue.prototype.$tconcat = function (this : Vue, ...parts : (LangType | number)[]) : LangArrType {
    return this.$tjoin(parts, '')
}

Vue.prototype.$tjoin = function(this : Vue, parts : (LangType | number)[], join : string = ',') : LangArrType {
    const langs = _.uniq([
        ...(this.$i18n as any).locales.map(it => it.code),
        ..._.flatMap(parts.map(part => Array.isArray(part) ? part.map(p => p.lang) : [])),
    ].map(l => mappedLocales[l] || l));
    return langs.map(lang => ({
        lang,
        value: parts.map(jt => Array.isArray(jt) || typeof jt === 'object' ? this.$td(jt, lang) : `${jt}`).join(join),
    }));
}

export function localePath(router : any, i18n : VueI18n, url : string) {
    if(!url) return;
    if (url === 'index') {
        url = '';
    }
    let route;
    if (typeof url === 'string') {
        url = url.replace(/(\/?[\w\d-_]+)(\/?)(\?.*)?$/, (text, g1, g2, g3) => `${g1}/${g3 || ''}`);
        route = router.match(url);
    } else if (typeof url === 'object') {
        route = url;
    }

    if (!route || !route.name) {
        console.warn('Cannot match url', {
            url,
            route
        })
        route = router.match('/');
    }

    route = router.match({
        name: [route.name.split('___')[0], i18n.locale].join('___'),
        params: route.params,
        query: route.query,
        hash: route.hash,
    });

    return route.fullPath;
}

Vue.prototype.$localePath = function(this : Vue, url : string) {
    return localePath(this.$router, this.$i18n, url);
}

function getMoment() {
    function m(...args) {
        return moment(...args).locale((m as any).lang);
    }
    return m;
}

export default function(context) {
    const { app } = context;
    const match = app.router.match;
    app.router.match = (to, from) => {
        if((to?.name ?? '').indexOf("___") !== -1) {
            to.query.locale = to.name.split('___')[1];
            let route = match.call(app.router, to, from);
            return route;
        }
        if(app.i18n.__redirect) {
            let route = match.call(app.router, to, from);
            return route;
        }
        let route = match.call(app.router, to, from);
        if (!route || !route.name) {
            console.warn('Cannot match url', {
                from,
                to,
                route
            })
            route = match.call(app.router, '/');
        }

        const toLocale = route.query.locale;
        delete route.query.locale;

        route = match.call(app.router, {
            name: [route.name.split('___')[0], toLocale || app.i18n.locale].join('___'),
            params: route.params,
            query: route.query,
            hash: route.hash,
        });

        return route;
    }

    if(app.i18n.locales.length > 1) {
        let initRedirect = true;
        app.router.beforeEach((to, from, next) => {
            if(initRedirect) {
                initRedirect = false;
                next();
                return;
            }
            if(app.context._redirected) {
                app.context._redirected = false;
                next();
                return;
            }
            const toName = (to.name || '').split('___')[0];
            const fromName = (from.name || '').split('___')[0];
            const toLang = (to.name || '').split('___')[1] || app.i18n.defaultLocale;
            const fromLang = (from.name || '').split('___')[1];
    
            if(process.browser) {
                if (toName === fromName && _.isEqual(_.omit(to.query, 'locale'), _.omit(from.query, 'locale')) && _.isEqual(to.params, from.params)) {
                    next(false);
                    if (app.i18n.locale !== toLang) {
                        window.history.replaceState(null, null, to.fullPath);
                        // not sure is needed or not, but newer i18n plugin redirect in set locale function
                        // set the route to prevent it
                        app.context.route = to;
                        app.i18n.setLocale(toLang || app.i18n.defaultLocale);
                    }
                    return;
                }
            }
            next();
        });

        if(process.client) {
            const replaceState = window.history.replaceState;
            const pushState = window.history.pushState;
            window.history.replaceState = function (data, title, u) {
                if(u && (<string>u).indexOf('locale=') !== -1) {
                    const p = url.parse(<string>u);
                    const q = qs.parse(p.query);
                    delete q.locale;
                    u = url.resolve(<string>u, "?" + qs.stringify(q));
                    if(u.endsWith('?')) u = u.substr(0, u.length - 1);
                }
                replaceState.call(this, data, title, u);
            }
            window.history.pushState = function (data, title, u) {
                if(u && (<string>u).indexOf('locale=') !== -1) {
                    const p = url.parse(<string>u);
                    const q = qs.parse(p.query);
                    delete q.locale;
                    u = url.resolve(<string>u, "?" + qs.stringify(q));
                    if(u.endsWith('?')) u = u.substr(0, u.length - 1);
                }
                pushState.call(this, data, title, u);
            }
        }
    }



    // beforeLanguageSwitch called right before setting a new locale
    app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {}
        // onLanguageSwitched called right after a new locale has been set
    app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
        console.log('lang from', oldLocale, 'to', newLocale)
        const locale = app.i18n.locales.find(it => it.code === newLocale)
        app.$moment.lang = locale.iso.toLowerCase();
        if (app.vuetify) {
            app.vuetify.framework.lang.current = locale.code;
        }
    }

    const dict = {};
    let missingList : any[] = [];
    let missingTimer : any = null;
    app.i18n.missing = (lang, key) => {
        if(!PRODUCTION_MODE) {
            if(!dict[key]) {
                dict[key] = true;
                if(missingTimer) {
                    clearTimeout(missingTimer)
                    missingTimer = null;
                }
                missingList.push(key);
                missingTimer = setTimeout(async () => {
                    const list = missingList;
                    missingList = [];
                    missingTimer = null;
                    await axios.post(`${app.$config.apiUrl}/api/missingTranslations`, list.map(key => ({
                        key
                    })));
                }, 3000)
            } else {
                return key;
            }
        }
    };

    (VueI18n.prototype as any)._isSilentFallback = function (this: any, locale, key) {
        if(PRODUCTION_MODE) {
            return key;
        } else if(dict[key]) {
            return key;
        } else {
            return this._isSilentFallbackWarn(key) && (this._isFallbackRoot() || locale !== this.fallbackLocale)
        }
    };

    (VueI18n.prototype as any)._isSilentFallbackWarn = function (this: any, locale, key) {
        return true;
    };

    (VueI18n.prototype as any)._isSilentTranslationWarn = function (this: any, locale, key) {
        if(PRODUCTION_MODE) {
            return key;
        } else if(dict[key]) {
            return key;
        } else {
            return this._silentTranslationWarn
        }
    };

    app.i18n.loadAllLocales  = (VueI18n.prototype as any).loadAllLocales = async () => {
        await Promise.all(app.i18n.locales.map(locale => loadLanguageAsync(context, locale.code)))
    }

    app.i18n.loadLocale = (VueI18n.prototype as any).loadLocale = (lang) : Promise<void> => {
        return loadLanguageAsync(context, lang)
    }

    const locale = app.i18n.locales.find(it => it.code === app.i18n.locale)

    app.$moment = getMoment();
    app.$moment.lang = locale.iso.toLowerCase();
    if (app.vuetify) {
        app.vuetify.framework.lang.current = locale.code;
    }

    if (process.client && !PRODUCTION_MODE) {
        console.log('i18n helper');
        const vm = new Vue({
            data: {
                enabled: false,
            }
        })

        const oldWarn = app.i18n._warnDefault;

        const sym_key = Symbol("key");
        const sym_locale = Symbol("locale");
        const sym_result = Symbol("result");
        const sym_mresult = Symbol("mresult");
        const sym_values = Symbol("values");

        let translateCache = {};
        app.i18n.vm.$watch('locale', () => {
            translateCache = {};
        })

        class TranslateString {
            constructor(key, values, locale, result, mresult) {
                this[sym_key] = key;
                this[sym_locale] = locale;
                this[sym_result] = result;
                this[sym_mresult] = mresult;
                this[sym_values] = values;
            }
            valueOf() {
                const cacheKey = this[sym_key] + JSON.stringify(this[sym_values]);
                translateCache[cacheKey] = this;
                return `~~~${cacheKey}~~~`;
            }
            toString() {
                return this[sym_mresult];
            }
        }

        app.i18n._warnDefault = function(locale, key, result, vvm, values) {
            const mvalue = oldWarn.call(this, locale, key, result, vvm, values);
            if (!vm.enabled) return mvalue;
            return new TranslateString(key, values, locale, result, mvalue);
        }
        @Component
        class I18NText extends Vue {
            @Prop()
            text: any
            @Prop(String)
            translateKey : string
            @Prop(Boolean)
            valid: boolean
            @Prop(String)
            locale: string
            active = false;

            get inputValue() { return this.text }
            set inputValue(v) {}

            render(_c) {
                return this.active ? _c('span', {
                    style: {
                        'min-width': '30px',
                        'min-height': '1em',
                        'display': 'inline-block'
                    },
                    attrs: {
                        contenteditable: true,
                    },
                    on: {
                        focus: $event => {
                            this.$root.$emit('focusText');
                        },
                        blur: $event => {
                            this.$root.$emit('blurText');
                            this.active = false;
                            this.inputValue = $event.target.innerText;
                        },
                        click: $event => {
                            $event.stopPropagation();
                        },
                    },
                    key: 'editing'
                }, [
                    original.call(this, this.text)
                ]) : _c('span', {
                    style: {
                        outline: '1px solid red',
                        zIndex: '1000',
                    },
                    on: {
                        click: $event => {
                            $event.stopPropagation();
                            $event.preventDefault();
                            this.active = true;
                        },
                    }
                }, [
                    original.call(this, this.text),
                ])
            }
            mounted() {}
        }
        Vue.component('i18n-text', I18NText);

        window.addEventListener('keydown', e => {
            if (e.keyCode === 113) {
                vm.enabled = !vm.enabled;
            }
        })
        const oldS = Vue.prototype._s;
        const original = Vue.prototype._v;
        Vue.prototype._s = function(text) {
            if (text === null || text === undefined) return "";
            if (text instanceof TranslateString) return text;
            return oldS.call(this, text);
        }
        Vue.prototype._v = function(text) {
            let mtext = text instanceof TranslateString ? text.toString() : text;
            if (vm.enabled) {
                if (text instanceof TranslateString) {
                    const node = this._c('i18n-text', {
                        props: {
                            text: mtext,
                            locale: text[sym_locale],
                            translateKey: text[sym_key],
                            valid: !!text[sym_result],
                        },
                    })
                    node.text = mtext;
                    return node;
                } else {
                    const node = this._c('span', text.split("~~~").map((c, idx) => {
                        if (idx % 2) {
                            const item = translateCache[c];
                            if (item) {
                                const node = this._c('i18n-text', {
                                    props: {
                                        text: item.toString(),
                                        locale: item[sym_locale],
                                        translateKey: item[sym_key],
                                        valid: !!item[sym_result],
                                    },
                                })
                                node.text = item.toString();
                                return node;
                            }
                        }
                        return original.call(this, c);
                    }));
                    node.text = mtext;
                    return node;
                }
            } else {
                return original.call(this, mtext);
            }
        }
    }
}
