var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-col gap-y-2" },
    [
      _c(
        "div",
        { staticClass: "text-sm text-grey500 d-flex items-center gap-x-1.5" },
        [_c("span", [_vm._v(_vm._s(_vm.label))]), _vm._t("nameAppend")],
        2
      ),
      _vm.editor
        ? _c("editor-quill", {
            staticClass:
              "input-item outline outline-1 outline-grey200 rounded-lg px-3 py-2.5 text-sm focus:outline-orange100",
            attrs: { readonly: _vm.readonly },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.multiLine
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            staticClass:
              "input-item outline outline-1 outline-grey200 rounded-lg px-3 py-2.5 text-sm focus:outline-orange100",
            attrs: { readonly: _vm.readonly, rows: _vm.rows },
            domProps: { value: _vm.inputValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputValue = $event.target.value
              },
            },
          })
        : _c("menu-list-input", {
            staticClass:
              "input-item editor-text-single-line outline outline-1 outline-grey200 rounded-lg px-3 py-2.5 text-sm outline-gray-200 focus:outline-orange100",
            attrs: { readonly: _vm.readonly },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          }),
      _vm._t("append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }