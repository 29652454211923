
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import components from "./components";
import _ from "lodash";
import type {
  SchemaHelper,
  EditorConfig,
  EditorField,
  SearchField,
  EditorFieldOptions,
  NormalizedRole,
  EditorViewSetting,
} from "./plugin";
import "./editor.scss";
const itemTemplatePrefix = "item";
import { LangType } from "@feathers-client/i18n";
import EditorSearchMenu from "./EditorSearchMenu.vue";

@Component({
  components: {
    ...components,
    EditorSinglePage: async () => (await import("./EditorSinglePage.vue")).default,
    EditorSearchMenu,
  },
})
export default class extends Vue {
  @Prop({ default: true })
  multiSelect: boolean;

  @Prop({ default: true })
  showBatchActions: boolean;

  @Prop()
  exportFilterOverride: any;

  @Prop()
  saveCallback: (item: any, origin?: any) => Promise<any>;

  @Prop()
  dateCutOffTime: string;

  @Watch('filter')
  onUpdateFilter() {
    if(this.nested) {
      this.query = _.merge({}, this.config.filter || {}, this.filter);
    }
  }

  addActionClick() {
    if (this.nested) return;
    (this.$refs.table as any).editItem();
  }

  addItem(item?) {
    return (this.$refs.table as any).editItem(item);
  }

  editItem(item?) {
    return (this.$refs.table as any).editItem(item);
  }

  importActionClick() {
    if (this.nested) return;
    this.$openDialog(
      import("./EditorImport.vue"),
      {
        config: this.config,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  exportActionClick() {
    if (this.nested) return;
    (this.$refs.table as any).beginExport();
  }

  refreshActionClick() {
    if (this.nested) return;
    (this.$refs.table as any).refresh();
  }

  refresh() {
    (this.$refs.table as any).refresh();
  }

  handlers: {
    func: () => void;
    action: string;
  }[];

  query: any = null;
  serializedState: any = {};
  editParams: any = null;

  addons: {
    name: LangType;
    action: (item: any) => void;
  }[] = [];

  @Prop({ type: Boolean, required: false })
  nested: boolean;

  @Prop({ type: Boolean, required: false })
  hidden: boolean;

  @Prop({ type: String, required: false })
  path: string;

  @Prop({ required: false })
  filter: any;

  @Prop({ type: Boolean, default: true })
  inlineEdit: boolean;

  @Prop()
  defaultItem: any;

  @Prop(Boolean)
  rowActions: boolean;

  @Prop({ type: Boolean, default: undefined })
  pager: boolean;

  @Prop(String)
  rootPath: string;

  role: NormalizedRole = null;

  get canImport() {
    return (!this.role || this.role.write) && this.config.import;
  }

  get canCreate() {
    return (!this.role || this.role.write) && this.config.create;
  }

  get canPatch() {
    return (!this.role || this.role.write) && this.config.patch;
  }

  get canRemove() {
    return (!this.role || this.role.write) && this.config.remove;
  }

  get canExport() {
    return (!this.role || this.role.read) && this.config.export;
  }

  get canClone() {
    return (!this.role || (this.role.write && this.role.read)) && this.config.clone;
  }

  get acl() {
    return this.config.acl && this.$schemas.aclList[this.config.acl];
  }

  densify(fields: EditorField[]) {
    return _.map(fields, (f) => {
      const nf = { ...f };
      nf.props = {
        ...f.props,
        dense: true,
        hideDetails: true,
      };
      if (nf.inner) {
        nf.inner = this.densify(nf.inner);
      }
      if (nf.default) {
        nf.default = this.densify(nf.default);
      }
      return nf;
    });
  }

  setting: EditorViewSetting = {};

  beforeMount() {
    if (!this.nested && this.$route.query.state) {
      try {
        this.serializedState = JSON.parse(`${this.$route.query.state}`);
      } catch (e) {
        console.warn(e);
      }
    }
    const path = this.path || this.$route.params.pathMatch;
    if (this.$store.state.settings?.editorSettings) {
      this.setting = _.cloneDeep(
        this.$store.state.settings?.editorSettings.find((it) => it.path === path)?.value || {},
      );
    } else if (localStorage["editorSettings_" + path]) {
      try {
        this.setting = JSON.parse(localStorage["editorSettings_" + path]);
      } catch (e) {}
    }
    if (this.$schemas.routes) {
      this.initConfig();
    } else {
      this.$schemas.init()?.then?.(this.initConfig);
    }
  }

  initConfig() {
    const route = "/" + (this.path || this.$route.params.pathMatch);
    this.config = this.$schemas.lookupRoute(route);
    if (!this.config) {
      console.warn(`Route not found ${route}`);
      return;
    }
    if (this.config.roles) {
      const roles = this.config.roles.filter((it) => this.$schemas.hasRole(it.role));
      this.role = {
        role: roles[0]?.role ?? "none",
        read: roles.some((it) => it.read),
        write: roles.some((it) => it.write),
      };
    }

    if (!this.nested) {
      this.$store.commit("SET_TITLE", {
        title: { $t: this.config.name },
        actions: [
          ...(this.canImport
            ? [
                {
                  icon: "fas fa-file-import",
                  name: "import",
                  action: "import",
                  altText: { $t: "basic.import" },
                },
              ]
            : []),
          ...(this.canExport
            ? [
                {
                  icon: "fas fa-file-export",
                  name: "export",
                  action: "export",
                  altText: { $t: "basic.export" },
                },
              ]
            : []),
          {
            icon: "refresh",
            name: "refresh",
            action: "refresh",
            altText: { $t: "basic.refresh" },
          },
          ...(this.canCreate
            ? [
                {
                  icon: "add",
                  name: "add",
                  action: "add",
                  altText: { $t: "basic.add" },
                },
              ]
            : []),
          ...this.config.actions,
        ],
        fullPage: true,
      });
    }
    if (this.config || !this.nested) {
      this.query = _.merge({}, this.config.filter || {}, this.filter);
    }

    let fields = this.config.fields;
    this.fields = this.$schemas.sortFields(fields);

    this.handlers = [];
    for (let action of this.config.actions) {
      const handler = {
        func: async () => {
          const component = this.$schemas.components[action.component];
          if (component) {
            await this.$openDialog(await component);
          } else {
            console.warn(`Component ${action.component} not found`);
          }
        },
        action: action.action,
      };
      this.handlers.push(handler);
      this.$root.$on(action.action, handler.func);
    }
  }

  get itemSlots() {
    return _.pickBy(this.$scopedSlots, (_, k) => k.startsWith(`${itemTemplatePrefix}.`));
  }

  @Prop()
  headerFields: any[];

  get headers() {
    const customItems = Object.keys(this.itemSlots).map((it) =>
      it.substring(itemTemplatePrefix.length + 1),
    );

    const headerFields = this.headerFields || this.setting.headers;

    const headers = headerFields
      ? [...this.config.headers, ...this.config.extraHeaders].filter((it) =>
          headerFields.includes(it.value),
        )
      : this.config.headers;

    return headers.map((it) => {
      if (customItems.includes(it.value)) {
        return {
          ...it,
          type: "custom",
          slot: `${itemTemplatePrefix}.${it.value}`,
          objectOnly: true,
        };
      }
      return it;
    });
  }

  get exportHeaders() {
    const customItems = Object.keys(this.itemSlots).map((it) =>
      it.substring(itemTemplatePrefix.length + 1),
    );

    const headers = [...this.config.headers, ...this.config.extraHeaders];

    return headers.map((it) => {
      if (customItems.includes(it.value)) {
        return {
          ...it,
          type: "custom",
          slot: `${itemTemplatePrefix}.${it.value}`,
          objectOnly: true,
        };
      }
      return it;
    });
  }

  preEdit(item: any) {
    return item;
  }

  beforeDestroy() {
    _.each(this.handlers, (handler) => {
      this.$root.$off(handler.action, handler.func);
    });
  }

  config: EditorConfig = null;
  data: any;
  fields: EditorField[] = [];
  showSearchMenu = false;

  renderEditor(_c, props) {
    return this.renderEditorTemplate(this.fields, _c, props);
  }

  renderExpand(_c, props) {
    return this.renderEditorTemplate(this.config.expands, _c, props);
  }

  renderEditorTemplate(fields: EditorField[], _c, props) {
    const mitem = props.item;
    return fields.map((field) => {
      const rootPath = field.rootPath ?? "item";
      const item = rootPath === "item" ? mitem : props[rootPath];
      if (!props.mustShow && field.cond && !field.cond(props)) {
        return this._e();
      }
      return _c(field.component, {
        key: field.path,
        props: {
          ...field.props,
          ...(field.propsFunc ? field.propsFunc(props) : {}),
          ...(field.nameField && field.name
            ? {
                [field.nameField]: typeof field.name === 'string' ? this.$root.$t(field.name) : this.$root.$td(field.name),
              }
            : {}),
          root: item,
        },
        model: field.path
          ? {
              value: item?.[field.path],
              callback: ($$v) => {
                if (item) Vue.set(item, field.path, $$v);
              },
            }
          : field.path === ""
          ? {
              value: item,
              callback: ($$v) => {
                props.item = $$v;
              },
            }
          : null,
        scopedSlots: {
          ...(field.inner?.length
            ? {
                item: (scope) => {
                  return this.renderEditorTemplate(field.inner, _c, { ...props, ...scope });
                },
              }
            : {}),
          ...(field.default?.length
            ? {
                default: () => {
                  return this.renderEditorTemplate(field.default, _c, props);
                },
              }
            : {}),
        },
      });
    });
  }

  async validate({ params, query, store, app }) {
    const schemas: SchemaHelper = app.$schemas;
    await schemas.init();
    if (params.pathMatch) {
      const config = schemas.routes["/" + params.pathMatch];
      if (config) {
        return true;
      }
    }
    return false;
  }

  scroll() {
    this.showSearchMenu = false;
  }

  async multiEdit(mode?) {
    if (!(this.$refs.table as any).mselected?.length) return;
    const res = await this.$openDialog(
      import("./EditorBatchEdit.vue"),
      {
        items: (this.$refs.table as any).mselected,
        provider: this.$refs.table,
        config: this.config,
        editor: this,
        mode,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  async showViewSetting() {
    await this.$openDialog(
      import("./EditorViewSetting.vue"),
      {
        editor: this,
        config: this.config,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  saveViewSettings() {
    const path = this.path || this.$route.params.pathMatch;
    this.$store.commit("SET_SETTINGS", {
      editorSettings: (this.$store.state.settings?.editorSettings || [])
        .filter((it) => it.path && it.path !== path)
        .concat([
          {
            path,
            value: _.cloneDeep(this.setting),
          },
        ]),
    });
    localStorage["editorSettings_" + path] = JSON.stringify(this.setting);
  }
}
