var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "nudge-right": 40,
        "min-width": "290px",
        "return-value": _vm.date,
      },
      on: {
        "update:returnValue": function ($event) {
          _vm.date = $event
        },
        "update:return-value": function ($event) {
          _vm.date = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      label: _vm.label,
                      "prepend-icon": "event",
                      readonly: "",
                      clearable: _vm.clearable,
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-date-picker",
        {
          attrs: { "no-title": "", scrollable: "" },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v
            },
            expression: "date",
          },
        },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function ($event) {
                  _vm.menu = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$refs.menu.save(_vm.date)
                },
              },
            },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }