

import { ImportField, ImportContext, TranslateImportField, ImportConfigTree, SheetRow } from '@schemaEditor/importCommon';
import { Context } from '@nuxt/types';
import helper from '~/plugins/helper'
import MallImportProcessor from './mallImport'
import { EditorField } from '@schemaEditor/plugin';
import { FindType } from '@feathers-client';
import _ from 'lodash'
import { LangArrType } from '@feathers-client/i18n';

class GeoJsonImport extends ImportField {

    getItems() {
        return [
            ImportField.create(this.context, { type: 'number', name: 'geojson.latitude', path: 'latitude' }, this),
            ImportField.create(this.context, { type: 'number', name: 'geojson.longitude', path: 'longitude' }, this),
            ImportField.create(this.context, { type: 'string', name: 'geojson.formatted', path: 'formatted' }, this),
        ]
    }

    async apply(config : ImportConfigTree, item : any, row : SheetRow) {
        const inner : {
            latitude?: number,
            longitude?: number,
            formatted?: string
        } = {};
        for(let item of config.inner) {
            await item.field.apply(item, inner, row)
        }
        let geojson : {
            place?: string
            url?: string
            point?: {
                type: 'Point'
                coordinates: number[]
            },
        } = { };
        if(inner.formatted) {
            const [m, a0, a1, a2, b0, b1, b2] = inner.formatted.match(/([\d.]+)?°?([\d.]+)?'?([\d.]+)?"?N\s*([\d.]+)?°?([\d.]+)?'?([\d.]+)?"?E/);

            geojson.point = {
                type: 'Point',
                coordinates: [
                    +(b0 || 0) + +(b1 || 0) / 60 + +(b2 || 0) / 3600,
                    +(a0 || 0) + +(a1 || 0) / 60 + +(a2 || 0) / 3600,
                ]
            }
        } else if(inner.latitude !== undefined && inner.longitude !== undefined && !isNaN(+inner.latitude) && !isNaN(+inner.longitude)) {
            geojson.point = {
                type: 'Point',
                coordinates: [
                    +inner.longitude,
                    +inner.latitude,
                ]
            }
        }
        item[this.path] = geojson;
    }
}


export default helper('schemaEditorAddons', (ctx : Context) => {
    if(process.server) return;
    ctx.app.$schemas.addComponents(require.context("~/components/dialogs", true, /\.(js|ts|vue)$/, 'lazy'))

    ctx.app.$schemas.registerImportPreprocess("mallImport", (context, parent) => {
        return new MallImportProcessor(parent, parent.$el);
    })

    ctx.app.$schemas.registerType('geojson', {
        component: () => import('~/components/GeoJson.vue'),
        props: {
            "editor-size": 12,
        },
        importer : (context : ImportContext, field : EditorField, parent? : ImportField) => new GeoJsonImport(context, field, parent),
    })

    ctx.app.$schemas.registerType('flat', {
        component: () => import('~/components/easymatch/ReadonlyFlat.vue')
    })
});