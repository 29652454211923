var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-left": "8px" } },
    [
      _vm.def.editor === "boolean"
        ? _c("v-switch", {
            attrs: { label: _vm.name, "hide-details": "" },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.def.editor === "string"
        ? _c("v-text-field", {
            attrs: {
              clearable: _vm.clearable,
              "hide-details": "",
              label: _vm.name,
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.def.editor === "number"
        ? _c("v-text-field", {
            attrs: { "hide-details": "", label: _vm.name },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = _vm._n($$v)
              },
              expression: "inputValue",
            },
          })
        : _vm.def.editor === "dim"
        ? _c("dim-picker", {
            attrs: {
              number: _vm.type && _vm.type.args && _vm.type.args.number,
              standalone: "",
              label: _vm.name,
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.def.editor === "icon"
        ? _c("icon-select", {
            attrs: { label: _vm.name },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _vm.def.editor === "array"
        ? [
            _c(
              "div",
              _vm._l(_vm.inputValue, function (entry, idx) {
                return _c(
                  "v-layout",
                  {
                    key: idx,
                    attrs: {
                      row: "",
                      "align-content-center": "",
                      "justify-center": "",
                      "align-center": "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "flex-grow": "1" } },
                      [
                        _c("constant-select", {
                          attrs: {
                            type: _vm.def.elementType,
                            value: _vm.inputValue[idx],
                          },
                          on: {
                            input: function ($event) {
                              _vm.inputValue = [
                                ..._vm.inputValue.slice(0, idx),
                                $event,
                                ..._vm.inputValue.slice(idx + 1),
                              ]
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.inputValue = [
                              ..._vm.inputValue.slice(0, idx),
                              ..._vm.inputValue.slice(idx + 1),
                            ]
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("delete")])],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function ($event) {
                    _vm.inputValue
                      ? (_vm.inputValue = [
                          ..._vm.inputValue,
                          _vm.def.elementType.create(),
                        ])
                      : (_vm.inputValue = [])
                  },
                },
              },
              [_c("v-icon", [_vm._v("add")])],
              1
            ),
          ]
        : _vm.def.editor === "imagePicker"
        ? [
            _c("image-picker", {
              attrs: { label: _vm.name },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "videoPicker"
        ? [
            _c("video-picker", {
              attrs: { label: _vm.name },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "css"
        ? [
            _c("css-picker", {
              attrs: {
                adown: _vm.madown,
                label: _vm.name,
                element: _vm.element,
              },
              on: {
                "update:adown": function ($event) {
                  _vm.madown = $event
                },
                focus: function ($event) {
                  return _vm.$emit("focus", $event)
                },
              },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "object"
        ? [
            _c("object-select", {
              attrs: {
                label: _vm.name,
                constant: "",
                type: _vm.type,
                clearable: _vm.clearable,
              },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "enum"
        ? [
            _c("v-select", {
              attrs: {
                label: _vm.name,
                items: _vm.getEnum(),
                clearable: _vm.clearable,
              },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "multiEnum"
        ? [
            _c(
              "v-input",
              {
                staticClass: "mt-2",
                attrs: { label: _vm.name },
                model: {
                  value: _vm.multiValue,
                  callback: function ($$v) {
                    _vm.multiValue = $$v
                  },
                  expression: "multiValue",
                },
              },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "ml-2",
                    staticStyle: { "flex-grow": "1" },
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.multiValue,
                      callback: function ($$v) {
                        _vm.multiValue = $$v
                      },
                      expression: "multiValue",
                    },
                  },
                  _vm._l(_vm.getEnum(), function (item) {
                    return _c(
                      "v-btn",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  1
                ),
                _vm.clearable
                  ? _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.inputValue = null
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("clear")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.def.editor === "toggle"
        ? [
            _c(
              "v-input",
              {
                attrs: { label: _vm.name },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "ml-2",
                    attrs: { mandatory: "" },
                    model: {
                      value: _vm.inputValue,
                      callback: function ($$v) {
                        _vm.inputValue = $$v
                      },
                      expression: "inputValue",
                    },
                  },
                  _vm._l(_vm.getEnum(), function (item) {
                    return _c(
                      "v-btn",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.text))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.def.editor === "page"
        ? [
            _c("page-picker", {
              attrs: { label: _vm.name },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "color"
        ? [
            _c("color-picker", {
              attrs: { hex: "", label: _vm.name },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "menuItems"
        ? [
            _c("menus-editor", {
              attrs: { label: _vm.name, type: _vm.type },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm.def.editor === "range"
        ? [
            _c(
              "v-input",
              {
                attrs: { label: _vm.name },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              [
                _c("v-range-slider", {
                  staticClass: "mx-3",
                  attrs: {
                    "thumb-color": _vm.inputValue ? undefined : "grey",
                    "thumb-label": "",
                    min: _vm.minValue,
                    max: _vm.maxValue,
                  },
                  model: {
                    value: _vm.rangeValue,
                    callback: function ($$v) {
                      _vm.rangeValue = $$v
                    },
                    expression: "rangeValue",
                  },
                }),
                _vm.clearable
                  ? _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.inputValue = null
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("clear")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }