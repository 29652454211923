var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("media-library", {
        attrs: { multiple: "", type: _vm.type, dir: _vm.dir, selecting: "" },
        on: { selected: _vm.addFiles },
        model: {
          value: _vm.mediaLibrary,
          callback: function ($$v) {
            _vm.mediaLibrary = $$v
          },
          expression: "mediaLibrary",
        },
      }),
      _c(
        "div",
        {
          staticClass: "uploader-dropzone",
          class: {
            "border-orange100": _vm.dragging,
          },
          on: {
            dragenter: _vm.dragenter,
            dragover: _vm.dragover,
            dragleave: _vm.dragleave,
            drop: _vm.drop,
          },
        },
        [
          _vm.label
            ? _c(
                "div",
                {
                  staticClass:
                    "text-sm text-grey500 d-flex items-center gap-x-1.5",
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
          _c("div", { staticClass: "d-flex py-2.5 bg-grey000 rounded" }, [
            _c(
              "div",
              { staticClass: "flex-grow basis-0 overflow-hidden relative" },
              [
                _c(
                  "div",
                  {
                    ref: "scrollArea",
                    staticClass:
                      "scrollable overflow-x-auto overflow-y-hidden d-flex",
                    class: _vm.$breakpoint.xs ? "justify-center" : "ml-3",
                    staticStyle: { overflow: "hidden !important" },
                    on: {
                      "&scroll": function ($event) {
                        return _vm.checkOverFlow.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.imageAreaOverflow &&
                    _vm.scrollBack &&
                    _vm.$breakpoint.smAndUp
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "absolute w-7 left-0 top-0 bottom-0 z-4 bg-grey000 d-flex align-center justify-center opacity-80",
                            attrs: { role: "button" },
                            on: { click: _vm.toLeft },
                          },
                          [_c("div", { staticClass: "left-icon" })]
                        )
                      : _vm._e(),
                    _c(
                      "draggable",
                      {
                        staticClass: "d-flex whitespace-nowrap gap-x-3",
                        class: _vm.$breakpoint.xs ? "flex-col" : "",
                        attrs: {
                          list: _vm.realFiles,
                          "ghost-class": "ghost",
                          animation: 200,
                        },
                        on: {
                          start: function ($event) {
                            _vm.draggingFiles = true
                          },
                          end: function ($event) {
                            _vm.draggingFiles = false
                          },
                        },
                      },
                      _vm._l(_vm.realFiles, function (file, i) {
                        return _c(
                          "div",
                          {
                            key: file.id,
                            staticClass:
                              "image-item cursor-move relative upload-file rounded-lg bg-grey200",
                            class: _vm.$breakpoint.xs ? "my-2" : "",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                void 0
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "two-line" }, [
                              _c("div", { staticClass: "twoLine-icon" }),
                            ]),
                            _c("div", {
                              staticClass: "hover-layer rounded-lg",
                            }),
                            _c("img", {
                              staticClass: "image-item object-cover rounded-lg",
                              class:
                                i != 0 ||
                                "border-solid border-2 border-orange-400",
                              attrs: { src: _vm.$thumb(file) },
                            }),
                            i == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "absolute bottom-2 w-full d-flex justify-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bg-white cover-text text-orange-400 border-solid border-2 border-orange-400 py-1 px-2 rounded-3xl",
                                      },
                                      [_vm._v(_vm._s(_vm.$t("basic.cover")))]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "details-view flex-col items-center justify-center absolute inset-0 hidden z-1",
                              },
                              [
                                _c("div", { staticClass: "dz-details" }),
                                file.processing
                                  ? _c("div", [
                                      _c("span", {
                                        staticClass: "dz-upload",
                                        style: {
                                          width: file.progress * 100 + "%",
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                file.error
                                  ? _c("div", [
                                      _c("span", [_vm._v(_vm._s(file.error))]),
                                    ])
                                  : _vm._e(),
                                file.error
                                  ? _c("div", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("error")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "absolute bottom-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bg-red300 rounded-lg white--text delete-text",
                                        attrs: { role: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.deleteFile(file)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("basic.delete")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.imageAreaOverflow &&
                    !_vm.scrollEnd &&
                    _vm.$breakpoint.smAndUp
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "absolute w-7 right-0 top-0 bottom-0 z-4 bg-grey000 d-flex align-center justify-center opacity-80",
                            attrs: { role: "button" },
                            on: { click: _vm.toRight },
                          },
                          [_c("div", { staticClass: "right-icon" })]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex items-center justify-center mr-3" },
              [
                _c("div", {
                  staticClass: "border-solid border-l border-grey200 h-20 mx-3",
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "image-item d-flex items-center justify-center rounded border border-grey600 border-dashed",
                    attrs: { role: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.addFile()
                      },
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }