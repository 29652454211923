import Vue from 'vue'
import helper from './helper'
import _ from 'lodash'

class Breakpoint {
    public static property: 'breakpoint' = 'breakpoint'
  
    // Public
    public xs: any = false
  
    public sm: any = false
  
    public md: any = false
  
    public lg: any = false
  
    public xl: any = false
  
    public xsOnly: any = false
  
    public smOnly: any = false
  
    public smAndDown: any = false
  
    public smAndUp: any = false
  
    public mdOnly: any = false
  
    public mdAndDown: any = false
  
    public mdAndUp: any = false
  
    public lgOnly: any = false
  
    public lgAndDown: any = false
  
    public lgAndUp: any = false
  
    public xlOnly: any = false
  
    // Value is xs to match v2.x functionality
    public name = 'xs'
  
    public height = 0
  
    public width = 0
  
    // TODO: Add functionality to detect this dynamically in v3
    // Value is true to match v2.x functionality
    public mobile = true
  
    public mobileBreakpoint = 'sm'
  
    public thresholds = {
        xs: 600,
        sm: 960,
        md: 1264,
        lg: 1904
    }
  
    public scrollBarWidth = 16
  
    private resizeTimeout = 0
  
    public init () {
      this.update()
  
      /* istanbul ignore if */
      if (typeof window === 'undefined') return
  
      window.addEventListener(
        'resize',
        this.onResize.bind(this),
        { passive: true }
      )
    }
  
    /* eslint-disable-next-line max-statements */
    public update (ssr = false) {
      const height = ssr ? 0 : this.getClientHeight()
      const width = ssr ? 0 : this.getClientWidth()
  
      const xs = width < this.thresholds.xs
      const sm = width < this.thresholds.sm && !xs
      const md = width < (this.thresholds.md - this.scrollBarWidth) && !(sm || xs)
      const lg = width < (this.thresholds.lg - this.scrollBarWidth) && !(md || sm || xs)
      const xl = width >= (this.thresholds.lg - this.scrollBarWidth)
  
      this.height = height
      this.width = width
  
      this.xs = xs
      this.sm = sm
      this.md = md
      this.lg = lg
      this.xl = xl
  
      this.xsOnly = xs
      this.smOnly = sm
      this.smAndDown = (xs || sm) && !(md || lg || xl)
      this.smAndUp = !xs && (sm || md || lg || xl)
      this.mdOnly = md
      this.mdAndDown = (xs || sm || md) && !(lg || xl)
      this.mdAndUp = !(xs || sm) && (md || lg || xl)
      this.lgOnly = lg
      this.lgAndDown = (xs || sm || md || lg) && !xl
      this.lgAndUp = !(xs || sm || md) && (lg || xl)
      this.xlOnly = xl
  
      switch (true) {
        case (xs):
          this.name = 'xs'
          break
        case (sm):
          this.name = 'sm'
          break
        case (md):
          this.name = 'md'
          break
        case (lg):
          this.name = 'lg'
          break
        default:
          this.name = 'xl'
          break
      }
  
      if (typeof this.mobileBreakpoint === 'number') {
        this.mobile = width < parseInt(this.mobileBreakpoint as any, 10)
  
        return
      }
  
      const breakpoints = {
        xs: 0,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
      } as const
  
      const current = breakpoints[this.name]
      const max = breakpoints[this.mobileBreakpoint]
  
      this.mobile = current <= max
    }
  
    private onResize () {
      clearTimeout(this.resizeTimeout)
  
      // Added debounce to match what
      // v-resize used to do but was
      // removed due to a memory leak
      // https://github.com/vuetifyjs/vuetify/pull/2997
      this.resizeTimeout = window.setTimeout(this.update.bind(this), 200)
    }
  
    // Cross-browser support as described in:
    // https://stackoverflow.com/questions/1248081
    private getClientWidth () {
      /* istanbul ignore if */
      if (typeof document === 'undefined') return 0 // SSR
      return Math.max(
        document.documentElement!.clientWidth,
        window.innerWidth || 0
      )
    }
  
    private getClientHeight () {
      /* istanbul ignore if */
      if (typeof document === 'undefined') return 0 // SSR
      return Math.max(
        document.documentElement!.clientHeight,
        window.innerHeight || 0
      )
    }
  }

export default helper('breakpoint', (ctx) => {
    const def = {
        xs: true,
        xsOnly: true,
        xsAndUp: true,
        sm: false,
        smOnly: true,
        smAndDown: true,
        smAndUp: false,
        md: false,
        mdOnly: false,
        mdAndDown: true,
        mdAndUp: false,
        lg: false,
        lgOnly: false,
        lgAndDown: true,
        lgAndUp: false,
        xl: false,
        xlOnly: false,
        xlAndDown: true
    };
    const desktop = {
        xs: false,
        xsOnly: false,
        xsAndUp: true,
        sm: false,
        smOnly: false,
        smAndDown: false,
        smAndUp: true,
        md: false,
        mdOnly: false,
        mdAndDown: false,
        mdAndUp: true,
        lg: true,
        lgOnly: true,
        lgAndDown: true,
        lgAndUp: true,
        xl: false,
        xlOnly: false,
        xlAndDown: true
    }
    const bp = new Breakpoint();
    if(process.client) {
        bp.init();
        const proxy = new Vue({
            data: bp,
        })
        return proxy;
    }
    return new Vue({
        computed: _.mapValues(def, (v, k) => 
            function() {
                return ctx.store.state.isMobile ? def[k] : desktop[k]
            }
        )
    });
});
