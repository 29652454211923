
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import path from 'path'
import * as mime from 'mime'
import _ from 'lodash'

export class ImportFileData {
    constructor(file : Partial<ImportFileData>) {
        if(!file.name) {
            file.name = path.basename(file.path);
        }

        if(!file.mime) {
            file.mime = ((mime as any).getType || (mime as any).lookup)(file.name);
        }

        if(file.path.startsWith('/')) file.path = file.path.substr(1);

        _.assign(this, file);
    }

    path: string = '';
    name: string = '';
    data: Blob | Buffer = null;
    mime: string = '';

    attachment? : string = null;
}

export interface FolderTree {
    child?: (FolderTree | ImportFileData)[]
    name: string
    path: string
}

@Component
export class ImportFileSource extends Vue {
    readonly type : string;
    mfiles: ImportFileData[] = [];
    get files() { return this.mfiles as readonly ImportFileData[] }
    name: string

    fileRoot : FolderTree = {
        child: [],
        name: '',
        path: '',
    }

    addFile(file : ImportFileData) {
        this.mfiles.push(file);
        const parts = file.path.split('/');
        let cur = this.fileRoot;
        for(let i = 0; i < parts.length - 1; i++) {
            let next = cur.child.find(it => it.name === parts[i]);
            if(!next) {
                cur.child.push(next = {
                    name: parts[i],
                    path: (cur.path ? cur.path + '/' : '') + parts[i],
                    child: [],
                })
            }
            cur = next;
        }
        cur.child.push(file);
    }

    loading = false;
    currentError : string = null;
    isValid = false;

    get valid() {
        return !this.loading && this.prepareTask && !this.currentError && this.isValid;
    }

    prepareTask : Promise<boolean> = null;
    async prepare() : Promise<boolean> {
        return false;
    }
    waitPrepare() {
        if(this.prepareTask) return this.prepareTask;
        this.loading = true;
        this.currentError = null;
        this.isValid = false;
        this.prepareTask = this.prepare();
        this.prepareTask.then(v => {
            this.isValid = v;
        }).catch(e => {
            this.currentError = e.message || `${e}`
        }).finally(() => {
            this.loading = false;
        })
    }

    reset() {
        this.prepareTask = null;
        this.mfiles = [];
        this.fileRoot = {
            child: [],
            name: '',
            path: '',
        };
        this.waitPrepare();
    }

    async getFile(value : string, folder? : boolean) : Promise<ImportFileData[]> {
        value = value.toLowerCase().trim();
        if(value.startsWith('/')) value = value.substr(1).trim();
        return this.files.filter(it => folder ? it.path.toLowerCase().trim().startsWith(value) : it.path.toLowerCase().trim() === value);
    }

    get renderer() {
        return this.$options.render && this.$options.render.bind(this);
    }
}

