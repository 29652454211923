<template>
<div>
  <div ref="editor" style="min-height: 400px"></div>
</div>
</template>

<script>
const Quill = process.server ? {} : require("quill/dist/quill");
import _ from "lodash";
import SlotRender from "./SlotRender.vue";
import StaticRender from "./StaticRender.vue";
if (process.client) {
    window.Quill = Quill;
    const ImageResize = require("quill-image-resize-module").default;
    Quill.register("modules/imageResize", ImageResize);
}
import MediaLibrary from "~/components/MediaLibrary";

// console.log(Quill);
var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = ["alt", "width", "style"];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

require("~/assets/quill/quill.snow.css");
export default {
    components: {
        SlotRender,
        StaticRender,
        MediaLibrary,
    },
    name: "quill-editor",
    data() {
        return {
            mediaLibrary: false,
            _content: "",
            defaultModules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [
                        {
                            color: [
                                "#000000",
                                "#e60000",
                                "#ff9900",
                                "#ffff00",
                                "#008a00",
                                "#0066cc",
                                "#9933ff",
                                "#ffffff",
                                "#facccc",
                                "#ffebcc",
                                "#ffffcc",
                                "#cce8cc",
                                "#cce0f5",
                                "#ebd6ff",
                                "#bbbbbb",
                                "#f06666",
                                "#ffc266",
                                "#ffff66",
                                "#66b966",
                                "#66a3e0",
                                "#c285ff",
                                "#888888",
                                "#a10000",
                                "#b26b00",
                                "#b2b200",
                                "#006100",
                                "#0047b2",
                                "#6b24b2",
                                "#444444",
                                "#5c0000",
                                "#663d00",
                                "#666600",
                                "#003700",
                                "#002966",
                                "#3d1466",
                                "custom-color",
                            ],
                        },
                        { background: [] },
                    ],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"],
                    ["link", "image", "video"],
                ],
                imageResize: {},
            },
        };
    },
    props: {
        content: String,
        value: String,
        label: String,
        outlined: Boolean,
        hideDetails: Boolean,
        options: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },
    mounted() {
        this.initialize();
    },
    beforeDestroy() {
        this.quill = null;
    },
    methods: {
        initialize() {
            this.applyLang();
            if (this.$el) {
                // options and instance
                var self = this;
                self.options.theme = self.options.theme || "snow";
                self.options.bounds = self.options.bounds || self.$el;
                self.options.modules =
                    self.options.modules || self.defaultModules;
                self.options.modules.toolbar =
                    self.options.modules.toolbar || self.defaultModules.toolbar;
                self.options.placeholder = self.placeholder;
                self.options.readOnly =
                    self.options.readOnly !== undefined
                        ? self.options.readOnly
                        : false;
                self.options.modules.toolbar =
                    self.options.modules.toolbar ||
                    defaultOptions.modules.toolbar;
                // self.options.modules.clipboard = self.options.modules.clipboard || {};
                // self.options.modules.clipboard.matchers = self.options.modules.clipboard.matchers || [];

                // self.options.modules.clipboard.matchers.push([
                //     'img',
                //     function matchStyles(node, delta) {
                //         var formats = {};
                //         var style = node.style || {};
                //         const img = delta?.ops?.[0];
                //         if(img) {
                //             img.attributes = img.attributes || {};
                //             img.attributes.style = 'background: red'
                //         }
                //         return delta;
                //     }
                // ])

                self.quill = new Quill(self.$refs.editor, self.options);
                self.quill.getModule("toolbar").addHandler("color", (value) => {
                    // if the user clicked the custom-color option, show a prompt window to get the color
                    if (value == "custom-color") {
                        value = prompt("Enter Hex/RGB/RGBA");
                    }

                    self.quill.format("color", value);
                });

                // set editor content
                if (self.value || self.content) {
                    self.quill.pasteHTML(
                        (self.value || self.content).replace(
                            /\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi,
                            (m, id) => {
                                return `${this.url}/api/attachments/${id}`;
                            }
                        )
                    );
                }

                // mark model as touched if editor lost focus
                self.quill.on("selection-change", (range) => {
                    if (!range) {
                        self.$emit("blur", self.quill);
                    } else {
                        self.$emit("focus", self.quill);
                    }
                });

                // update model if text changes
                self.quill.on("text-change", (delta, oldDelta, source) => {
                    var html = self.$refs.editor.children[0].innerHTML;
                    const text = self.quill.getText();
                    if (html === "<p><br></p>") html = "";
                    html = html.replace(
                        new RegExp(
                            `${escapeRegExp(
                                this.url + "/api/attachments/"
                            )}([a-f\\d]{24})`,
                            "ig"
                        ),
                        (m, id) => {
                            return `{{ATTACHMENT(${id})}}`;
                        }
                    );
                    self._content = html;
                    self.$emit("input", self._content);
                    self.$emit("change", {
                        editor: self.quill,
                        html: html,
                        text: text,
                    });
                });

                // quill editor add image handler
                self.quill.getModule("toolbar").addHandler("image", () => {
                    this.mediaLibrary = true;
                });

                // emit ready
                self.$emit("ready", self.quill);
            }
        },

        applyLang() {
            _.forEach(this.lang, (v, k) => {
                document.documentElement.style.setProperty(k, `'${v}'`);
            });
        },

        addFiles(files) {
            this.mediaLibrary = false;
            const editor = this.quill;
            const range = editor.getSelection();
            for (let f of files) {
                editor.insertEmbed(
                    range ? range.index : editor.getLength(),
                    "image",
                    `${this.url}/api/attachments/${f._id}`
                );
            }
        },
    },
    watch: {
        content(newVal, oldVal) {
            if (this.quill) {
                if (!!newVal && newVal !== this._content) {
                    this._content = newVal;
                    this.quill.pasteHTML(
                        newVal.replace(
                            /\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi,
                            (m, id) => {
                                return `${this.url}/api/attachments/${id}`;
                            }
                        )
                    );
                } else if (!newVal) {
                    this.quill.setText("");
                }
            }
        },
        value(newVal, oldVal) {
            if (this.quill) {
                if (newVal !== this._content) {
                    this._content = newVal;
                    this.quill.pasteHTML(
                        newVal.replace(
                            /\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi,
                            (m, id) => {
                                return `${this.url}/api/attachments/${id}`;
                            }
                        )
                    );
                } else if (!newVal) {
                    this.quill.setText("");
                }
            }
        },
        placeholder(val) {
            if (this.$el) {
                const editor = this.$el.getElementsByClassName("ql-editor")[0];
                if (editor) editor.setAttribute("data-placeholder", val);
            }
        },
        lang(val) {
            this.applyLang();
        },
    },
    computed: {
        url() {
            return this.$config.apiUrl;
        },
        placeholder() {
            return this.options.placeholder || this.$t("quill.placeholder");
        },
        lang() {
            return {
                "--ql-size-small": this.$t("quill.size.small"),
                "--ql-size-normal": this.$t("quill.size.normal"),
                "--ql-size-large": this.$t("quill.size.large"),
                "--ql-size-huge": this.$t("quill.size.huge"),

                "--ql-heading-1": this.$t("quill.heading.1"),
                "--ql-heading-2": this.$t("quill.heading.2"),
                "--ql-heading-3": this.$t("quill.heading.3"),
                "--ql-heading-4": this.$t("quill.heading.4"),
                "--ql-heading-5": this.$t("quill.heading.5"),
                "--ql-heading-6": this.$t("quill.heading.6"),
                "--ql-heading-normal": this.$t("quill.heading.normal"),

                "--ql-enter-video": this.$t("quill.enter-video"),
                "--ql-enter-formula": this.$t("quill.enter-formula"),
                "--ql-enter-link": this.$t("quill.enter-link"),

                "--ql-save": this.$t("basic.save"),
                "--ql-remove": this.$t("basic.remove"),
            };
        },
    },
};
</script>

<style lang="scss">
.quill-editor img {
    max-width: 100%;
}

.ql-color .ql-picker-options [data-value="custom-color"] {
    background: none !important;
    width: 100% !important;
    height: 20px !important;
    text-align: center;
}
.ql-color .ql-picker-options [data-value="custom-color"]:before {
    content: "Custom Color";
}
.ql-color .ql-picker-options [data-value="custom-color"]:hover {
    border-color: transparent !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border: 0;
}
</style>
