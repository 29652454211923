var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.useBounds && !_vm.dialog
    ? _c(
        "div",
        { staticClass: "pointer-events-none absolute inset-0" },
        [
          _vm.legacySize && _vm.inputValue
            ? _c("resize-sensor", { on: { resized: _vm.updateMenu } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }