var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    { class: _vm.verifyStatusStyle },
    [
      _vm.icon
        ? _c(
            "v-avatar",
            { attrs: { left: "" } },
            [
              _c("v-icon", { attrs: { color: "white" } }, [
                _vm._v(_vm._s(_vm.icon)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(
        " " +
          _vm._s(_vm.$t("enum.pages.orders.verifyStatus." + _vm.value)) +
          " "
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }