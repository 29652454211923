var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "w-full",
      style: { "--selected-color": _vm.mselectedColor },
    },
    [
      _c(
        "transition-group",
        {
          staticClass:
            "d-flex items-center flex-wrap btn-container px-4 gap-x-3",
          class: { light: _vm.light },
          attrs: { name: "list-complete", tag: "div" },
        },
        [
          _vm._t("items", function () {
            return _vm._l(_vm.items, function (item, idx) {
              return _c(
                "div",
                {
                  key: item._id || idx,
                  class: [
                    { selected: _vm.isSelected(item), disabled: _vm.disabled },
                    _vm.itemClass,
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.toggleSelected(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-lg text-center rounded-t p-3 not-item",
                      attrs: { role: "button" },
                    },
                    [
                      _vm._t(
                        "itemContent",
                        function () {
                          return [
                            _vm._v(" " + _vm._s(_vm.$td(item.name)) + " "),
                          ]
                        },
                        { item: item, index: idx }
                      ),
                    ],
                    2
                  ),
                ]
              )
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }