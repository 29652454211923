var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          _vm.showSearchMenuSync = true
        },
      },
    },
    [
      _c("v-menu", {
        ref: "searchMenu",
        attrs: {
          "max-height": "400",
          "offset-y": "",
          "offset-overflow": "",
          "close-on-click": true,
          "close-on-content-click": false,
          "content-class": "searchMenu",
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on }) {
              return [
                _c(
                  "v-combobox",
                  _vm._g(
                    {
                      class: ["mt-6", "mx-6", "search-box"],
                      attrs: {
                        outlined: "",
                        "hide-details": "",
                        "prepend-inner-icon": "search",
                        multiple: "",
                        "no-filter": "",
                        "hide-selected": "",
                        "small-chips": "",
                        "deletable-chips": "",
                        "item-value": "path",
                        clearable: "",
                        "append-icon": "",
                        "menu-props": {
                          closeOnContentClick: false,
                          closeOnClick: true,
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "relative" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: `${item.color} darken-2`,
                                          close: !_vm.searchFields.includes(
                                            item.path
                                          ),
                                        },
                                        on: {
                                          "click:close": function ($event) {
                                            return _vm.clearCond(item)
                                          },
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.$set(
                                              _vm.activeState,
                                              item.path,
                                              typeof item !== "string"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        typeof item === "string"
                                          ? [
                                              _c(
                                                "span",
                                                { staticClass: "white--text" },
                                                [_vm._v(_vm._s(item))]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [_vm._v(_vm._s(_vm.$t(item.name)))]
                                        ),
                                        typeof item !== "string" &&
                                        _vm.isSearchActive(item)
                                          ? [
                                              (item.cond && item.value1) ||
                                              (item.cond === "inRange" &&
                                                item.value2)
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mx-1 white--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "conds." +
                                                                item.cond
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color:
                                                            "white !important",
                                                        },
                                                      },
                                                      [
                                                        item.value1
                                                          ? _c(
                                                              "virtual-data-table-cell",
                                                              {
                                                                attrs: {
                                                                  context:
                                                                    _vm.context,
                                                                  item: item.value1,
                                                                  header:
                                                                    item.header,
                                                                },
                                                              }
                                                            )
                                                          : _c("span", [
                                                              _vm._v("-"),
                                                            ]),
                                                        item.cond === "inRange"
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "conds.to"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              item.value2
                                                                ? _c(
                                                                    "virtual-data-table-cell",
                                                                    {
                                                                      attrs: {
                                                                        context:
                                                                          _vm.context,
                                                                        item: item.value2,
                                                                        header:
                                                                          item.header,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c("span", [
                                                                    _vm._v("-"),
                                                                  ]),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          : typeof item !== "string"
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mx-1 white--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("conds.all"))
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _c("teleport-menu", {
                                      attrs: { size: "auto", thumb: "start" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pos-popup p-4 space-y-2 min-w-100 max-w-[70vw]",
                                                  },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "mx-2",
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.toggleCond(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "conds." +
                                                                item.cond
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "<md:col-span-2",
                                                        staticStyle: {
                                                          "min-width": "250px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            void 0
                                                          },
                                                        },
                                                      },
                                                      [
                                                        item.edit.component ===
                                                        "editor-date-picker"
                                                          ? _c(
                                                              "editor-date-picker-preset",
                                                              {
                                                                attrs: {
                                                                  item: item,
                                                                  dateCutOffTime:
                                                                    _vm.dateCutOffTime,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          item.edit.component,
                                                          _vm._b(
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                required: false,
                                                                clearable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value1,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value1",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.value1",
                                                              },
                                                            },
                                                            "component",
                                                            item.edit.props,
                                                            false
                                                          )
                                                        ),
                                                        item.cond === "inRange"
                                                          ? _c(
                                                              item.edit
                                                                .component,
                                                              _vm._b(
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    required: false,
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.value2,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "value2",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.value2",
                                                                  },
                                                                },
                                                                "component",
                                                                item.edit.props,
                                                                false
                                                              )
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.activeState[item.path],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.activeState,
                                            item.path,
                                            $$v
                                          )
                                        },
                                        expression: "activeState[item.path]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.currentSearch,
                        callback: function ($$v) {
                          _vm.currentSearch = $$v
                        },
                        expression: "currentSearch",
                      },
                    },
                    on
                  )
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function ({}) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-white p-2 grid md:grid-cols-[auto,auto,minmax(0,1fr)] <md:grid-cols-[minmax(0,1fr),minmax(0,1fr)] items-center gap-y-2",
                  },
                  _vm._l(_vm.config.searchFields, function (item) {
                    return _c(
                      "div",
                      { key: item.path, staticClass: "contents" },
                      [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              color: `${item.color} darken-2`,
                              dark: "",
                              label: "",
                              small: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                void 0
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t(item.name)))]
                        ),
                        _c(
                          "v-chip",
                          {
                            staticClass: "mx-2",
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.toggleCond(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("conds." + item.cond)))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "<md:col-span-2",
                            staticStyle: { "min-width": "250px" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                void 0
                              },
                            },
                          },
                          [
                            item.edit.component === "editor-date-picker"
                              ? _c("editor-date-picker-preset", {
                                  attrs: {
                                    item: item,
                                    dateCutOffTime: _vm.dateCutOffTime,
                                  },
                                })
                              : _vm._e(),
                            _c(
                              item.edit.component,
                              _vm._b(
                                {
                                  tag: "component",
                                  attrs: { clearable: "" },
                                  model: {
                                    value: item.value1,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value1", $$v)
                                    },
                                    expression: "item.value1",
                                  },
                                },
                                "component",
                                item.edit.props,
                                false
                              )
                            ),
                            item.cond === "inRange"
                              ? _c(
                                  item.edit.component,
                                  _vm._b(
                                    {
                                      tag: "component",
                                      attrs: { required: false, clearable: "" },
                                      model: {
                                        value: item.value2,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value2", $$v)
                                        },
                                        expression: "item.value2",
                                      },
                                    },
                                    "component",
                                    item.edit.props,
                                    false
                                  )
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showSearchMenuSync,
          callback: function ($$v) {
            _vm.showSearchMenuSync = $$v
          },
          expression: "showSearchMenuSync",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }