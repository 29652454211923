import { render, staticRenderFns } from "./EditorSearchMenu.vue?vue&type=template&id=03ad4050&"
import script from "./EditorSearchMenu.vue?vue&type=script&lang=ts&"
export * from "./EditorSearchMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./EditorSearchMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/Users/domorelabs/Documents/domorelabs/wheels/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03ad4050')) {
      api.createRecord('03ad4050', component.options)
    } else {
      api.reload('03ad4050', component.options)
    }
    module.hot.accept("./EditorSearchMenu.vue?vue&type=template&id=03ad4050&", function () {
      api.rerender('03ad4050', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/schema-editor/EditorSearchMenu.vue"
export default component.exports