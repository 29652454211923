var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("virtual-list", {
    staticClass: "scrollable virtual-list",
    attrs: {
      "data-key": _vm.itemStoreKey,
      "data-sources": _vm.itemStore,
      "data-component": _vm.itemComponent,
      "extra-props": _vm.itemProps,
      "estimate-size": _vm.estimateSize,
      "item-scoped-slots": { item: _vm.$scopedSlots["item"] },
      keeps: _vm.keeps,
      "footer-style": _vm.footerStyle,
      "bottom-threshold": _vm.estimateSize,
      "wrap-style": { width: "100%" },
      "wrap-tag": _vm.wrapTag,
      "wrap-class": _vm.wrapClass,
      "item-tag": _vm.itemTag,
      "item-class": _vm.itemClass,
    },
    on: { tobottom: _vm.loadNext },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _vm._t("header"),
              !_vm.loading && !_vm.items.length ? _vm._t("noData") : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "sticky",
          fn: function () {
            return [_vm._t("sticky")]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _vm.loader && _vm.loading
                ? _vm._t("loader", function () {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "lds-ring", style: _vm.loaderStyle },
                            [_c("div")]
                          ),
                        ]
                      ),
                    ]
                  })
                : _vm._e(),
              _vm._t("footer"),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }