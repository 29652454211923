var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.type === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "flex-grow menu-list-input",
        attrs: { enterkeyhint: _vm.enterkeyhint, type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.inputValue)
            ? _vm._i(_vm.inputValue, null) > -1
            : _vm.inputValue,
        },
        on: {
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.goNext.apply(null, arguments)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            return _vm.onBackspace.apply(null, arguments)
          },
          focus: _vm.onFocus,
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          change: function ($event) {
            var $$a = _vm.inputValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputValue = $$c
            }
          },
        },
      })
    : _vm.type === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "flex-grow menu-list-input",
        attrs: { enterkeyhint: _vm.enterkeyhint, type: "radio" },
        domProps: { checked: _vm._q(_vm.inputValue, null) },
        on: {
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.goNext.apply(null, arguments)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            return _vm.onBackspace.apply(null, arguments)
          },
          focus: _vm.onFocus,
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          change: function ($event) {
            _vm.inputValue = null
          },
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        staticClass: "flex-grow menu-list-input",
        attrs: { enterkeyhint: _vm.enterkeyhint, type: _vm.type },
        domProps: { value: _vm.inputValue },
        on: {
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.goNext.apply(null, arguments)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            return _vm.onBackspace.apply(null, arguments)
          },
          focus: _vm.onFocus,
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputValue = $event.target.value
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }