
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import ListLoader, { ListLoaderBase } from './ListLoader'

// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { DataTableDef, DataTableHeader, DataTablePagination, DataTablePaginated, AclHandler } from './index'
import HeaderProvider from './mixins/HeaderProvider'
import VirtualDataTableRow from './VirtualDataTableRow.vue'
import VirtualDataTableHeader from './VirtualDataTableHeader.vue'
import _ from 'lodash'
import QueryProvider from "./mixins/QueryProvider";
import VirtualList from './vue-virtual-scroll-list'

@Component({
    components: {
        VirtualDataTableHeader,
        VirtualList,
    }
})
export default class VirtualDataTable extends mixins(HeaderProvider, QueryProvider) {

    VirtualDataTableRow = VirtualDataTableRow;
    
    items = [];
    mloader : ListLoader<any> = null;

    @Prop({ default: 'color: white' })
    loaderStyle: any

    @Prop()
    loader : ListLoaderBase<any>;

    get _loader() {
        return this.loader || this.mloader;
    }

    @Prop({ type: Boolean, default: undefined })
    pager : boolean

    @Prop({ type: Boolean })
    flat : boolean

    @Prop({ type: Boolean, default: true })
    header : boolean

    @Prop(Boolean)
    hidden : boolean

    get mpager() {
        return this.pager ?? !this.isMobile;
    }

    @Prop()
    path : string

    @Prop()
    query : any

    @Prop()
    params : any

    @Prop({ type: String, default: '_id' })
    itemKey : string

    @Prop(Boolean)
    noPaginate : boolean

    @Prop()
    headers : DataTableHeader[]

    @Prop()
    exportHeaders : DataTableHeader[]

    @Prop({ type: Boolean, default: true })
    patch: boolean

    @Prop({ type: Boolean, default: true })
    remove: boolean

    @Prop({ type: Boolean, default: true })
    create: boolean

    @Prop({ type: Boolean, default: true })
    canClone: boolean

    @Prop()
    acl: AclHandler

    @Prop({ type: Boolean, default: true })
    multiSelect: Boolean

    @Prop(Boolean)
    rowActions: boolean

    get hasRowActions() {
        return this.create || this.patch || this.remove || this.rowActions;
    }

    @Prop({ })
    defaultSort: string | string[]

    @Prop({ })
    defaultSortDesc: boolean | boolean[]

    @Prop()
    editParams : any

    @Prop()
    preEdit : (item : any) => any | Promise<any>;

    @Prop({ type: Number})
    rowSize : number

    @Prop({ type: Number})
    mobileRowSize : number

    @Prop({ type: Boolean })
    nested : boolean

    @Prop()
    rootPath: string

    @Prop(String)
    appendRow : string

    @Prop({ default: true, type: Boolean })
    inlineEdit: boolean;

    @Prop()
    exportFilterOverride: any

    @Prop()
    serializedState: any

    isMobile = false;

    mselect = false;
    mselected: any[] = [];

    currentDeltaTotal = 0;
    pageCount = 10;
    curPage = 0;
    pageStart = 0;

    sort : string[] = [];
    sortDesc : boolean[] = [];

    _stateStore : any;

    initJump: number | null = null;

    get currentRowSize() {
        return this.isMobile ? (this.mobileRowSize ?? Math.max(100, (this.headers?.length ?? 1) * 22 + 66)) : (this.rowSize ?? 66);
    }

    get sortParams() {
        if(!this.sort.length) return undefined; // assume sort by _id?
        else {
            const param = _.fromPairs(this.sort.map((field, idx) => [field, this.sortDesc[idx] ? -1 : 1]));
            if(!param._id) param._id = 1;
            return param;
        }
    }

    get pageMax() { return Math.max(1, Math.floor(((this._loader?.total || 0) - 1) / this.pageCount)) }

    get context() {
        return this;
    }

    get extraProps() {
        return {
            context: this.context,
        } as any
    }

    @Watch('query', { deep: true})
    @Watch('sortParams')
    setQuery() {
        this.curPage = this.pageStart = this.initJump ?? 0;
        this._loader?.setPageStart(this.initJump ?? 0);
        // console.warn('set query', JSON.stringify(this.query), this.curPage);
        this.mloader?.setQuery({
            ...this.query,
            $sort: this.sortParams
        })?.then?.(() => {
            this.updateCurrentPage();
        });;
        (this.$refs.scroller as any)?.scrollToOffset?.(0);
        this.updateUrl();
    }

    async beforeMount() {
        this._stateStore = {};
        if(!this.nested && this.$route.query.sort) {
            try {
                const [sort, sortDesc] = JSON.parse(`${this.$route.query.sort}`);
                this.sort = sort;
                this.sortDesc = sortDesc;
            } catch(e) {
                console.warn(e);
            }
        }
        else if(this.defaultSort) {
            this.sort = Array.isArray(this.defaultSort) ? this.defaultSort.slice() : [this.defaultSort];
            this.sortDesc = Array.isArray(this.defaultSortDesc) ? this.defaultSortDesc.slice() : [this.defaultSortDesc ?? false];
        }
        if(!this.nested && process.client && location.hash && !isNaN(+location.hash.substr(1))) {
            this.pageStart = this.initJump = +location.hash.substr(1);
            setTimeout(() => {
                this.initJump = null;
            }, 500);
        }
        if(!this.headers) {
            console.warn("Headers is mandatory, use virtual-data-list for slot mode")
        }
        this.setMobile();
        if(!this.nested && (this.$route.query.edit || (this.$route.query.query as any)?.editor)) {
            try {
                const service = this.path && (this as any).$feathers.service(this.path);
                const item = await service.get((this.$route.query.edit || (this.$route.query.query as any)?.editor), {
                    ...this.query
                })
                this.editItem(item);
            } catch(e) {
                console.warn(e);
            }
        }
        await Vue.nextTick();
        this.reset();
    }

    refresh() {
        this._loader?.reset(false);
        this._loader?.execute().then(() => {
            this.updateCurrentPage();
        });
        this.mselected = [];
        (this.$refs.scroller as any)?.scrollToOffset?.(0);
    }

    @Watch('$vuetify.breakpoint.smAndDown')
    setMobile() {
        if(this.flat) return;
        this.isMobile =  this.$vuetify.breakpoint.smAndDown;
    }

    reset() {
        const clientHeight = this.$refs.scroller ? (this.$refs.scroller as any).getViewPortSize() : this.$el.clientHeight;
        this.mloader = null;
        if(!this.path) {
            return;
        }
        this.pageCount = Math.max(3, Math.floor(clientHeight / this.currentRowSize));
        this.mloader = new ListLoader(this.path, {
            $root: this,
            $feathers: this.$feathers,
            query: {
                ...this.query,
                $sort: this.sortParams
            },
            params: this.params,
            noPaginate: this.noPaginate,
            limit: Math.ceil(clientHeight / this.currentRowSize) + 3,
            ensureUnique: true,
        });
        this._loader.execute().then(()=>{
            if(this._lastEndIndex) {
                this.checkUpdate();
            }
            this.updateCurrentPage();
        });
        this.updateUrl();
    }

    @Watch('curPage')
    @Watch('serializedState')
    updateUrl() {
        if(!this.nested) {
            const url = new URL(`${window.location}`);
            if(this.serializedState) {
                url.searchParams.set('state', JSON.stringify(this.serializedState));
            } else {
                url.searchParams.set('query', JSON.stringify(this.query));
            }
            url.searchParams.set('sort', JSON.stringify([this.sort, this.sortDesc]))
            url.searchParams.delete('edit')
            url.hash = `${this.initJump ? this.initJump : Math.max(this.mloader?.pageStart ?? 0, this.curPage * this.pageCount)}`;
            window.history.replaceState({}, '', url.toString());
        }
    }

    resize() {
        const clientHeight = this.$refs.scroller ? (this.$refs.scroller as any).getViewPortSize() : this.$el.clientHeight;
        this.pageCount = Math.max(3, Math.floor(clientHeight / this.currentRowSize));
        this._loader?.setLimit(Math.ceil(clientHeight / this.currentRowSize) + 3);
        this.$emit('resize', { width: this.$el.clientWidth, height: this.$el.clientHeight});
    }

    _lastEndIndex: number;
    _updateIndexTimer: any;
    checkUpdate() {
        if(!this._loader || !this._loader.store || this._loader.loading || this._loader.loaded) return;
        return this._loader.execute().then(() => {
            this.updateCurrentPage();
        });
    }

    checkScroll(evt,range) {
        this._lastEndIndex = range.end;
        if(!this._updateIndexTimer) {
            this._updateIndexTimer = setTimeout(this.updateCurrentPage, 200);
        }
    }

    updateCurrentPage() {
        this._updateIndexTimer = null;
        const view : HTMLElement = (this.$refs.scroller as any)?.$el;
        const headerSizes = (this.$refs.scroller as any)?.getHeaderStickySize?.() ?? 0;
        if(view) {
            const rect = view.getBoundingClientRect();
            const items = Array.from(view.querySelectorAll("[role='group'] [role='listitem']"));
            const first = items.find(it => (it as HTMLElement).getBoundingClientRect().bottom >= rect.top + headerSizes) ||
                items.find(it => (it as HTMLElement).getBoundingClientRect().top >= rect.top + headerSizes);
            const item = first || items[items.length - 1];
            const index = (item as any)?.__vue__?.index ?? 0;
            const p = Math.min(this.pageMax - 1, Math.floor((index + this.pageStart) / this.pageCount));
            if(this.curPage !== p) {
                this.curPage = p;
            }
        }
    }

    onScroll(e) {
        // console.log(e);
        this.$emit('scroll', e);
    }

    @Prop({ })
    default : (any | (() => any));

    async editItem (item? : any, clone? : boolean, assign? : any) {
        if(!this.inlineEdit) {
            if(clone) {
                this.$router.push(`${this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/'}edit/?clone=${item?._id || ''}`)
            } else {
                this.$router.push(`${this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/'}edit/${item?._id || ''}`)
            }
            return;
        }
        const origin = clone ? null : item;
        if(item && item._id && this.editParams) {
            item = await (this as any).$feathers.service(this.path).get(item._id, {
                query: {
                    ...this.editParams,
                }
            });
        }
        if(this.preEdit) {
            item = await this.preEdit(item);
        }
        const newItem = _.merge({}, this.default instanceof Function ? this.default() : this.default, item, assign);
        if (clone) {
            _.unset(newItem, this.itemKey)
        }
        const result = await this.$openDialog(import('./dialogs/EditDialog.vue'), {
            provider: this,
            source: newItem,
            origin,
            renderItem: this.$scopedSlots.editor,
            slots: this.$scopedSlots,
            useEdit: false,
        }, {
            contentClass: 'editor-dialog'
        })

        if(!this.nested && (this.$route.query.edit || (this.$route.query.query as any)?.editor)) {
            this.updateUrl();
        }

        return result;
    }

    @Prop()
    saveCallback: (item : any, origin? : any) => Promise<any>;

    async save(item : any, origin? : any) {
        if(this.saveCallback) {
            const resp = await this.saveCallback(item, origin);
            if(origin) {
                for(let [k, v] of Object.entries(resp)) {
                    Vue.set(origin, k, v)
                }
            }
            return resp;
        }
        try {
            const editingId = _.get(item, this.itemKey);
            let result : any;

            const service = (this as any).$feathers.service(this.path);
            if (editingId) {
                result = await service.patch(editingId,
                    item, {
                        query: {
                            ...(this.editParams || {})
                        }
                    }
                );
                _.assign(item, result);
                if(origin) {
                    for(let [k, v] of Object.entries(result)) {
                        Vue.set(origin, k, v)
                    }
                }
            } else {
                result = await service.create(item, {
                    query: {
                        ...(this.editParams || {})
                    }
                }) as any;
                let results = result instanceof Array ? result : [result];
                _.each(results, item => {
                    const oldItem = this._loader.store.find(it => it[this.itemKey] === item[this.itemKey]);
                    if (oldItem) {
                        _.assign(oldItem, item);
                    }
                    else this._loader.store.unshift(item);
                    this.currentDeltaTotal++;
                })
            }
            this.$store.commit('SET_SUCCESS', this.$t('basic.saveSuccess'));
            return result;
        } catch(e : any) {
            this.$store.commit('SET_ERROR', e.message);
        }
    }

    async goPage(toPage : number) {
        const toIndex = Math.max(0, Math.min((this._loader?.total || 0) - 1, toPage * this.pageCount));
        if(toIndex - this.pageStart >= (this._loader?.store?.length || 0) || toIndex < this.pageStart) {
            this.pageStart = toIndex;
            this._loader?.setPageStart(toIndex)?.then?.(() => {
                this.updateCurrentPage();
            });
        } else {
            const view : any = this.$refs.scroller;
            if(toIndex - this.pageStart >= (this._loader?.store?.length ?? 0) - this.pageCount) {
                await this.checkUpdate();
            }
            if(view) {
                view.scrollToIndex(toIndex - this.pageStart);
                await Vue.nextTick();
                this.updateCurrentPage();
            }
        }
    }

    deleteItem (item : any) {
        this.$openDialog(
            import('./dialogs/DeleteDialog.vue'),
            {
                provider: this,
                item,
            }
        )
    }

    async deleteItemCore(mitem : any, delay? : boolean) {
        const service = (this as any).$feathers.service(this.path);
        await service.remove(_.get(mitem, this.itemKey));
        const fcb = () => {
            const idx = this._loader.store.findIndex(it => _.get(mitem, this.itemKey) === _.get(it, this.itemKey));
            idx !== -1 && this._loader.store.splice(idx, 1);
            this.currentDeltaTotal--;
            const cur = _.get(mitem, this.itemKey);
            // const selected = this.mselected.find(it => _.get(it, this.itemKey) === cur);
            // this.mselected = _.filter(this.mselected, it => it !== selected);
            // this.$emit('update:items', this.mitems);
        }
        if(delay) return fcb;
        fcb();
    }

    get exportFilter() {
        return (this.exportHeaders ?? this.headers ?? []).length ? {
            $useHeaders: true,
            $headers: (this.exportHeaders ?? this.headers ?? []).map(it => ({
                ...it,
                text: this.$t(it.text),
            })),
            ...this.exportFilterOverride,
        } : {...this.exportFilterOverride};
    }

    beginExport(overrdies?: any) {
        this.$openDialog(import('./dialogs/ExportDialog.vue'), {
            data: {
                path: this.path,
            },
            provider: this,
            rawQuery: {
                ...this.query,
                $sort: this.sortParams,
                ...(this.mselect && this.mselected?.length ? {
                    _id: {
                        $in: _.map(this.mselected, it => it._id),
                    }
                } : {}),
            },
            exportSlot: this.$scopedSlots.export,
            ...(overrdies || []),
        }, {
            maxWidth: '400px'
        })
    }

    toggleSort(header : DataTableHeader, append? : boolean) {
        if(!header.sortable) return;
        const field = header.sortField || header.value;

        if(append) {
            const index = this.sort.indexOf(field);
            if(index === -1) {
                this.sort.push(field);
                this.sortDesc.push(false);
            } else {
                if(this.sortDesc[index] === false) {
                    this.sortDesc.splice(index, 1, true);
                } else {
                    this.sort.splice(index, 1);
                    this.sortDesc.splice(index, 1);
                }
            }
        } else {
            if(this.sort.length === 1 && this.sort[0] === field) {
                if(this.sortDesc[0] === false)  this.sortDesc.splice(0, this.sortDesc.length, true);
                else if(this.sortDesc[0] === true) {
                    this.sort.splice(0, this.sort.length);
                    this.sortDesc.splice(0, this.sortDesc.length);
                }
            } else {
                this.sort.splice(0, this.sort.length, header.sortField || header.value);
                this.sortDesc.splice(0, this.sortDesc.length, false);
            }
        }
    }

    selectItem(item) {
        if (this.mselected.find(m => m == item)) {
            this.mselected = this.mselected.filter(m => m != item)
        } else {
            this.mselected.push(item)
        }
    }

    async multiDuplicate() {
        if (!this.mselected.length) return

        const c = await this.$openDialog(import('@feathers-client/components-internal/ConfirmDialog.vue'), {
            title: this.$t('basic.doYouWantToClone')
        }, {
            maxWidth: '400px',
        });

        if(!c) return;
        try {
            for (const m of this.mselected) {
                const item = _.cloneDeep(m)
                delete item._id
                await this.save(item)
            }
            this.$store.commit('SET_SUCCESS', this.$t('basic.success'));
        } catch(e) {
            this.$store.commit("SET_ERROR", e.message);
        }

        this.mselected = []
        this.mselect = false
    }

    async multiDelete() {
        const r = await this.$openDialog(import('./dialogs/BatchDeleteDialog.vue'), {
            provider: this,
            selected: [...this.mselected],
        });
        if(r) {
            this.mselected = []
            this.mselect = false
        }
    }

    get selectedAll() {
        return this.mselect && this.mselected.length && this.mloader && this.mselected.length === this.mloader.total;
    }

    set selectedAll(v) {
        if(v) {
            this.confirmSelectAll();
        } else {
            this.mselected = [];
        }
    }

    async confirmSelectAll() {
        if(this.mloader) {
            if(this.mloader.loaded) {
                this.mselected = this.mloader.store;
            } else {
                if(this.mloader.total > 500) {
                    const c = await this.$openDialog(import('@feathers-client/components-internal/ConfirmDialog.vue'), {
                        title: this.$t('basic.doYouWantToSelectAllMayTakeLongTime')
                    }, {
                        maxWidth: '400px',
                    });

                    if(!c) return;
                
                }
                while(!this.mloader.loaded) {
                    await this.mloader.execute();
                }
                this.mselected = this.mloader.store;
            }
        }
    }

    get selectedIndeterminate() {
        return this.mselect && !this.selectedAll && !!this.mselected.length;
    }
}

