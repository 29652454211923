
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import LRUCache from "lru-cache";
import _ from "lodash";

@Component
export default class AsyncPopulate extends Vue {
    @Prop()
    path: string;

    @Prop()
    value: any;

    @Prop({ required: false })
    args: any;

    @Prop({ type: Boolean, default: false })
    allowEmpty: boolean;

    cachedValue: any = null;
    loading = false;

    get cacheKey() {
        return this.path + JSON.stringify(this.args || null);
    }

    get cache() {
        let cache: {
            [key: string]: CacheLoader;
        } =
            (this as any).$root._asyncPopulates ||
            ((this as any).$root._asyncPopulates = {});
        let loader: CacheLoader = cache[this.cacheKey];
        if (!loader) {
            Vue.set(
                cache,
                this.cacheKey,
                (loader = new CacheLoader({
                    parent: this.$root,
                    key: this.cacheKey,
                    propsData: {
                        path: this.path,
                        args: this.args,
                    },
                }))
            );
        }
        return loader;
    }

    beforeMount() {
        this.reload();
    }

    @Watch("value")
    reload() {
        if (this.value) {
            if (typeof this.value === "object") {
                this.cachedValue = this.value;
            } else {
                this.cachedValue = null;
                this.cache.query(
                    this.value,
                    () => {
                        this.loading = true;
                    },
                    (v) => {
                        this.cachedValue = v || null;
                        this.loading = false;
                    }
                );
            }
        } else {
            this.cachedValue = null;
        }
    }
}

@Component
class CacheLoader extends Vue {
    lruCache: LRUCache<string, any>;
    queue: {
        key: string;
        resolve: (v: any) => void;
        reject: (e: any) => void;
    }[];

    @Prop()
    path: string;

    @Prop({ required: false })
    args: any;

    beforeCreate() {
        this.lruCache = new LRUCache();
        this.queue = [];
    }

    query(id: string, beginLoad: () => void, endLoad: (v?: any) => void) {
        const v = this.lruCache.get(id);
        if (v) {
            if (v instanceof Promise) {
                v.then(endLoad, (e) => {
                    endLoad();
                });
            } else {
                endLoad(v);
            }
        } else {
            const needSchedule = this.queue.length === 0;
            const p = new Promise<void>((resolve, reject) => {
                this.queue.push({
                    key: id,
                    resolve,
                    reject,
                });
            });
            this.lruCache.set(id, p);
            p.then(
                (v) => {
                    this.lruCache.set(id, v);
                    endLoad(v);
                },
                (e) => {
                    endLoad();
                }
            );

            if (needSchedule) {
                this.scheduleQueue();
            }
        }
    }

    async scheduleQueue() {
        await Vue.nextTick();
        const q = this.queue.slice();
        this.queue.splice(0, this.queue.length);

        for (let chunk of _.chunk(q, 100)) {
            try {
                const table = (await this.$feathers.service(this.path).find({
                    query: {
                        _id: {
                            $in: chunk.map((v) => v.key),
                        },
                        $limit: 100,
                        ...(this.args || {}),
                    },
                })) as any;
                const items = table.data ? table.data : table;
                if (Array.isArray(items)) {
                    const itemToDict: {
                        [key: string]: any;
                    } = {};
                    for (let item of items) {
                        itemToDict[item._id] = item;
                    }

                    for (let item of chunk) {
                        const v = itemToDict[item.key];
                        if (v) {
                            item.resolve(v);
                        } else {
                            item.reject(new Error("Not Found"));
                        }
                    }
                }
            } catch (e) {
                console.warn(e);
                for (let item of chunk) {
                    item.reject(e);
                }
            }
        }
    }
}
