var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { ref: "app", staticStyle: { width: "100%", height: "100%" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.error,
            callback: function ($$v) {
              _vm.error = $$v
            },
            expression: "error",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'",
              },
            ],
            attrs: { text: "", color: "pink" },
            nativeOn: {
              click: function ($event) {
                _vm.error = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-content",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": "", column: _vm.breakpoint.mdAndDown } },
            [
              _c(
                "v-snackbar",
                {
                  attrs: { top: "" },
                  model: {
                    value: _vm.error,
                    callback: function ($$v) {
                      _vm.error = $$v
                    },
                    expression: "error",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "basic.close",
                        expression: "'basic.close'",
                      },
                    ],
                    attrs: { text: "", color: "pink" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.error = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "loginGradient flex",
                  attrs: {
                    xs4: "",
                    lg6: "",
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": "",
                    column: !_vm.breakpoint.mdAndDown,
                  },
                },
                [
                  _c("div", { staticClass: "white--text display-1" }, [
                    _vm._v("歡迎回來"),
                  ]),
                ]
              ),
              _c(
                "v-layout",
                {
                  staticClass: "loginSide flex pa-2",
                  attrs: {
                    xs8: "",
                    lg6: "",
                    "justify-center": "",
                    "align-center": "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [_c("nuxt")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }