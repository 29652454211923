
import { Component, Prop, Watch, Vue } from '@feathers-client'
import uuid from 'uuid/v4'

@Component
export default class StatusHost extends Vue {
    get error() {
        return !!this.$store.state.error;
    }
    set error(v) {
        if(!v) {
            this.$store.commit('SET_ERROR');
        }
    }
    get errorMessage() { return this.$store.state.error }
    get success() {
        return !!this.$store.state.success;
    }
    set success(v) {
        if (!v) {
            this.$store.commit('SET_SUCCESS');
        }
    }
    get successMessage() {
        return this.$store.state.success;
    }

    
}

