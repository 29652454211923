var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.875 3C11.3227 3 10.875 3.44772 10.875 4V10.875H4C3.44772 10.875 3 11.3227 3 11.875V12.125C3 12.6773 3.44772 13.125 4 13.125H10.875V20C10.875 20.5523 11.3227 21 11.875 21H12.125C12.6773 21 13.125 20.5523 13.125 20V13.125H20C20.5523 13.125 21 12.6773 21 12.125V11.875C21 11.3227 20.5523 10.875 20 10.875H13.125V4C13.125 3.44772 12.6773 3 12.125 3H11.875Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }