
import _ from "lodash";
import uuid from "uuid/v4";
import { Component, Prop, Vue, Watch, mixins } from "@feathers-client";
import Remove from "./svg/remove.vue";
import Add from "./svg/add.vue";
import type { EditorField } from "./plugin";
import draggable from "vuedraggable";

const KeySymbol = Symbol("KeySymbol")

export interface HeaderFormatOptions {
  field: string;
  translate?: boolean;
}

@Component({
  components: {
    Add,
    Remove,
    draggable,
  },
})
export default class EditorList extends Vue {
  KeySymbol = KeySymbol;

  @Prop()
  label: string;

  @Prop()
  value: any;

  @Prop()
  default: any;

  @Prop()
  itemKey: string;

  @Prop({ type: Boolean })
  prefillKey: boolean

  @Prop({ type: Boolean, default: true })
  add: boolean;

  @Prop()
  itemName: any;

  editing = false;

  @Prop({ type: Boolean, default: true })
  remove: boolean;

  @Prop({ type: Boolean })
  overview: boolean;

  @Prop({ type: Boolean })
  tabView: boolean;

  @Prop()
  defaultItems: any[];

  @Prop()
  addFunc: any;

  @Prop({ type: Boolean, default: false })
  showSeparator: boolean;

  @Prop()
  itemFields: EditorField[];

  @Prop(Boolean)
  readonly: boolean;

  @Prop({ default: "gap-y-6" })
  containerClass;

  get inputValue(): any[] {
    return this.value;
  }
  set inputValue(v) {
    this.$emit("input", v);
  }

  editingIdx = 0;
  get editingItem() {
    return this.value?.[this.editingIdx];
  }

  getDefault() {
    const v =
      this.default instanceof Function
        ? this.default()
        : this.default
        ? _.cloneDeep(this.default)
        : {};
    v[this.prefillKey ? this.itemKey : KeySymbol] = uuid();
    return v;
  }

  mounted() {
    if (!this.inputValue || !this.inputValue.length) {
      if (this.defaultItems) {
        const items: any[] = [];
        for (let it of this.defaultItems) {
          items.push(_.defaultsDeep(it, this.getDefault()));
        }
        this.inputValue = items;
      }
    }
  }

  getEdit(item: any, idx: number) {
    const self = this;
    return {
      get item() {
        return item;
      },
      set item(v) {
        self.inputValue = [...self.inputValue.slice(0, idx), v, ...self.inputValue.slice(idx + 1)];
      },
      remove() {
        self.removeItem(idx);
      }
    };
  }

  @Prop()
  headers: HeaderFormatOptions[];

  get headerFields() {
    return this.headers?.length
      ? this.headers.map((it) => {
          const field = this.itemFields?.find?.((f) => f.path === it.field);

          if (field?.component === "editor-object-picker") {
            if (field.type === "string") {
              return {
                text: (item) =>
                  this.$td(
                    field.props?.items?.find?.((f) => f._id === item[field.path])?.name ??
                      item[field.path],
                  ),
              };
            } else if (field.type === "id") {
              return {
                path: field.props.path,
                value: (item) => item[field.path],
              };
            }
          } else if (field?.component === "editor-translate-box") {
            return {
              text: (item) => this.$td(item[field.path]),
            };
          }

          return {
            text: (item) =>
              it.translate
                ? this.$td(item[field?.path ?? it.field])
                : item[field?.path ?? it.field],
          };
        })
      : [];
  }

  get formatHeader() {
    if (!this.headers || !this.headers.length) {
      const itemKey = this.itemKey;
      return (it) => it[itemKey];
    }
    const fields = this.headers.map(
      (header) => (it) => header.translate ? this.$td(it[header.field]) : it[header.field],
    );
    return (it) => fields.map((f) => f(it)).join("/");
  }

  async addItem() {
    if (this.addFunc) {
      this.addFunc();
    } else {
      const newItem = this.getDefault();
      if (this.inputValue) {
        this.inputValue = [...this.inputValue, newItem];
      } else {
        this.inputValue = [newItem];
      }
      await Vue.nextTick();
      if (this.tabView) {
        this.editingIdx = Math.max(0, this.inputValue.length - 1);
      }
      this.$emit('add', newItem);
    }
  }

  async removeItem(idx: number) {
    this.$emit("removeItem", this.inputValue[idx], idx);
    this.inputValue = [...this.inputValue.slice(0, idx), ...this.inputValue.slice(idx + 1)];
    if (idx >= this.editingIdx) idx--;
    this.editingIdx = idx;

    if(!this.inputValue?.length) {
      this.editing = false;
    }
  }
}
