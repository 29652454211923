var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "rounded-full radio-border border-1 d-flex items-center justify-center box-content",
      class: [_vm.checkerClass, { checked: _vm.value, unchecked: !_vm.value }],
    },
    [_c("div", { staticClass: "rounded-full w-1/2 h-1/2 radio-bg" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }