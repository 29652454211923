

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { FindType } from '@feathers-client'

@Component({
})
export default class GeoJson extends Vue {

    @Prop()
    value : any

    get inputValue() { return this.value || {} }
    set inputValue(v) { this.$emit('input', v) }

    get lat() {
        return this.coordinates[1];
    }

    set lat(v : number) {
        this.coordinates = [this.lng, v];
    }

    get lng() {
        return this.coordinates[0];
    }

    set lng(v : number) {
        this.coordinates = [v, this.lat];
    }

    get coordinates() { return this.inputValue?.coordinates || [] }
    set coordinates(v) {
        this.inputValue = {
            ...this.inputValue,

                type: "Point",
                coordinates: v,
            
        }
    }

    // get url() { return this.inputValue?.url }
    // set url(v) {
    //     this.inputValue = {
    //         ...this.inputValue,
    //         url: v,
    //     }
    // }

    // get place() { return this.inputValue?.place }
    // set place(v) {
    //     this.inputValue = {
    //         ...this.inputValue,
    //         place: v,
    //     }
    // }
}

