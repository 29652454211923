
import _ from 'lodash'
import qs from 'qs'
import url from 'url'
import { DataTablePagination, DataTablePaginated } from './index'

import { VDataTable, VDataIterator, VContainer, VRow } from 'vuetify/lib'
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

import SelectProvider from './mixins/SelectProvider'

interface MSearch {
    $and? : any[];
}

function escapeRegExp (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

@Component({
    components: {
        VDataTable,
        VDataIterator,
        VContainer,
        VRow,
    }
})
export default class DataTable extends mixins(SelectProvider) {
    @Prop()
    rootPath : string

    @Prop()
    dataData : DataTablePaginated

    @Prop({ })
    default : (any | (() => any));

    @Prop({})
    defaultSearch : any;

    @Prop({})
    searchFields : any;

    @Prop({ type: Boolean })
    expand : boolean

    // show rud buttons on row
    @Prop({ type: Boolean })
    actions : boolean

    @Prop({ type: Boolean })
    actionsRight: boolean

    // actions column shrink width
    @Prop({ type:Boolean, default:false })
    actionsShrink: boolean

    // show toolbar (title, add button)
    @Prop({ type: Boolean })
    hideToolBar : boolean

    @Prop({ type: String })
    add : string

    @Prop({ type: Boolean, default: false })
    noAdd : boolean

    @Prop({ type: Boolean, default: false })
    addAction : boolean

    @Prop({ type: Boolean, default: false })
    noClone : boolean

    @Prop({ type: Boolean, default: false })
    noEdit : boolean

    @Prop({ type: Boolean, default: false })
    noRemove : boolean

    @Prop({ type: Boolean, default: false })
    search : boolean

    @Prop({ type: Boolean, default: false })
    searchAction : boolean

    @Prop({ type: Boolean, default: false })
    noExport : boolean

    @Prop({ type: Boolean, default: false })
    exportAction : boolean

    @Prop({ type: Boolean, default: false })
    refreshAction : boolean

    @Prop({ type: Boolean, default: false })
    hideRowsPerPage : boolean

    @Prop({ type: Boolean, default: false })
    hideTotal : boolean

    @Prop({ type: Boolean, default: false })
    autoHidePage : boolean

    @Prop({ type: Boolean, default: false })
    disableInlineEdit : boolean

    @Prop({ type: Boolean, default: false })
    clickable : boolean

    @Prop({ type: Boolean, default: false })
    iterator : boolean

    @Prop({ type: Boolean, default: false })
    fillHeight : boolean

    @Prop({ type: Boolean, default: false })
    flat : boolean

    @Prop({ default: 10 })
    paginate : number;

    // container
    @Prop()
    contentProps : any;

    @Prop()
    contentTag : any;

    @Prop()
    contentClass : any;

    @Prop({ type: Boolean, default: false })
    hideSearchDialog: boolean;

    @Prop()
    localSearch : string;

    @Prop()
    localSearchFilter : any;

    @Prop()
    customGroup : any;

    @Prop({ type: Boolean, default: false })
    itemCanClick : boolean

    @Prop()
    checkWrite : (item : any) => boolean

    @Prop()
    checkRemove : (item : any) => boolean

    loading = false;
    session = 0;
    mpagination : DataTablePagination = null;

    searching = false;
    searchFilter : any = null;
    
    @Watch('data')
    onDataChanged (d, od) {
        if (!_.isEqual(d, od)) {
            this.mdata = null;
            this.searching = false;
            if(!this.source) {
                this.reload();
            }
        }
    }

    get dispHeaders() {
        const actionHeaderItem = { text: '', value: '', sortable: false }
        if(this.actionsShrink) {
            _.set(actionHeaderItem, 'width', 0)
        }
        const actionHeader = this.actions ? [actionHeaderItem] : []
        return [
            ...(!this.actionsRight ? actionHeader : []),
            ...this.mheaders,
            ...(this.actionsRight ? actionHeader : []),
        ]
    }

    get mheaders() {
        return _.map(this.headers, row => _.pick(row, 'text', 'sortable', 'value'))
    }

    get page() {
        return this.pagination.page
    }
    set page(page) { 
        this.pagination = _.merge({}, this.pagination, {page})
    }

    get pages () {
        return this.pagination.rowsPerPage === false ? 0 : (this.pagination && Math.ceil(this.pagination.total / this.pagination.rowsPerPage)) || 0;
    }

        // editing: {
        //     get () {
        //         return !!this.editingItem;
        //     },
        //     set (v) {
        //         if (v && !this.editingItem) {
        //             console.warn('No editing item');
        //         } else if (!v) this.editingItem = null;
        //     }
        // },
        // editingItem: {
        //     get () { return this.closedEditing ? this.meditingItem : this.data && this.data.editingItem },
        //     set (val) {
        //         this.meditingItem = val;
        //         this.closedEditing = true;
        //         if (this.data.query) {
        //             const u = url.parse(window.location.toString());
        //             const query = qs.parse(u.query) || {};
        //             query[this.data.query] = _.merge(query[this.data.query], {
        //                 editor: val && val._id
        //             });
        //             if (!query[this.data.query].editor) {
        //                 delete query[this.data.query].editor;
        //             }
        //             window.history.replaceState(null, null, url.resolve(this.$route.fullPath, '?' + qs.stringify(query)));
        //         }
        //     }
        // },

    // pagination
    get pagination() : DataTablePagination {
        if (this.data.static || !this.data.path) {
            return this.mpagination ? {
                ...this.mpagination,
                total: this.mitems.length,
            } : {
                rowsPerPage: this.paginate ? this.paginate : -1,
                page: 1,
                total: this.mitems.length,
                sortBy: this.data.sortBy || [],
                sortDesc: this.data.sortDesc || [],
                currentFilter: null,
            };
        }
        if (!this.source) {
            return {
                rowsPerPage: this.paginate ? this.paginate : -1,
                sortBy: this.data.sortBy || [],
                sortDesc: this.data.sortDesc || [],
            };
        }
        return {
            rowsPerPage: !this.paginate ? -1 : this.source.limit,
            page: this.cursor || !this.paginate ? 1 : Math.floor(this.source.skip / this.source.limit) + 1,
            total: this.cursor ? 999 : this.source.total + this.currentDeltaTotal,
            cursor: this.cursor ? this.source.skip : null,
            sortBy: this.source.sortBy || [],
            sortDesc: this.source.sortDesc || [],
            currentFilter: this.source.currentFilter,
        }
    }
    set pagination(pagination) {
        this.setPagination(pagination);
    }

    reload () {
        this._fetchCache = {};
        return this.setPagination(this.pagination, true);
    }

    @Prop(Boolean)
    needReload : boolean

    async mounted() {
        const u = url.parse(this.$route.fullPath);
        let query : any = (this.data.query && qs.parse(u.query)[this.data.query]) || {};
        if(typeof query === 'string') {
            try {
                query = JSON.parse(query);
            } catch(e) {
                query = {};
            }
        }
        if(this.needReload) {
            const parsed : any = {};

            if (query.sortBy === undefined && this.data.sortBy !== undefined) {
                query.sortDesc = (this.data.sortDesc !== undefined ? this.data.sortDesc : false) || false;
                query.sortBy = this.data.sortBy;
            }

            if(typeof query.sortBy === 'string') {
                query.sortBy = [query.sortBy];
            }
            if(typeof query.sortDesc === 'boolean') {
                query.sortDesc = [query.sortDesc || false];
            }

            if(query.sortDesc) {
                query.sortDesc = _.map(query.sortDesc, q => typeof q === 'string' ? q === 'true' : q);
            }

            if(query.currentFilter) parsed.currentFilter = query.currentFilter
            if(query.page) parsed.page = query.page
            if(query.rowsPerPage) parsed.rowsPerPage = query.rowsPerPage
            if(query.sortBy) parsed.sortBy = query.sortBy
            if(query.sortDesc) parsed.sortDesc = query.sortDesc

            this.setPagination({
                ...this.pagination,
                ...parsed
            }, true);
        }
        if(query.editor && this.data?.path) {
            try {
                const service = this.data.path && (this as any).mfeathers.service(this.data.path);
                const item = await service.get(query.editor, {
                    ...this.data.filter || {},
                    ...(this.data.populate ? { $populate: this.data.populate } : {})
                })
                this.editItem(item);
            } catch(e) {
                console.warn(e);
            }
        }
    }

    invalidate () {
        this.pagination = {};
    }

    get rowsPerPage() {
        return this.pagination.rowsPerPage
    }
    set rowsPerPage(val) {
        this.pagination = { ...this.pagination, rowsPerPage: val }
    }

    @Prop({ type: Array, default: () => [5, 10, 20, 100]})
    rowsPerPageItems: number[]

    get pageText () {
        if (!this.paginate) return '';
        const p = this.pagination;
        if (!p.total) return '-';
        if(p.rowsPerPage === false) return '';
        return `${(p.page - 1) * p.rowsPerPage + 1}-${Math.min(p.total, p.page * p.rowsPerPage)}/${p.total}`;
    }

    setCursor (cursor) {
        this.pagination = {
            ...this.pagination,
            cursor,
        }
    }

    // search
            
    async beginSearch () {
        if (!this.searchFilter) {
            this.resetFilter();
        }
        if(this.$vuetify.breakpoint.mdAndUp) {
            this.searching = !this.searching;
        } else if (!this.hideSearchDialog) {
            const resp = await this.$openDialog(import('./dialogs/SearchDialog.vue'), {
                renderSearch: this.$scopedSlots.search,
                defaultSearch: this.defaultSearch,
                current: this.searchFilter,
                searchFields: this.searchFields,
            })
            if(!resp) return;
            this.searchFilter = resp;
            this.applyFilter();
        }
    }

    resetFilter () {
        this.searchFilter = _.cloneDeep(this.source?.currentFilter || this.defaultSearch || <any>{});
    }

    applyFilter () {
        const result = this.setPagination({ ...this.pagination, page: 1, currentFilter: _.cloneDeep(this.searchFilter) }, true);
        // this.resetFilter();
        this.searching = false;
        return result;
    }

    sortBy(sortBy, sortDesc) {
        return this.setPagination({ ...this.pagination, page: 1, sortBy, sortDesc }, true);
    }

    // query login

    _settingPagiation! : DataTablePagination;
    _settingPagiationTask! : Promise<DataTablePagination>;

    setPagination (pagination : DataTablePagination, force? : boolean) {
        if (force) return this.setPaginationCore(pagination, force);
        if (this._settingPagiationTask && _.isEqual(this._settingPagiation, pagination)) {
            return this._settingPagiationTask;
        }
        this._settingPagiation = pagination;
        return (this._settingPagiationTask = this.setPaginationCore(pagination, force));
    }

    async setPaginationCore (pagination : DataTablePagination, force? : boolean) {
        try {
            const q = _.pick(pagination, ...(!this.cursor ? ['page', 'rowsPerPage', 'sortBy', 'sortDesc', 'total', 'currentFilter'] : ['rowsPerPage', 'sortBy', 'sortDesc', 'cursor', 'currentFilter']));
            const mq = _.pick(this.pagination, ...(!this.cursor ? ['page', 'rowsPerPage', 'sortBy', 'sortDesc', 'total', 'currentFilter'] : ['rowsPerPage', 'sortBy', 'sortDesc', 'cursor', 'currentFilter']));

            // 0 -> disable in paginated route, false -> array route
            if (this.paginate === 0) {
                // q.rowsPerPage = -1;
                mq.rowsPerPage = -1;
                // q.page = 1;
                mq.page = 1;
            }
            if (this.data.path && this.data.subpath) {
                const session = ++this.session;
                this.loading = true;
                try {
                    const result = await this.runQuery(this.data, q);
                    if (this.session === session) {
                        this.fetchValue = result;
                        this.$emit('fetchValue', this.fetchValue);
                    }
                } finally {
                    if (this.session === session) this.loading = false;
                }
            }
            if (this.data.static || !this.data.path || this.data.subpath) return (this.mpagination = pagination);
            if (process.server) return;
            if (_.isEqual(q, mq) && !force && this.source) return;
            if (this.data.query) {
                const u = url.parse(window.location.toString());
                const query : any = qs.parse(u.query) || {};
                query[this.data.query] = JSON.stringify(_.omit(q, 'total'));
                window.history.replaceState(null, null, url.resolve(this.$route.fullPath, '?' + qs.stringify(query)));
            }
            const session = ++this.session;
            this.loading = true;
            try {
                const result = await this.runQuery(this.data, q);
                if (this.session === session) {
                    this.mdata = result;
                    this.currentDeltaTotal = 0;
                    this.$emit('update:dataData', this.mdata);
                }
            } finally {
                if (this.session === session) this.loading = false;
            }
            if (this.data.prepend) {
                this.mitems.splice(0, 0, ...this.data.prepend);
            }
            this.$emit('update:items', this.mitems);
            this.$emit('update:pagination', this.pagination);
        } finally {
            this._settingPagiation = null;
            this._settingPagiationTask = null;
        }
    }

    toggleAll() {
        switch(this.selectState) {
            case 'none':
            case 'pageSome':
            case 'some':
                this.mselected = _.uniqBy(_.concat(this.mselected, this.mitems), it => _.get(it, this.mItemKey));
                break;
            case 'all':
            case 'pageAll':
                const dict = _.fromPairs(_.map(this.mitems, it => [_.get(it, this.mItemKey), true]));
                this.mselected = _.filter(this.mselected, it => !dict[_.get(it, this.mItemKey)]);
                break;
        }
    }

    getSearchComputed() {
        if(!this.searchFields) return {};
        const obj = {};

        const addSearchFilter = (id, sub, type) => {
            return {
                get: () => {
                    const list = _.get(this.searchFilter, '$and') || [];
                    const it = list.find(it => it.hasOwnProperty(id));
                    let val = it ? sub ? _.get(it[id], sub) : it[id] : undefined;
                    switch(type) {
                        case 'regstart':
                        case 'regexp':
                        case 'regstart-case':
                        case 'regexp-case':
                        case 'regstart-uppercase':
                        case 'regexp-uppercase':
                        case 'regstart-lowercase':
                        case 'regexp-lowercase':
                            val = _.get(val, '$regex') || ''
                            if(type.startsWith('regstart')) val = (val || '').substr(1);
                            val = val.replace(/\\(.)/g, (r, c) => {
                                return c;
                            })
                            break;
                        case 'array':
                            val = val || [];
                            break;
                    }
                    return val;
                },
                set: (value) => {
                    switch(type) {
                        case 'regstart':
                        case 'regexp':
                        case 'regstart-case':
                        case 'regexp-case':
                        case 'regstart-uppercase':
                        case 'regexp-uppercase':
                        case 'regstart-lowercase':
                        case 'regexp-lowercase':
                            value = value ? {
                                $regex: `${type.startsWith('regstart') ? '^' : ''}${type.endsWith('lowercase') ? escapeRegExp(value).toLowerCase() : type.endsWith('uppercase') ? escapeRegExp(value).toUpperCase() : escapeRegExp(value)}`,
                                $options: (type.endsWith('case') ? '' : 'i') + (type.startsWith('regexp') ? 'g' : '')
                            } : undefined
                            break;
                        case 'array':
                            value = value && value.length ? value : undefined;
                            break;
                    }
                    if(!this.searchFilter) this.searchFilter = _.cloneDeep(this.defaultSearch || <any>{});
                    if(!this.searchFilter.$and) this.searchFilter.$and = [];
                    let list = this.searchFilter.$and;
                    const it = list.find(it => it.hasOwnProperty(id));
                    if(it) {
                        if(sub) {
                            if(value) {
                                Vue.set(it[id], sub, value);
                            } else {
                                delete it[id][sub];
                            }
                            if(_.isEmpty(it[id])) {
                                const idx = list.indexOf(it);
                                idx !== -1 && list.splice(idx, 1); 
                            }
                        } else {
                            if(value) it[id] = value;
                            else {
                                const idx = list.indexOf(it);
                                idx !== -1 && list.splice(idx, 1);
                            }
                        }
                    }
                    else if(value) {
                        list.push({
                            [id]: sub ? _.set({}, sub, value) : value
                        })
                    }
                }
            }
        }

        _.each(this.searchFields, (v, id) => {
            if(typeof v === 'object') {
                const sobj = {};
                _.each(v, (vv, sub) => {
                    Object.defineProperty(sobj, sub, addSearchFilter(id, sub, vv));
                })
                obj[id] = sobj;
            } else {
                Object.defineProperty(obj, id, addSearchFilter(id, undefined, v));
            }
        })
        return obj;
    }

    deleteItem (item : any) {
        this.$openDialog(
            import('./dialogs/DeleteDialog.vue'),
            {
                provider: this,
                data: this.data,
                item,
            }
        )
    }

    batchDelete() {
        this.$openDialog(import('./dialogs/BatchDeleteDialog.vue'), {
            provider: this,
            data: this.data,
            selected: [...this.mselected],
        })
    }

    @Prop()
    preEdit: (item : any) => Promise<void>;

    async editItem (item? : any, clone? : boolean, assign? : any) {
        const origin = clone ? null : item;
        const newItem = _.merge({}, this.default instanceof Function ? this.default() : this.default, this.data.filter, item, assign);
        if (clone) {
            _.unset(newItem, this.mItemKey)
        }
        if(this.preEdit) {
            await this.preEdit(newItem);
        }
        this.$openDialog(import('./dialogs/EditDialog.vue'), {
            data: this.data,
            provider: this,
            source: newItem,
            origin,
            renderItem: this.$scopedSlots.editor,
        }, {
            contentClass: 'editor-dialog'
        })
    }

    // actions
    itemOnClick(item) {
        this.$emit('itemOnClick', item)
    }

    addActionClick () {
        if (this.addAction) {
            this.editItem();
        }
    }

    searchActionClick () {
        if (this.searchAction) {
            this.beginSearch();
        }
    }

    exportActionClick () {
        if (this.exportAction) {
            this.beginExport();
        }
    }

    beginExport() {
        this.$openDialog(import('./dialogs/ExportDialog.vue'), {
            data: this.data,
            provider: this,
            query: _.cloneDeep(this.pagination),
            exportSlot: this.$scopedSlots.export,
        }, {
            maxWidth: '400px'
        })
    }

    refreshActionClick() {
        if (this.refreshAction) {
            this.reload();
        }
    }

    renderContainer(_c, { scopedSlots }) {
        return (this.$scopedSlots.container || scopedSlots.container)({
            renderList: this.$scopedSlots.list || scopedSlots.list,
            renderPaginate: this.$scopedSlots.paginate || scopedSlots.paginate,
            pagination: this.pagination,
            setPage: page => this.page = page,
        });
    }
}

