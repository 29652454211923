
import _ from "lodash"
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType, checkID, getID } from "@feathers-client";
// @ts-ignore
import type VirtualDataList from 'domore-table/VirtualDataList.vue'
// @ts-ignore
import draggable from "vuedraggable";

export interface SearchConfig {
    field: string,
    mode: 'regstart' | 'regstart' | 'regexp' | 'regstart-case' | 'regexp-case' | 'regstart-uppercase' | 'regexp-uppercase' | 'regstart-lowercase' | 'regexp-lowercase' | 'none'
    regexp?: string,
    regopt?: string,
    mongo?: any,
    mongoField?: string,
    translate?: boolean
}

function escapeRegExp (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function normalizeConfig(conf : string | SearchConfig, value: string) {
    const cfg : SearchConfig = typeof conf === 'string' ? {
        field: conf,
        mode: 'regexp',
    } : {mode: 'regexp', ...conf};

    if(value) {
        if(cfg.field === '_id') {
            if(/^[a-f\d]{24}$/i.test(cfg.mongo))
                cfg.mongo = value;
            else {
                cfg.mongo = null;
            }
        } else if(cfg.mode !== 'none') {
            const regstr = `${cfg.mode.startsWith('regstart') ? '^' : ''}${cfg.mode.endsWith('lowercase') ? escapeRegExp(value).toLowerCase() : cfg.mode.endsWith('uppercase') ? escapeRegExp(value).toUpperCase() : escapeRegExp(value)}`;
            const regopt = (cfg.mode.endsWith('case') ? '' : 'i') + (cfg.mode.startsWith('regexp') ? 'g' : '');

            cfg.regexp = regstr;
            cfg.regopt = regopt;
            cfg.mongoField = cfg.translate ? cfg.field + '.value' : cfg.field;
            cfg.mongo = {
                $regex: regstr,
                $options: regopt,
            }
        } else {
            cfg.regexp = null;
            cfg.regopt = null;
            cfg.mongo = null;
        }
    } else {
        cfg.regexp = null;
        cfg.regopt = null;
        cfg.mongo = null;
    }
    return cfg;
}

@Component({
  components: {
    draggable,
  }
})
export default class MenuListPicker extends Vue {

    @Prop()
    path: string

    @Prop()
    enterkeyhint: string

    /**
     * Display fields
     */
    @Prop({ type: Array, default: () => ['name'] })
    fields: (string | SearchConfig)[]

    /**
     * Searchable fields
     */
    @Prop({ type: Array, default: null })
    searchFields: (string | SearchConfig)[]

    @Prop(Boolean)
    serverSearch: boolean

    @Prop()
    rowIcon: string

    @Prop({ default: '$tick' })
    activeIcon: string

    @Prop()
    items: any[]

    @Prop(Boolean)
    combo: boolean

    @Prop(Boolean)
    multiple: boolean

    @Prop()
    query: any

    @Prop(Boolean)
    readonly: boolean

    @Prop(Boolean)
    ordered: boolean

    @Prop({ default: 'border-orange100' })
    activeClass: string

    @Prop({ default: 'border-transparent' })
    inactiveClass: string

    @Prop({ type: Boolean, default: false })
    preferSingle: boolean

    @Prop({ type: Boolean })
    returnObject: boolean

    @Prop(Boolean)
    mandatory: boolean

    filterQuery : any = {};

    get finalQuery() {
        return {
            ...(this.query || {}),
            ...this.filterQuery,
        }
    }

    get keySet() {
        return this.multiple ? Object.fromEntries(Array.isArray(this.inputValue) ? this.inputValue.map((it, idx) => [getID(it), idx + 1]) : []) : this.inputValue ? {
            [getID(this.inputValue)]: 1
        } : {};
    }

    get normalizedFields() {
        return this.fields.map(f => normalizeConfig(f, this.searchKeyword));
    }

    get normalizedSearchFields() {
        return this.searchFields?.map?.(f => normalizeConfig(f, this.searchKeyword)) ?? [];
    }

    get cols() {
        return Math.max(1, (this.fields?.length ?? 0) + (this.searchFields?.length ? 1 : 0))
    }

    get colStyle() {
        return `flex-basis: ${100 / this.cols}%; word-break: break-word;`;
    }

    getter(item: any, field: SearchConfig) {
        const v = _.get(item, field.field);
        if(field.translate) return this.$td(v);
        return v;
    }

    removeItem(item: any) {
        if(this.multiple) {
            if(Array.isArray(this.inputValue)) {
                this.inputValue = this.inputValue.filter(it => !checkID(it, item));
            }
        } else {
            this.inputValue = null;
        }
    }

    chooseItem(item : any) {
        if(this.multiple) {
            if(item) {
                if(this.closeTimer) {
                    clearTimeout(this.closeTimer);
                    this.closeTimer = null;

                    (this.$refs.input as any)?.focus?.();
                }
                let iv = Array.isArray(this.inputValue) ? this.inputValue : [];
                if(iv.find(it => checkID(it, item))) {
                    this.inputValue = iv.filter(it => !checkID(it, item));
                } else {
                    if(!Array.isArray(this.maybeValue)) {
                        this.maybeValue = [];
                    }
                    if(!this.maybeValue.find(it => checkID(it, item))) {
                        this.maybeValue.push(item);
                    }
                    this.inputValue = [
                        ...iv,
                        this.returnObject ? item : getID(item),
                    ]
                }
            } else {
                this.searchKeyword = '';
                this.inputValue = [];
            }
        } else {
            this.inputValue = getID(item);
            this.maybeValue = item;
            (this.$refs.input as any)?.blur?.();
            if(item && this.combo) {
                const valueField = this.normalizedFields.map(it => this.getter(item, it)).find(it => !!it);
                if(valueField) {
                    this.searchKeyword = valueField;
                }
            } else {
                this.searchKeyword = '';
            }
        }
    }

    onMouseDown(e: MouseEvent) {
    }

    @Prop()
    textValue: string

    @VModel()
    inputValue: string | string[];

    beforeMount() {
        if(this.textValue) {
            this.searchKeyword = this.textValue;
        }
    }

    @Watch('textValue')
    onTextValue(v, ov) {
        if(v !== ov && v !== this.searchKeyword) {
            this.searchKeyword = v;
        }
    }

    @Watch('searchKeyword')
    onKeyword(v, ov) {
        if(v !== ov) {
            this.$emit('update:textValue', v);
        }
    }

    onCachedLoaded(item) {
        this.cachedValue = item;
        this.$emit('update:cachedValue', item)
        if(!this.multiple) {
            if(this.combo && this.hasCachedValue && !this.searchKeyword) {
                this.searchKeyword = this.cachedName;
            }
        }
    }

    get cachedItems() {
        const source = this.items || (Array.isArray(this.cachedValue) ? this.cachedValue : this.cachedValue ? [this.cachedValue] : []);
        const normalized = this.multiple ? 
            Array.isArray(this.inputValue) ? this.inputValue : this.inputValue ? [this.inputValue] : [] :
            this.inputValue ? [this.inputValue] : [];

        return normalized.map(item => {
            return source.find(it => checkID(item, it)) || item;
        });
    }

    set cachedItems(items: any[]) {
        const ids = items.map(it => getID(it));
        if(this.multiple) {
            this.inputValue = ids;
        } else {
            this.inputValue = ids[0] ?? null;
        }
    }

    get cachedItemsEditor() {
        const self = this;
        return {
            get items() { return self.cachedItems },
            set items(v) { self.cachedItems = v }
        }
    }

    get cachedName() {
        const iv = this.inputValue;
        const cv = this.cachedItems;
        if(cv.length) {
            return cv.map(cv => {
                return (this.normalizedFields.map(it => this.getter(cv, it)).find(it => !!it) || cv._id);
            }).join(', ');
        }
        return Array.isArray(iv) ? iv.join(', ') : iv;
    }

    get cachedNames() : [string, string,any][] {
        const iv = this.inputValue;
        const cv = this.cachedItems;
        if(cv.length) {
            return cv.map(cv => {
                return [
                  cv?._id ?? cv,
                  (this.normalizedFields?.map(it => this.getter(cv, it))?.find(it => !!it) || cv._id) ?? cv,
                  cv,
                ];
            });
        }
        return Array.isArray(iv) ? iv.map(it => [it, it, it]) : iv ? [[iv, iv, iv]] : [];
    }

    set cachedNames(v: [string,string,any][]) {
      if(this.multiple) {
        this.inputValue = v.map(it => this.returnObject ? it[2] : it[0])
      }
    }

    currentPage = '';
    searchKeyword = '';
    maybeValue: any = null;
    cachedValue: any = null;

    get hasCachedValue() {
        return this.multiple ? Array.isArray(this.cachedValue) && !!this.cachedValue.length : !!this.cachedValue;
    }

    get displayKeyword() {
        return this.activeMenu ? this.searchKeyword : (this.combo ? this.searchKeyword : '');
    }

    activeMenu = false;

    setSearch(v) {
        this.searchKeyword = v;
        this.queueSearch();
    }

    queueSearch() {
        if(this.combo) {
            this.inputValue = null;
        }
        this.goSearch();
    }

    goSearch() {
        if(this.serverSearch) {
            this.filterQuery = {
                $keyword: this.searchKeyword,
            }
        } else {
            const conds = this.normalizedFields.concat(this.normalizedSearchFields).filter(it => !!it.mongo).map(it => ({
                [it.mongoField || it.field]: it.mongo
            }));
            this.filterQuery = {
                ...(conds.length ? {
                    $or: conds
                } : {}),
            }
        }
    }

    closeTimer: any = null;

    onFocus() {
        if(this.readonly) return;
        if(this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = null;
        }
        this.activeMenu = true;
    }

    onBlur() {
        if(this.closeTimer) {
            clearTimeout(this.closeTimer);
            this.closeTimer = null;
        }
        this.closeTimer = setTimeout(() => {
            this.activeMenu = false;
            this.closeTimer = null;
        }, 100);
    }

    @Ref()
    dataList : VirtualDataList;

    submit() {
        if(!this.dataList) return;
        const item = this.dataList.items[0];
        if(item) {
            if(this.keySet[item._id]) {
                this.activeMenu = false;
                return;
            }
            this.chooseItem(item)
            if(!this.combo) {
                this.searchKeyword = '';
            }
        }
    }

    onBackspace() {
        if(this.multiple) {
            if(Array.isArray(this.inputValue)) {
                this.inputValue = this.inputValue.slice(0, -1);
            }
        } else {
            this.inputValue = null;
        }
    }

    clickOuter() {
      if(!this.multiple) {
        (this.$refs.input as any)?.$el?.focus?.();
      }
    }

}

