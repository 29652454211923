
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ImportFileSource, ImportFileData } from './base'

@Component
export default class ImportFolderSource extends mixins(ImportFileSource) {
    type = 'folder' as const
    fileList : FileList = null;

    @Watch('fileList')
    onUpdate() {
        this.reset();
    }

    async prepare() {
        if(!this.fileList) return false;
        for(let i = 0; i < this.fileList.length; i++) {
            const file = this.fileList[i];
            const path = (file as any).webkitRelativePath || file.name || 'file_' + i;
            this.addFile(new ImportFileData({
                path: path,
                data: file,
                mime: (file as any).mime,
            }))
        }
        return true;
    }

}

