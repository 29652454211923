var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex items-center relative box-border border-2 rounded",
      class: this.active ? "border-orange100" : "border-transparent",
    },
    [
      _c(
        "div",
        {
          class: { disabled: _vm.readonly },
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              _vm.inputValue--
            },
          },
        },
        [_c("v-icon", { staticClass: "custom-icon" }, [_vm._v("$minus")])],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex-grow",
          on: {
            touchstart: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              _vm.numPad = !_vm.numPad
            },
          },
        },
        [
          _c("menu-list-input", {
            staticClass: "w-full text-center outline-none",
            class: {
              "text-grey300": !_vm.customReceived && (_vm.numPad || _vm.active),
            },
            attrs: {
              "select-all": "",
              type: "number",
              enterkeyhint: _vm.enterkeyhint,
              value: _vm.customReceived || _vm.inputValue,
              readonly: _vm.readonly,
            },
            on: {
              input: function ($event) {
                _vm.customReceived = $event
              },
              focus: _vm.focused,
              blur: _vm.submitValue,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: { disabled: _vm.readonly },
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              _vm.inputValue++
            },
          },
        },
        [_c("v-icon", { staticClass: "custom-icon" }, [_vm._v("$plus")])],
        1
      ),
      _c("teleport-menu", {
        attrs: {
          wrap: "",
          placement: "bottom",
          size: "auto",
          offset: "center",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("num-pad", {
                  staticClass: "pos-popup rounded-lg",
                  attrs: { string: _vm.customReceived, "no-default": "" },
                  on: {
                    "update:string": function ($event) {
                      _vm.customReceived = $event
                    },
                    confirm: function ($event) {
                      _vm.numPad = false
                    },
                  },
                  model: {
                    value: _vm.inputValue,
                    callback: function ($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.numPad,
          callback: function ($$v) {
            _vm.numPad = $$v
          },
          expression: "numPad",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }