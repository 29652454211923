
import { Component, Prop, Vue, Watch, mixins,FindType, VModel, getID } from "@feathers-client";
import ItemPickerBase from "../components-internal/ItemPickerBase.vue"

@Component({
})
export default class ItemPicker extends mixins(ItemPickerBase) {
  @Prop(Boolean)
  light: boolean
}

