
export * from 'nuxt-property-decorator'
export * from './util'
import Vue from 'vue'
import { createDecorator } from 'vue-class-component'
import VueClassComponent from 'vue-class-component'
import qs from 'querystring'
import { Component as NuxtComponent } from 'nuxt-property-decorator'
import nuxtend from './nuxtend'
import { AppApplication } from 'serviceTypes';
import { 
    // @ts-ignore
    FindType as ServerFindType,
    // @ts-ignore
    FindPopType as ServerFindPopType,
    // @ts-ignore
    FindPopRawType as ServerFindPopRawType,
} from '@feathersjs/feathers'
// @ts-ignore
import { DB } from '@db'

type ExpandType<T> = T extends { _objectType?: infer U } ? U : never;

export interface SetCurrentApp {
};

export type CurrentApp = (SetCurrentApp extends { app : infer T } ? T : AppApplication) extends infer U ? U : AppApplication;
export type CurrentDB = (SetCurrentApp extends { db : infer T } ? T : DB) extends infer U ? U : DB;

export type FindType<TRoute extends keyof CurrentApp> = ServerFindType<TRoute, CurrentApp>
export type FindPopType<TPop extends { 
    readonly query: {
        readonly $populate: any;
    };
}, TRoute extends keyof CurrentApp> = ServerFindPopType<TPop, TRoute, CurrentApp>;
export type FindPopRawType<TPop, TRoute extends keyof CurrentApp> = ServerFindPopRawType<TPop, TRoute, CurrentApp>

VueClassComponent.registerHooks(['loadData'])

export const Auth = createDecorator(function (componentOptions, k) {
    if (typeof componentOptions.mounted !== "function") {
        componentOptions.mounted = function () { };
    }
    var original = componentOptions.mounted;
    componentOptions.mounted = function (this : Vue) {
        if(!this.$store.getters['userId']) {
            this.$i18nReplace(`/login/?${qs.stringify({
                from: this.$route.fullPath
            })}`)
        }
        original.call(this);
    };
});

export const Nuxtend = createDecorator(function (componentOptions, k) {
    nuxtend(componentOptions);
});

export function Component(options) {
    if (typeof options === 'function') {
        Nuxtend(options);
        return NuxtComponent(options);
    }
    return function (Component) {
        Nuxtend(Component);
        return NuxtComponent(options)(Component);
    }
}

