var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quill-editor" },
    [_vm._t("toolbar"), _c("div", { ref: "editor" })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }