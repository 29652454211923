var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("renderer", {
    attrs: { render: _vm.renderContainer, useContext: true },
    scopedSlots: _vm._u(
      [
        {
          key: "container",
          fn: function ({ renderList, renderPaginate }) {
            return [
              !_vm.flat
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "action",
                            rawName: "v-action.add",
                            value: _vm.addActionClick,
                            expression: "addActionClick",
                            modifiers: { add: true },
                          },
                          {
                            name: "action",
                            rawName: "v-action.search",
                            value: _vm.searchActionClick,
                            expression: "searchActionClick",
                            modifiers: { search: true },
                          },
                          {
                            name: "action",
                            rawName: "v-action.export",
                            value: _vm.exportActionClick,
                            expression: "exportActionClick",
                            modifiers: { export: true },
                          },
                          {
                            name: "action",
                            rawName: "v-action.refresh",
                            value: _vm.refreshActionClick,
                            expression: "refreshActionClick",
                            modifiers: { refresh: true },
                          },
                        ],
                        class: { "fill-height": _vm.fillHeight },
                      },
                      [
                        _c(
                          "v-card",
                          { class: { "fill-height": _vm.fillHeight } },
                          [
                            _c(
                              "div",
                              {
                                class: { "fill-height": _vm.fillHeight },
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    !_vm.hideToolBar
                                      ? _c(
                                          "v-toolbar",
                                          { attrs: { dense: "" } },
                                          [
                                            _vm._t("pre-title"),
                                            _c("v-toolbar-title", {
                                              directives: [
                                                {
                                                  name: "td",
                                                  rawName: "v-td",
                                                  value: _vm.data.name,
                                                  expression: "data.name",
                                                },
                                              ],
                                            }),
                                            _c("v-spacer"),
                                            _vm._t("pre-actions"),
                                            !_vm.noExport
                                              ? _c(
                                                  "b-btn",
                                                  {
                                                    attrs: {
                                                      "alt-text":
                                                        _vm.$t("basic.export"),
                                                    },
                                                    on: {
                                                      click: _vm.beginExport,
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "fas fa-file-export"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.search
                                              ? _c(
                                                  "b-btn",
                                                  {
                                                    attrs: {
                                                      "alt-text":
                                                        _vm.$t("basic.search"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.beginSearch.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("search"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.data && !_vm.data.static
                                              ? _c(
                                                  "b-btn",
                                                  {
                                                    attrs: {
                                                      "alt-text":
                                                        _vm.$t("basic.refresh"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.reload.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("refresh"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.add
                                              ? _c(
                                                  "b-btn",
                                                  {
                                                    attrs: {
                                                      "alt-text":
                                                        _vm.$t("basic.add"),
                                                      to: _vm.add,
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm.actions && !_vm.noAdd
                                              ? _c(
                                                  "b-btn",
                                                  {
                                                    attrs: {
                                                      "alt-text":
                                                        _vm.$t("basic.add"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.editItem(
                                                          null
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._t("post-actions", null, {
                                              reload: _vm.reload,
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  {
                                    staticStyle: {
                                      "flex-grow": "1",
                                      flex: "1",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    _c("v-slide-y-transition", [
                                      _vm.searchFilter
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.searching &&
                                                    _vm.$vuetify.breakpoint
                                                      .mdAndUp,
                                                  expression:
                                                    "searching && $vuetify.breakpoint.mdAndUp",
                                                },
                                              ],
                                              staticClass: "fill-height mb-2",
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  class: {
                                                    "fill-height":
                                                      _vm.fillHeight,
                                                  },
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        "fill-height":
                                                          _vm.fillHeight,
                                                      },
                                                      staticStyle: {
                                                        position: "relative",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-toolbar",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-toolbar-title",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "t",
                                                                      rawName:
                                                                        "v-t",
                                                                      value:
                                                                        "datatable.search",
                                                                      expression:
                                                                        "'datatable.search'",
                                                                    },
                                                                  ],
                                                                }
                                                              ),
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.resetFilter,
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "clear"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.applyFilter,
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "filter_list"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticStyle: {
                                                            "flex-grow": "1",
                                                            flex: "1",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.fillHeight
                                                              ? "perfect-scrollbar"
                                                              : "div",
                                                            {
                                                              tag: "component",
                                                              staticClass:
                                                                "pb-4",
                                                              class: {
                                                                "scroll-area":
                                                                  _vm.fillHeight,
                                                              },
                                                              staticStyle: {
                                                                "overflow-x":
                                                                  "hidden",
                                                              },
                                                            },
                                                            [
                                                              _vm._t(
                                                                "search",
                                                                null,
                                                                {
                                                                  search:
                                                                    _vm.searchFilter,
                                                                  computed:
                                                                    _vm.getSearchComputed(),
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fill-height",
                                        staticStyle: {
                                          position: "relative",
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          _vm.fillHeight
                                            ? "perfect-scrollbar"
                                            : "div",
                                          {
                                            tag: "component",
                                            class: {
                                              "scroll-area": _vm.fillHeight,
                                            },
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c("renderer", {
                                              attrs: { render: renderList },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.paginate &&
                                (!_vm.autoHidePage ||
                                  (_vm.autoHidePage && _vm.pages > 1))
                                  ? _c(
                                      "v-card-actions",
                                      [
                                        _c("renderer", {
                                          attrs: { render: renderPaginate },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._t("extend"),
                              ],
                              2
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [_c("renderer", { attrs: { render: renderList } })],
            ]
          },
        },
        {
          key: "list",
          fn: function ({}) {
            return [
              _c(
                _vm.iterator ? "v-data-iterator" : "v-data-table",
                {
                  tag: "component",
                  attrs: {
                    options: _vm.pagination,
                    items: _vm.mitems,
                    headers: _vm.dispHeaders,
                    "show-select": _vm.mselect,
                    "no-data-text": _vm.$t("basic.no_data"),
                    "no-results-text": _vm.$t("basic.no_results"),
                    "loading-text": _vm.$t("basic.loading"),
                    "footer-props": {
                      "items-per-page-text": _vm.$t("basic.row_per_page"),
                    },
                    "item-key": _vm.mItemKey,
                    loading: _vm.loading,
                    "server-items-length":
                      _vm.items || _vm.localSearch
                        ? undefined
                        : _vm.pagination.total,
                    "hide-default-footer": "",
                    "multi-sort": "",
                    search: _vm.localSearch,
                    "custom-filter": _vm.localSearchFilter,
                    "custom-group": _vm.customGroup,
                    "disable-pagination": !_vm.paginate || _vm.paginate === -1,
                  },
                  on: {
                    "update:options": function ($event) {
                      _vm.pagination = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header.data-table-select",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mr-4" },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "input-value":
                                          _vm.selectState === "all",
                                        indeterminate:
                                          _vm.selectState !== "none" &&
                                          _vm.selectState !== "all",
                                        primary: "",
                                        "hide-details": "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleAll.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.mselected.length
                                  ? [
                                      !_vm.noRemove
                                        ? _c(
                                            "b-btn",
                                            {
                                              staticClass: "mx-1",
                                              attrs: {
                                                "alt-color": "pink",
                                                "alt-text":
                                                  _vm.$t("basic.delete"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.batchDelete.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "pink" } },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.noExport
                                        ? _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                "alt-text":
                                                  _vm.$t("basic.export"),
                                              },
                                              on: { click: _vm.beginExport },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-file-export"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._t("bulkActions", null, {
                                        items: _vm.mselected,
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function ({
                          item,
                          isExpanded,
                          isSelected,
                          expand,
                          select: selectItem,
                          isMobile,
                        }) {
                          return [
                            isMobile
                              ? [
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        { name: "press", rawName: "v-press" },
                                      ],
                                      staticClass:
                                        "v-data-table__mobile-table-row",
                                      on: {
                                        click: function ($event) {
                                          _vm.itemCanClick
                                            ? _vm.itemOnClick(item)
                                            : null
                                        },
                                        longpress: function ($event) {
                                          return selectItem(!isSelected)
                                        },
                                        shortpress: function ($event) {
                                          _vm.clickable
                                            ? _vm.$emit("pickItem", item)
                                            : _vm.select &&
                                              _vm.selectState !== "none"
                                            ? selectItem(!isSelected)
                                            : expand(!isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _vm.actions
                                        ? _c(
                                            "td",
                                            {
                                              staticClass:
                                                "v-data-table__mobile-row",
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _vm._t(
                                                "actions",
                                                function () {
                                                  return [
                                                    _vm.mselect
                                                      ? _c("v-checkbox", {
                                                          staticStyle: {
                                                            "max-width": "50px",
                                                          },
                                                          attrs: {
                                                            primary: "",
                                                            "hide-details": "",
                                                            height: 20,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return selectItem(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              void 0
                                                            },
                                                          },
                                                          model: {
                                                            value: isSelected,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              isSelected = $$v
                                                            },
                                                            expression:
                                                              "isSelected",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-content":
                                                            "center !important",
                                                          "align-items":
                                                            "center !important",
                                                        },
                                                      },
                                                      [
                                                        !_vm.noEdit
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "teal",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.edit"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.rootPath
                                                                          ? _vm.rootPath
                                                                          : _vm.$route.path.endsWith(
                                                                              "/"
                                                                            )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit/${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "teal",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noEdit &&
                                                        !_vm.noClone
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "purple",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.clone"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.$route.path.endsWith(
                                                                          "/"
                                                                        )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit?clone=${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item,
                                                                          true
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "purple",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "file_copy"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noRemove
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "pink",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.delete"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "pink",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._t(
                                                          "extraActions",
                                                          null,
                                                          {
                                                            item: item,
                                                            editItem:
                                                              _vm.editItem,
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                                {
                                                  item: item,
                                                  editItem: _vm.editItem,
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.headers,
                                        function (header, idx) {
                                          return _c(
                                            "td",
                                            {
                                              key: idx,
                                              staticClass:
                                                "v-data-table__mobile-row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-data-table__mobile-row__header",
                                                },
                                                [_vm._v(_vm._s(header.text))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-data-table__mobile-row__cell",
                                                },
                                                [
                                                  _c("renderer", {
                                                    attrs: {
                                                      render: _vm.renderItem,
                                                      item: item,
                                                      header: header,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("td"),
                                    ],
                                    2
                                  ),
                                ]
                              : [
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        { name: "press", rawName: "v-press" },
                                      ],
                                      style: _vm.itemCanClick
                                        ? "cursor:pointer"
                                        : "",
                                      on: {
                                        click: function ($event) {
                                          _vm.itemCanClick
                                            ? _vm.itemOnClick(item)
                                            : null
                                        },
                                        longpress: function ($event) {
                                          return selectItem(!isSelected)
                                        },
                                        shortpress: function ($event) {
                                          _vm.clickable
                                            ? _vm.$emit("pickItem", item)
                                            : _vm.select &&
                                              _vm.selectState !== "none"
                                            ? selectItem(!isSelected)
                                            : expand(!isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _vm.mselect
                                        ? _c(
                                            "td",
                                            [
                                              _c("v-checkbox", {
                                                staticStyle: {
                                                  "max-width": "50px",
                                                },
                                                attrs: {
                                                  primary: "",
                                                  "hide-details": "",
                                                  height: 20,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return selectItem($event)
                                                  },
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    void 0
                                                  },
                                                },
                                                model: {
                                                  value: isSelected,
                                                  callback: function ($$v) {
                                                    isSelected = $$v
                                                  },
                                                  expression: "isSelected",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.actions && !_vm.actionsRight
                                        ? _c(
                                            "td",
                                            {
                                              staticClass: "actions",
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _vm._t(
                                                "actions",
                                                function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-content":
                                                            "center !important",
                                                          "align-items":
                                                            "center !important",
                                                        },
                                                      },
                                                      [
                                                        !_vm.noEdit
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.checkWrite &&
                                                                    !_vm.checkWrite(
                                                                      item
                                                                    ),
                                                                  "alt-color":
                                                                    "teal",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.edit"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.rootPath
                                                                          ? _vm.rootPath
                                                                          : _vm.$route.path.endsWith(
                                                                              "/"
                                                                            )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit/${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "teal",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noEdit &&
                                                        !_vm.noClone
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "purple",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.clone"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.$route.path.endsWith(
                                                                          "/"
                                                                        )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit?clone=${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item,
                                                                          true
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "purple",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "file_copy"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noRemove
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.checkRemove &&
                                                                    !_vm.checkRemove(
                                                                      item
                                                                    ),
                                                                  "alt-color":
                                                                    "pink",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.delete"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "pink",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._t(
                                                          "extraActions",
                                                          null,
                                                          {
                                                            item: item,
                                                            editItem:
                                                              _vm.editItem,
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                                {
                                                  item: item,
                                                  editItem: _vm.editItem,
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.headers,
                                        function (header, idx) {
                                          return _c(
                                            "td",
                                            { key: idx },
                                            [
                                              _c("renderer", {
                                                attrs: {
                                                  render: _vm.renderItem,
                                                  item: item,
                                                  header: header,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _vm.actions && _vm.actionsRight
                                        ? _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _vm._t(
                                                "actions",
                                                function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-content":
                                                            "center !important",
                                                          "align-items":
                                                            "center !important",
                                                        },
                                                      },
                                                      [
                                                        !_vm.noEdit
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "teal",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.edit"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.rootPath
                                                                          ? _vm.rootPath
                                                                          : _vm.$route.path.endsWith(
                                                                              "/"
                                                                            )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit/${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "teal",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noEdit &&
                                                        !_vm.noClone
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "purple",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.clone"
                                                                    ),
                                                                  to:
                                                                    (_vm.disableInlineEdit &&
                                                                      `${
                                                                        _vm.$route.path.endsWith(
                                                                          "/"
                                                                        )
                                                                          ? _vm
                                                                              .$route
                                                                              .path
                                                                          : _vm
                                                                              .$route
                                                                              .path +
                                                                            "/"
                                                                      }edit?clone=${
                                                                        item._id
                                                                      }`) ||
                                                                    undefined,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      !_vm.disableInlineEdit &&
                                                                        _vm.editItem(
                                                                          item,
                                                                          true
                                                                        )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "purple",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "file_copy"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.noRemove
                                                          ? _c(
                                                              "b-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  "alt-color":
                                                                    "pink",
                                                                  "alt-text":
                                                                    _vm.$t(
                                                                      "basic.delete"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.deleteItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "pink",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._t(
                                                          "extraActions",
                                                          null,
                                                          {
                                                            item: item,
                                                            editItem:
                                                              _vm.editItem,
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                                {
                                                  item: item,
                                                  editItem: _vm.editItem,
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                          ]
                        },
                      },
                      {
                        key: "expanded-item",
                        fn: function ({ item, headers }) {
                          return [
                            _c(
                              "td",
                              {
                                staticStyle: { height: "auto" },
                                attrs: { colspan: headers.length },
                              },
                              [
                                _vm._t("expand", null, {
                                  item: item,
                                  computed: _vm.getComputed(item),
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function ({ items }) {
                          return [
                            _vm._t(
                              "itemContainer",
                              function () {
                                return [
                                  _c(
                                    _vm.contentTag || "div",
                                    _vm._b(
                                      {
                                        tag: "component",
                                        class: _vm.contentClass,
                                      },
                                      "component",
                                      _vm.contentProps,
                                      false
                                    ),
                                    [
                                      _vm._l(items, function (item, idx) {
                                        return [
                                          _vm._t("item", null, {
                                            idx: idx,
                                            item: item,
                                            headers: _vm.headers,
                                            get: _vm.get,
                                            deleteItem: _vm.deleteItem,
                                            editItem: _vm.editItem,
                                          }),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              { items: items }
                            ),
                          ]
                        },
                      },
                      {
                        key: "body.append",
                        fn: function ({ items, headers }) {
                          return [
                            _vm._t("bodyAppend", null, {
                              items: items,
                              headers: headers,
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.mselected,
                    callback: function ($$v) {
                      _vm.mselected = $$v
                    },
                    expression: "mselected",
                  },
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pa-10 text-center grey--text text--darken-4 subtitle-1",
                      },
                      [_vm._v(_vm._s(_vm.$t("basic.no_data")))]
                    ),
                  ]),
                ],
                2
              ),
            ]
          },
        },
        {
          key: "paginate",
          fn: function ({}) {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center !important",
                    "align-items": "center !important",
                    width: "100%",
                  },
                },
                [
                  _vm.cursor
                    ? [
                        _c(
                          "v-flex",
                          { staticStyle: { "flex-grow": "1" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: "",
                                  disabled:
                                    !_vm.source ||
                                    !_vm.source.cursors ||
                                    !_vm.source.cursors.previous,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCursor(
                                      _vm.source.cursors.previous
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: "",
                                  disabled:
                                    !_vm.source ||
                                    !_vm.source.cursors ||
                                    !_vm.source.cursors.next,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCursor(
                                      _vm.source.cursors.next
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("keyboard_arrow_right")])],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.hideRowsPerPage
                          ? _c("v-combobox", {
                              staticStyle: { "max-width": "100px" },
                              attrs: {
                                label: _vm.$t("basic.row_per_page"),
                                "hide-details": "",
                                items: _vm.rowsPerPageItems,
                              },
                              model: {
                                value: _vm.rowsPerPage,
                                callback: function ($$v) {
                                  _vm.rowsPerPage = _vm._n($$v)
                                },
                                expression: "rowsPerPage",
                              },
                            })
                          : _vm._e(),
                      ]
                    : [
                        _c(
                          "v-flex",
                          { staticStyle: { "flex-grow": "1" } },
                          [
                            _c("v-pagination", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                circle: "",
                                "total-visible": 10,
                                length: _vm.pages,
                              },
                              model: {
                                value: _vm.page,
                                callback: function ($$v) {
                                  _vm.page = $$v
                                },
                                expression: "page",
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.hideRowsPerPage
                          ? _c(
                              "div",
                              { staticStyle: { width: "120px" } },
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    label: _vm.$t("basic.row_per_page"),
                                    "hide-details": "",
                                    items: _vm.rowsPerPageItems,
                                  },
                                  model: {
                                    value: _vm.rowsPerPage,
                                    callback: function ($$v) {
                                      _vm.rowsPerPage = _vm._n($$v)
                                    },
                                    expression: "rowsPerPage",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.hideTotal
                          ? _c(
                              "span",
                              {
                                staticClass: "px-2",
                                staticStyle: {
                                  "min-width": "80px",
                                  "text-align": "right",
                                },
                              },
                              [_vm._v(_vm._s(_vm.pageText))]
                            )
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }