import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64d60420 = () => interopDefault(import('../pages/broadcast.vue' /* webpackChunkName: "pages/broadcast" */))
const _465a437a = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _7f110f28 = () => interopDefault(import('../pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _8ab61dfe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3eefa89b = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5256f76b = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _54fd0dca = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _57cce0b3 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _2adefc88 = () => interopDefault(import('../pages/versions.vue' /* webpackChunkName: "pages/versions" */))
const _89fcb01c = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _232088bc = () => interopDefault(import('../pages/profile/changeProfile.vue' /* webpackChunkName: "pages/profile/changeProfile" */))
const _208e1d8e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/broadcast",
    component: _64d60420,
    name: "broadcast___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/broadcast.vue').default
  }, {
    path: "/dashboard",
    component: _465a437a,
    name: "dashboard___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/delete-account",
    component: _7f110f28,
    name: "delete-account___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/delete-account.vue').default
  }, {
    path: "/en",
    component: _8ab61dfe,
    name: "index___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/login",
    component: _3eefa89b,
    name: "login___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/logout",
    component: _5256f76b,
    name: "logout___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/profile",
    component: _54fd0dca,
    name: "profile___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/test",
    component: _57cce0b3,
    name: "test___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test.vue').default
  }, {
    path: "/versions",
    component: _2adefc88,
    name: "versions___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/versions.vue').default
  }, {
    path: "/zh-cn",
    component: _8ab61dfe,
    name: "index___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/en/broadcast",
    component: _64d60420,
    name: "broadcast___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/broadcast.vue').default
  }, {
    path: "/en/dashboard",
    component: _465a437a,
    name: "dashboard___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/en/delete-account",
    component: _7f110f28,
    name: "delete-account___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/delete-account.vue').default
  }, {
    path: "/en/login",
    component: _3eefa89b,
    name: "login___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/en/logout",
    component: _5256f76b,
    name: "logout___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/en/profile",
    component: _54fd0dca,
    name: "profile___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/en/test",
    component: _57cce0b3,
    name: "test___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test.vue').default
  }, {
    path: "/en/versions",
    component: _2adefc88,
    name: "versions___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/versions.vue').default
  }, {
    path: "/profile/changePassword",
    component: _89fcb01c,
    name: "profile-changePassword___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/profile/changeProfile",
    component: _232088bc,
    name: "profile-changeProfile___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/zh-cn/broadcast",
    component: _64d60420,
    name: "broadcast___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/broadcast.vue').default
  }, {
    path: "/zh-cn/dashboard",
    component: _465a437a,
    name: "dashboard___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/zh-cn/delete-account",
    component: _7f110f28,
    name: "delete-account___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/delete-account.vue').default
  }, {
    path: "/zh-cn/login",
    component: _3eefa89b,
    name: "login___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/zh-cn/logout",
    component: _5256f76b,
    name: "logout___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/zh-cn/profile",
    component: _54fd0dca,
    name: "profile___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/zh-cn/test",
    component: _57cce0b3,
    name: "test___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/test.vue').default
  }, {
    path: "/zh-cn/versions",
    component: _2adefc88,
    name: "versions___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/versions.vue').default
  }, {
    path: "/en/profile/changePassword",
    component: _89fcb01c,
    name: "profile-changePassword___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/en/profile/changeProfile",
    component: _232088bc,
    name: "profile-changeProfile___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/zh-cn/profile/changePassword",
    component: _89fcb01c,
    name: "profile-changePassword___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/zh-cn/profile/changeProfile",
    component: _232088bc,
    name: "profile-changeProfile___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/en/*",
    component: _208e1d8e,
    name: "all___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }, {
    path: "/zh-cn/*",
    component: _208e1d8e,
    name: "all___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }, {
    path: "/",
    component: _8ab61dfe,
    name: "index___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/*",
    component: _208e1d8e,
    name: "all___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }],

  parseQuery: function(q) {
      return require("qs").parse(q);
    },
  stringifyQuery: function(q) {
      var r = require("qs").stringify(q);
      return r ? "?" + r : "";
    },
  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  router.routes = routerOptions.routes;

  return router
}
