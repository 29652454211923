var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "event",
          rawName: "v-event.toggleDrawer",
          value: _vm.toggleDrawer,
          expression: "toggleDrawer",
          modifiers: { toggleDrawer: true },
        },
      ],
      staticClass: "nav-root",
      class: {
        mini: _vm.miniSync,
        "mini-real": _vm.miniReal,
        temporary: _vm.realTemp,
        activeDrawer: _vm.inputValue,
      },
      style: _vm.widthStyle,
    },
    [
      _c("div", {
        staticClass: "overlay",
        on: {
          click: _vm.toggleDrawer,
          touchmove: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
          },
        },
      }),
      _c(
        "nav",
        {
          staticClass: "inset-y-0 fixed navbar",
          on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave },
        },
        [
          _c("div", {
            staticClass:
              "col-start-1 row-start-1 row-span-3 nav-anim bg-nav border-r-grey100 border-r border-solid",
          }),
          _c(
            "div",
            {
              staticClass: "col-start-1 row-start-1 app-logo w-$nav-cur-width",
            },
            [
              _c("img", {
                attrs: { src: require("assets/images/logo.png"), width: "40" },
              }),
              _c("div", {
                staticClass: "nav-close",
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleDrawer()
                  },
                },
              }),
              _c("div", {
                staticClass: "nav-open",
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleDrawer()
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-start-1 row-start-2 overflow-hidden mt-6" },
            [
              _c("div", { staticClass: "w-$nav-cur-width h-full" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "scrollable overflow-y-auto overflow-x-hidden h-full px-3 w-$nav-full-width",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-col" },
                      [
                        _vm._l(_vm.items, function (item, n) {
                          return [
                            _c("boxs-nav-bar-item", { attrs: { item: item } }),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-start-1 row-start-3 py-3" },
            [_vm._t("bottom")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }