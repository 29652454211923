
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import VirtualList from './vue-virtual-scroll-list'
import _ from 'lodash'
import VirtualDataListRow from "./VirtualDataListRow.vue"
import VirtualDataListSlot from "./VirtualDataListSlot"
// @ts-ignore
import { compareByMongoSort, sortedIndexBy, runQuery } from '@feathers-client'

@Component({
    components: {
        VirtualList
    }
})
export default class VirtualDataTable extends Vue {
    @Prop({ type: String, default: '_id' })
    itemKey: string

    @Prop()
    component: any

    @Prop()
    extraProps: any
    
    @Prop({ type: Number, default: 80 })
    estimateSize: number

    @Prop({ type: Number })
    keeps: number

    @Prop({ type: Number })
    pageSize: number

    @Prop({ type: Number, default: 100 })
    maxPageSize: number

    @Prop()
    path: string

    @Prop()
    query: any

    @Prop()
    sort: any

    @Prop({ type: Number, default: 1})
    columns: number

    @Prop({ default: 'color: white' })
    loaderStyle: any

    @Prop({ default: () => ({width: '100%', justifyContent: 'center'})})
    footerStyle: any

    @Prop({ type: Boolean, default: true })
    loader: boolean

    @Prop()
    wrapTag : any

    @Prop()
    wrapClass : any

    @Prop()
    itemTag : any

    @Prop()
    itemClass : any

    @Prop({ type: Number, default: 10 })
    reloadThrottle: number

    @Prop({ type: Number, default: 10 })
    reloadThrottleWhenLoading: number

    @Prop(Boolean)
    resortWhenUpdate: boolean

    @Prop()
    staticItems: any[]

    get itemStoreKey() {
        return this.columns > 1 ? '_id' : this.itemKey;
    }

    get itemStore() {
        return this.staticItems ? this.staticItems : this.tempView ? this.tempView : this.columns > 1 ? this.gridItems : this.items;
    }

    get itemComponent() {
        return this.columns > 1 ? VirtualDataListRow : this.component || VirtualDataListSlot;
    }

    get itemProps() {
        return this.columns > 1 ? {
            columns: this.columns,
            component: this.component,
            itemKey: this.itemKey,
            remove: this.remove,
            update: this.update,
        } : {
            remove: this.remove,
            update: this.update,
            ...(this.extraProps || {}),
        }
    }

    items : any[] = [];
    gridItems: {_id: number, items: any[]}[] = [];
    loaded = false;
    loadSession = 0;
    loading = false;
    initLoad = true;
    tempView : any[] = null;

    mounted() {
        this.loadNext();
    }

    _loadNextTask: Promise<void>
    loadNext() {
        if(!this.path) return;
        if(this.loaded) return;
        if(this._loadNextTask) return this._loadNextTask;
        const task = this.loadNextInner();
        this._loadNextTask = task;
        task.finally(() => {
            if(this._loadNextTask === task) {
                this._loadNextTask = null;
            }
        })
        return task;
    }

    appendData(newItems: any[]) {
        this.items.push(...newItems);

        const c = this.columns;
        if(c > 1) {
            const g = this.gridItems;
            for(let item of newItems) {
                let last = g[g.length - 1];
                if(!last || last.items.length >= c) {
                    g.push(last = {
                        _id: (last?._id ?? 0) + (last?.items?.length ?? 0),
                        items: []
                    })
                }
                last.items.push(item);
            }
        }
        this.tempView = null;
    }

    async loadNextInner() {
        const session = ++this.loadSession;
        this.loading = true;
        try {
            const sort = {
                ...(this.query?.$sort || this.sort || {})
            }
            if(!sort._id) sort._id = 1;

            const lastItem = this.items[this.items.length - 1];
            const $and = (this.query?.$and ?? []).slice();
            if(lastItem) {
                const sortEntries = Object.entries(sort);
                $and.push({
                    $or: sortEntries.map((it, idx) => {
                        const entries = sortEntries.slice(0, idx + 1);

                        return Object.fromEntries(entries.map(([k, v], idx) => {
                            const lastVal = _.get(lastItem, k);
                            if(!lastVal) {
                                return null;
                            }
                            return [k, idx === entries.length - 1 ? { [v < 0 ? '$lt' : '$gt']: lastVal } : lastVal];
                        }).filter(it => !!it));
                    })
                })
            }

            let defaultPageSize = this.pageSize ?? 10;
            const el = this.$el as HTMLElement;
            if(el && el.clientHeight) {
                defaultPageSize = Math.max(defaultPageSize, (Math.ceil((Math.ceil(el.clientHeight / this.estimateSize) + 3) * this.columns)));
            }

            const query = {
                ...(this.query || {}),
                $sort: sort,
                $limit: Math.min(this.maxPageSize, defaultPageSize),
                ...($and.length ? { $and } : {}),
            }
            const result : any = await this.$feathers.service(this.path).find({
                query,
            });
            if(this.loadSession !== session) return;
            const total = !Array.isArray(result) && Array.isArray(result.data) && result.total;
            const resultData = Array.isArray(result) ? result : result.data;
            if(!resultData?.length) {
                this.loaded = true;
            } else {
                this.appendData(resultData);
                if(typeof total === 'number' && resultData.length >= total) {
                    // reached end
                    this.loaded = true;
                }
            }
            if(this.initLoad && !this.loaded) {
                setTimeout(this.checkScroll, 50);
            }
        } catch(e) {
            if(this.loadSession !== session) return;
            console.warn(e);
            this.loaded = true;
        } finally {
            if(this.loadSession !== session) return;
            this.loading = false;
        }
    }

    checkScroll() {
        const el = this.$el as HTMLElement;
        if(!el) return;
        if(el.scrollHeight <= el.clientHeight) {
            // Need to load more to fill space
            setTimeout(this.loadNextInner, 500);
        } else {
            this.initLoad = false;
        }
    }

    reloadTimer : any;
    scheduleReload(flags? : boolean) {
        if(this.reloadTimer) {
            clearTimeout(this.reloadTimer);
        }
        this.reloadTimer = setTimeout(() => {
            this.reload();
            this.reloadTimer = null;
        }, this.loading ? this.reloadThrottleWhenLoading : this.reloadThrottle);
    }

    @Watch('sort')
    @Watch('query')
    onSourceChanged() {
        this.scheduleReload();
    }

    @Watch('path')
    onPathChanged(v, ov) {
        if(v === ov) return;
        this.clear();
        this.scheduleReload();
    }

    @Watch('columns')
    onColumnsChanged() {
        this.gridItems = this.columns > 1 ? _.chunk(this.items, this.columns).map((it, idx) => ({
            _id: idx * this.columns,
            items: it
        })) : [];
    }

    clear() {
        this.loadSession++;
        this.loading = false;
        this.loaded = false;
        this._loadNextTask = null;
        this.initLoad = true;
        this.items = [];
        this.gridItems = [];
    }

    reload() {
        this.tempView = this.itemStore;
        setTimeout(() => this.tempView = null, 250);
        this.clear();
        this.loadNext();
    }

    update(item: any) {
        let idx = this.items.indexOf(item);
        if(idx === -1) {
            idx = this.items.findIndex(it => it[this.itemKey || '_id'] === item[this.itemKey || '_id']);
        }
        if(idx === -1) {
            this.insert(item);
        } else {
            const cur = this.items[idx];
            if(this.resortWhenUpdate) {
                this.items.splice(idx, 1);
            }
            if(item !== cur) {
                Object.assign(cur, item);
            }
            if(this.resortWhenUpdate) {
                this.insert(cur);
            }
        }
    }

    insert(item : any) {
        if(!runQuery(item, this.query)) {
            return;
        }
        const idx = sortedIndexBy(this.items, item, compareByMongoSort(this.query?.$sort ?? this.sort ?? { _id: 1 }));
        this.items.splice(idx, 0, item);
    }

    async insertMaybePopulate(item: any) {
        if(!item || !item._id) return;
        if(this.query?.$populate) {
            const result = await this.$feathers.service(this.path).find({
                query: {
                    ...this.query,
                    _id: item._id,
                    $limit: 1,
                }
            });
            const resultData = Array.isArray(result) ? result : result.data;
            item = resultData[0];
        }
        if(!item) return;
        this.insert(item);
    }

    remove(item: any) {
        const idx = this.items.indexOf(item);
        if(idx !== -1) {
            this.items.splice(idx, 1);
        }
    }

}

