var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        fab: "",
        color: "info",
        fixed: "",
        right: "",
        bottom: "",
        id: "backToTopBtn",
      },
      on: {
        click: function ($event) {
          return _vm.$vuetify.goTo(_vm.target, _vm.options)
        },
      },
    },
    [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }