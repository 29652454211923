
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType } from "@feathers-client";
import { escapeRegExp } from "@feathers-client/util";
import type { SearchConfig } from './MenuListPicker.vue'

@Component
export default class Highlighter extends Vue {

    @Prop()
    keyword: string

    @Prop()
    value: string

    @Prop()
    fields: (string | SearchConfig)[]

    @Prop()
    item: any

    getField(field: string | SearchConfig) {
        if(typeof field === 'string') {
            return `${this.item?.[field] ?? ''}`;
        } else if(field) {
            const v = this.item?.[field.field];
            if(v && field.translate && Array.isArray(v)) {
                if(this.keyword) {
                    let keyword = `${this.keyword ?? ''}`.trim().toLowerCase();
                    return `${v.find(it => `${(it.value || '')}`.trim().toLowerCase().indexOf(keyword) !== -1)?.value}`;
                } else {
                    return `${this.$td(v)}`
                }
            }

            return `${v ?? ''}`
        }
        return '';
    }

    get parts() {
        let values : string[];

        if(this.value !== undefined) {
            const v = `${this.value ?? ''}`;
            if(!v) return [];
            values = [v];
        } else {
            if(!this.item) return [];
            values = Array.isArray(this.fields) ? this.fields.map(this.getField) : [this.getField(this.fields)];
        }

        let keyword = `${this.keyword ?? ''}`.trim().toLowerCase();

        if(keyword) {
            const matched = values.find(it => it.toLowerCase().indexOf(keyword) !== -1);
            if(matched) {
                const e = escapeRegExp(keyword);
                return Array.from(matched.matchAll(new RegExp(`((?:(?!${e}).)*)(${e})?`, 'gi'))).flatMap(it => it.slice(1))
            }
        }
        return [values[0]];
    }


}

