var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true },
        },
        {
          name: "event",
          rawName: "v-event.openDialog",
          value: _vm.openDialog,
          expression: "openDialog",
          modifiers: { openDialog: true },
        },
        {
          name: "event",
          rawName: "v-event.modalResult",
          value: _vm.modalResult,
          expression: "modalResult",
          modifiers: { modalResult: true },
        },
      ],
      ref: "app",
      class: { "full-page-app": _vm.fullPage },
      staticStyle: { width: "100%" },
      attrs: { light: !_vm.dark, dark: _vm.dark },
      nativeOn: {
        touchstart: function ($event) {
          return _vm.startMove($event)
        },
        touchmove: function ($event) {
          return _vm.canMove($event)
        },
        touchend: function ($event) {
          return _vm.endMove($event)
        },
      },
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.error,
            callback: function ($$v) {
              _vm.error = $$v
            },
            expression: "error",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'",
              },
            ],
            attrs: { text: "", color: "pink" },
            nativeOn: {
              click: function ($event) {
                _vm.error = false
              },
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.dialogs, function (dialog) {
        return _c(
          "v-dialog",
          _vm._b(
            {
              key: dialog.key,
              model: {
                value: dialog.show,
                callback: function ($$v) {
                  _vm.$set(dialog, "show", $$v)
                },
                expression: "dialog.show",
              },
            },
            "v-dialog",
            dialog.modal,
            false
          ),
          [
            dialog.loading
              ? [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "blue" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    dialog.component,
                    _vm._b(
                      { tag: "component", attrs: { modalId: dialog.key } },
                      "component",
                      dialog.props,
                      false
                    )
                  ),
                ],
          ],
          2
        )
      }),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { padding: "0px !important" },
          attrs: {
            dark: "",
            persistent: "",
            height: "100%",
            width: 300,
            "mini-variant": _vm.mini,
            "enable-resize-watcher": "",
            app: "",
            color: "primary",
          },
          on: {
            "update:miniVariant": function ($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function ($event) {
              _vm.mini = $event
            },
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "" } },
            [
              _c("div", { class: ["app-logo", { mini: _vm.mini }] }, [
                _c("img", {
                  attrs: { src: require("assets/images/icon.png") },
                }),
                !_vm.mini
                  ? _c("span", { staticClass: "app-name primary--text" }, [
                      _vm._v("二四轆"),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "scroll-area",
                  attrs: { settings: { suppressScrollX: true } },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "", rounded: "" } },
                    [
                      _vm._l(_vm.items, function (item, n) {
                        return [
                          item.items
                            ? _c(
                                "v-list-group",
                                {
                                  key: item.title,
                                  attrs: {
                                    "prepend-icon": item.action,
                                    group: item.group,
                                    color: "white",
                                    "append-icon": _vm.mini ? "" : undefined,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c("v-list-item-title", {
                                              directives: [
                                                {
                                                  name: "t",
                                                  rawName: "v-t",
                                                  value: item.title,
                                                  expression: "item.title",
                                                },
                                              ],
                                              staticClass: "white--text",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._l(item.items, function (subItem) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          key: subItem.title,
                                          staticClass: "white--text",
                                          attrs: {
                                            to: !subItem.target
                                              ? subItem.href
                                              : undefined,
                                            href:
                                              subItem.target && subItem.href,
                                            exact: subItem.exact,
                                            nuxt: !subItem.target,
                                            disabled:
                                              item.disabled || subItem.disabled,
                                            target: subItem.target,
                                          },
                                        },
                                        [
                                          _c("v-list-item-content", {
                                            directives: [
                                              {
                                                name: "t",
                                                rawName: "v-t",
                                                value: subItem.title,
                                                expression: "subItem.title",
                                              },
                                            ],
                                          }),
                                          subItem.action
                                            ? _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subItem.action)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : item.header
                            ? _c("v-subheader", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: item.header,
                                    expression: "item.header",
                                  },
                                ],
                                key: n,
                              })
                            : item.divider
                            ? _c("v-divider", { key: n, attrs: { light: "" } })
                            : [
                                _c(
                                  "v-list-item",
                                  {
                                    key: item.title,
                                    attrs: {
                                      disabled: item.disabled,
                                      to: item.href ? item.href : undefined,
                                      exact: item.exact,
                                      nuxt: "",
                                      ripple: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(item.action)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value: item.title,
                                              expression: "item.title",
                                            },
                                          ],
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "justify-center px-2 pt-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { small: "", icon: "" } }, on),
                                [_c("v-icon", [_vm._v("language")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.lang,
                        callback: function ($$v) {
                          _vm.lang = $$v
                        },
                        expression: "lang",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.$store.state.locales, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.id,
                              attrs: {
                                value: _vm.$store.state.locale === item.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$store.dispatch("changeLang", {
                                    id: item.id,
                                    $i18n: _vm.$i18n,
                                  })
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$store.state.fullscreen
        ? _c(
            "v-app-bar",
            {
              staticClass: "top-bar",
              attrs: { height: _vm.dark ? undefined : 100, fixed: "", app: "" },
            },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleDrawer()
                  },
                },
              }),
              _c("v-toolbar-title", {
                directives: [
                  {
                    name: "td",
                    rawName: "v-td",
                    value: {
                      path: _vm.$store.state.title,
                      args: _vm.$store.getters.margs,
                    },
                    expression:
                      "{ path: $store.state.title, args: $store.getters.margs }",
                  },
                ],
              }),
              _c("v-spacer"),
              _vm._l(_vm.$store.state.actions, function (action) {
                return _c(
                  "v-fade-transition",
                  { key: action.action },
                  [
                    !action.hide
                      ? _c(
                          "b-btn",
                          {
                            attrs: {
                              "alt-icon": action.badge,
                              "alt-text": action.altText
                                ? _vm.$td(action.altText)
                                : undefined,
                              loading:
                                action.action === _vm.$store.state.processing,
                              to: action.to ? action.to : undefined,
                              disabled: !!_vm.$store.state.processing,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                action.action &&
                                  _vm.$root.$emit(action.action, action)
                              },
                            },
                          },
                          [
                            action.badge
                              ? [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        color: action.badgeColor,
                                        left: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { slot: "badge" },
                                          slot: "badge",
                                        },
                                        [_vm._v(_vm._s(action.badge))]
                                      ),
                                      _c("v-icon", [
                                        _vm._v(_vm._s(action.icon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              : [_c("v-icon", [_vm._v(_vm._s(action.icon))])],
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-content",
        { class: { "fill-height": _vm.fullPage } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0",
              staticStyle: { height: "100%" },
              attrs: { fluid: "" },
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("nuxt")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: "",
                  },
                  on: { click: _vm.exitFullscreen },
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }