var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "aspect-square rounded justify-center inline-flex",
      class: [
        _vm.checkerClass,
        { changed: _vm.changed, checked: _vm.value, unchecked: !_vm.value },
      ],
    },
    [
      _c(
        "svg",
        {
          staticClass: "w-full h-full",
          attrs: {
            viewBox: "0 0 16 16",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            staticClass: "check-path-1",
            attrs: {
              d: "M3 9L6 12",
              stroke: _vm.color,
              "stroke-width": "2",
              "stroke-linecap": "round",
            },
          }),
          _c("path", {
            staticClass: "check-path-2",
            attrs: {
              d: "M6 12L13 4",
              stroke: _vm.color,
              "stroke-width": "2",
              "stroke-linecap": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }