var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-file-input", {
        attrs: {
          multiple: "",
          label: _vm.$t("archive"),
          accept: "application/zip",
        },
        model: {
          value: _vm.fileList,
          callback: function ($$v) {
            _vm.fileList = $$v
          },
          expression: "fileList",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }