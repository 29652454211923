import { render, staticRenderFns } from "./Uploader.vue?vue&type=template&id=63cbffc9&"
import script from "./Uploader.vue?vue&type=script&lang=js&"
export * from "./Uploader.vue?vue&type=script&lang=js&"
import style0 from "~/node_modules/dropzone/dist/dropzone.css?vue&type=style&index=0&lang=css&"
import style1 from "./Uploader.vue?vue&type=style&index=1&id=63cbffc9&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/domorelabs/Documents/domorelabs/wheels/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63cbffc9')) {
      api.createRecord('63cbffc9', component.options)
    } else {
      api.reload('63cbffc9', component.options)
    }
    module.hot.accept("./Uploader.vue?vue&type=template&id=63cbffc9&", function () {
      api.rerender('63cbffc9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Uploader.vue"
export default component.exports