
import { Component, Prop, Watch, Vue } from '@feathers-client'
import uuid from 'uuid/v4'


@Component
export default class Default extends Vue {

    @Prop(Boolean)
    active: boolean;

    @Prop({ type: Number, default: 30000 })
    durationToIdle: number;

    @Prop({ type: Number, default: 60000 })
    durationToClear: number;

    @Prop(String)
    overlayColor: string | undefined;

    @Prop([String, Number])
    overlayOpacity: string | number | undefined;

    @Prop([String, Number])
    width: string | number | undefined;

    @Prop([String, Number])
    maxWidth: string | number | undefined;

    lastAction = 0;
    isIdle = false;
    actionTimer: any;
    timeLeftToClear = 0;

    continueAction() {
        this.isIdle = false;
        this.lastAction = Date.now();
    }
    clearAction() {
        this.isIdle = false;
        this.lastAction = Date.now();
        this.$emit('reset');
    }

    async mounted() {
        document.addEventListener('gesturestart', this.gesture);
        document.addEventListener('mousedown', this.move, true);
        document.addEventListener('mousemove', this.move, true);
        document.addEventListener('touchstart', this.move, true);
        document.addEventListener('touchmove', this.move, true);

        this.lastAction = Date.now();
        this.actionTimer = setInterval(this.tick, 1000);
        this.timeLeftToClear = this.durationToClear;
    }

    beforeDestroy() {
        document.removeEventListener('gesturestart', this.gesture);
        document.removeEventListener('mousedown', this.move, true);
        document.removeEventListener('mousemove', this.move, true);
        document.removeEventListener('touchstart', this.move, true);
        document.removeEventListener('touchmove', this.move, true);
        if(this.actionTimer) clearInterval(this.actionTimer);
    }

    move(e) {
        if(!this.$store.state.pos) return;
        if(this.isIdle) return;
        this.lastAction = Date.now();
    }
    gesture(e) {
        e.preventDefault();
    }

    tick() {
        if(this.active) {
            this.timeLeftToClear = this.durationToClear - (Date.now() - this.lastAction);
            if(this.isIdle) {
                if(this.timeLeftToClear <= 0) {
                    this.clearAction();
                }
            } else if(Date.now() - this.lastAction > this.durationToIdle) {
                this.isIdle = true;
            }
        }
    }

    @Watch('active')
    onActiveChanged() {
        this.timeLeftToClear = this.durationToClear;
    }

    @Watch('lastAction')
    onLastActionChanged() {
        this.timeLeftToClear = this.durationToClear - (Date.now() - this.lastAction);
    }
}

