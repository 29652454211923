var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.contents
    ? _c(
        "div",
        { staticClass: "contents" },
        [
          _vm._t("item", null, {
            item: _vm.inputValue,
            edit: {
              get item() {
                return _vm.inputValue
              },
              set item(v) {
                _vm.inputValue = v
              },
            },
          }),
        ],
        2
      )
    : _vm.collapse
    ? _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", [_vm._v(_vm._s(_vm.label))]),
              ]),
              _c("v-expansion-panel-content", [
                _c(
                  "div",
                  { staticClass: "ma-2 d-flex flex-col gap-y-2" },
                  [
                    _vm._t("item", null, {
                      item: _vm.inputValue,
                      edit: {
                        get item() {
                          return _vm.inputValue
                        },
                        set item(v) {
                          _vm.inputValue = v
                        },
                      },
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", { staticClass: "editor-nested p-4" }, [
        _c(
          "div",
          { staticClass: "text-sm text-grey500 d-flex items-center gap-x-1.5" },
          [_c("span", [_vm._v(_vm._s(_vm.label))]), _vm._t("nameAppend")],
          2
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-col gap-y-6" },
          [
            _vm._t("item", null, {
              item: _vm.inputValue,
              edit: {
                get item() {
                  return _vm.inputValue
                },
                set item(v) {
                  _vm.inputValue = v
                },
              },
            }),
          ],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }