export default {
    icons: {
        iconfont: 'md',
    },
    theme:{
        themes:{
            light:{
                primary: '#5F9670'
            }
        }
    }
}
