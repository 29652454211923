var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "editor", staticStyle: { "min-height": "400px" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }