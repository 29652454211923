
export default {
  props: {
    header: {},
    item: {},
    context: {},
  },
  render(_c) {
    const cell = this.context.renderItem(_c, { item: this.item, header: this.header });
    if(!cell || Array.isArray(cell) && !cell?.length || cell?.text === '') {
      return this._v('-')
    }
    return cell;
  }
}

