var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition-group",
    {
      staticClass: "relative w-full transition-group transform translate-z-0",
      class: {
        reversed: _vm.reversed,
        "h-full overflow-hidden": !_vm.pageMode,
      },
      attrs: { tag: "div", name: _vm.transition },
      on: { "after-enter": _vm.resetDirection },
    },
    [
      _vm._t("root", function () {
        return _vm._l(_vm.pages, function (page, idx) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.reversed
                    ? _vm.pages.length - 2 === idx
                    : _vm.pages.length - 1 === idx,
                  expression:
                    "reversed ? pages.length - 2 === idx : pages.length - 1 === idx",
                },
              ],
              key: page[0] + idx,
              class: _vm.contentClass,
            },
            [
              _vm._t(
                page[0] || _vm.rootPage,
                null,
                { navigate: _vm.navigate },
                page[1]
              ),
            ],
            2
          )
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }