var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "80%", "content-class": "mediaLibrary" },
      model: {
        value: _vm.inputValue,
        callback: function ($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue",
      },
    },
    [
      _c(
        "v-card",
        {
          class: { dragging: _vm.dragging },
          attrs: { height: "100%" },
          on: {
            dragenter: _vm.dragenter,
            dragover: _vm.dragover,
            dragleave: _vm.dragleave,
            drop: _vm.drop,
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "" } },
            [
              _c(
                "v-card-text",
                { staticStyle: { height: "0", "flex-grow": "1" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "mr-1 attachList",
                          staticStyle: { height: "100%" },
                          attrs: { xs12: "", md6: "" },
                        },
                        [
                          _c(
                            "data-table",
                            {
                              ref: "table",
                              attrs: {
                                data: {
                                  path: "/attachments",
                                  filter: {
                                    $sort: { date: -1 },
                                    ..._vm.mimeFilter,
                                  },
                                },
                                iterator: "",
                                "content-tag": "v-layout",
                                "content-class": "ma-0 row wrap",
                                paginate: 40,
                                "fill-height": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple",
                                            },
                                          ],
                                          class: [
                                            {
                                              selected:
                                                _vm.selectedItems.indexOf(
                                                  props.item._id
                                                ) !== -1,
                                            },
                                            "attachmentItem",
                                          ],
                                          staticStyle: { width: "150px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.pickItem(props.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                flat: "",
                                                tile: "",
                                                src:
                                                  _vm.$thumb(props.item) ||
                                                  _vm.getExt(props.item.mime),
                                                contain: "",
                                                height: 150,
                                                width: 150,
                                                gradient:
                                                  "transparent 65%, rgba(34,34,34, 0.8) 65%, rgba(34,34,34, 1) 95%",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticStyle: {
                                                    width: "150px",
                                                  },
                                                  attrs: {
                                                    column: "",
                                                    "fill-height": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      props.item.uploading
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                value:
                                                                  props.item
                                                                    .progress *
                                                                  100,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  props.item.error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("error")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "div",
                                                    { staticClass: "pos-item" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$td(
                                                            props.item.name
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "post-actions" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.addFile },
                                    },
                                    [_c("v-icon", [_vm._v("add")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "ml-1",
                          staticStyle: { height: "100%" },
                          attrs: { xs12: "", md6: "" },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { height: "100%" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "", "fill-height": "" } },
                                [
                                  _c("v-card-title", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value: "mediaLibrary.details",
                                        expression: "'mediaLibrary.details'",
                                      },
                                    ],
                                  }),
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { height: "0", flex: "1" } },
                                    [
                                      _c(
                                        "perfect-scrollbar",
                                        {
                                          staticClass: "scroll-area",
                                          staticStyle: { height: "100%" },
                                        },
                                        [
                                          _vm.curItem
                                            ? [
                                                _c("v-img", {
                                                  attrs: {
                                                    src: _vm.$thumb(
                                                      _vm.curItem
                                                    ),
                                                    height: 150,
                                                    contain: "",
                                                  },
                                                }),
                                                _c(
                                                  "v-list",
                                                  _vm._l(
                                                    _vm.headers,
                                                    function (header, idx) {
                                                      return _c(
                                                        "v-list-item",
                                                        { key: idx },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        header.title
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "ripple",
                                                                      rawName:
                                                                        "v-ripple",
                                                                    },
                                                                  ],
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        header.value
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$jutils.copyTextToClipboard(
                                                                          header.value
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _vm.curItem
                                        ? [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteImage(
                                                      _vm.curItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("delete"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selecting
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-around", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loading,
                                    large: "",
                                    color: "green",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.save.apply(null, arguments)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("done")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { large: "", color: "red" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      ;(_vm.selectedItems = []),
                                        (_vm.inputValue = false)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("clear")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }