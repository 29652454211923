

import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

@Component
export default class MenuListNum extends Vue {

    @Prop()
    value: number

    @Prop(Boolean)
    readonly: boolean

    numPad = false;

    customReceived = '';

    @Prop({ type: String, default: 'done' })
    enterkeyhint: string

    @Watch('numPad')
    onNumPad(v) {
        if(!v) this.customReceived = '';
    }

    get inputValue() {
        return this.value
    }

    set inputValue(v : number) {
        v = Math.floor(v);
        if(this.minValue !== undefined) v = Math.max(v, this.minValue);
        if(this.maxValue !== undefined) v = Math.min(v, this.maxValue);

        if(v !== this.inputValue) {
            this.$emit('input', v);
        }
    }

    active = false;

    focused() {
        this.active = true;
    }

    submitValue() {
        this.active = false;
        if(this.customReceived !== '' && !isNaN(+this.customReceived)) {
            this.inputValue = +this.customReceived
        }
        this.customReceived = '';
    }

    @Prop({ type: Number, default: 0 })
    minValue: number

    @Prop({type: Number})
    maxValue: number
}

