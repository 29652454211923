var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "contents", class: _vm.sizes },
    [
      _vm.slots["before:" + _vm.fullPath]
        ? _c("renderer", {
            attrs: {
              render: _vm.slots["before:" + _vm.fullPath],
              item: _vm.item,
            },
          })
        : _vm._e(),
      _vm.slots[_vm.fullPath]
        ? _c("renderer", { attrs: { render: _vm.slots[_vm.fullPath] } })
        : _vm.preview
        ? [
            _c("editor-group-preview", {
              attrs: { field: _vm.field, item: _vm.item },
            }),
          ]
        : [
            !_vm.field.cond || _vm.field.cond(_vm.condCtx)
              ? _c(
                  _vm.field.component,
                  _vm._b(
                    {
                      tag: "component",
                      attrs: { "use-edit": false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function (scope) {
                              return [
                                _vm.field.inner
                                  ? _vm._l(_vm.field.inner, function (entry) {
                                      return _c(
                                        "editor-field",
                                        _vm._b(
                                          {
                                            key:
                                              entry.displayPath || entry.path,
                                            attrs: {
                                              field: entry,
                                              item:
                                                scope.item === undefined
                                                  ? _vm.item
                                                  : scope.item,
                                              slots: _vm.slots,
                                              parentPath: _vm.fullPath,
                                            },
                                            on: {
                                              updated: function ($event) {
                                                return _vm.$emit("updated")
                                              },
                                            },
                                          },
                                          "editor-field",
                                          scope,
                                          false
                                        )
                                      )
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "items",
                            fn: function (scope) {
                              return [
                                _vm.field.inner
                                  ? _vm._l(_vm.field.inner, function (entry) {
                                      return _c(
                                        "editor-multi",
                                        _vm._b(
                                          {
                                            key: entry.path,
                                            attrs: { field: entry },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("editor-field", {
                                                        attrs: {
                                                          item: item,
                                                          slots: _vm.slots,
                                                          parentPath:
                                                            _vm.fullPath,
                                                          field: entry,
                                                        },
                                                        on: {
                                                          updated: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "updated"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          "editor-multi",
                                          scope,
                                          false
                                        )
                                      )
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm.field.default
                                  ? _vm._l(_vm.field.default, function (entry) {
                                      return _c("editor-field", {
                                        key: entry.displayPath || entry.path,
                                        attrs: {
                                          field: entry,
                                          item: _vm.item,
                                          slots: _vm.slots,
                                          parentPath: _vm.fullPath,
                                        },
                                        on: {
                                          updated: function ($event) {
                                            return _vm.$emit("updated")
                                          },
                                        },
                                      })
                                    })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3286411634
                      ),
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    },
                    "component",
                    _vm.props,
                    false
                  )
                )
              : _vm._e(),
          ],
      _vm.slots["after:" + _vm.fullPath]
        ? _c("renderer", {
            attrs: {
              render: _vm.slots["after:" + _vm.fullPath],
              item: _vm.item,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }