var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: { isMobile: _vm.isMobile },
      on: { mouseover: _vm.onHover, click: _vm.onClick },
    },
    [
      _vm.isMobile
        ? [
            _c(
              "div",
              {
                staticClass: "data-card item-row pa-2 relative",
                on: {
                  click: function ($event) {
                    _vm.idState.expanded = !_vm.idState.expanded
                  },
                },
              },
              [
                _vm.selectable
                  ? _c("v-checkbox", {
                      staticStyle: { "max-width": "50px" },
                      attrs: { primary: "", "hide-details": "", height: 20 },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          void 0
                        },
                      },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "grid",
                      "grid-template-columns":
                        "minmax(100px,min-content) minmax(0,1fr)",
                    },
                  },
                  [
                    _vm._l(_vm.context.headers, function (header, idx) {
                      return [
                        _c(
                          "div",
                          {
                            key: "k" + idx,
                            staticStyle: { "font-weight": "600" },
                          },
                          [_vm._v(_vm._s(_vm.$t(header.text)))]
                        ),
                        _c(
                          "div",
                          {
                            key: idx,
                            class: ["px-2 itemText"],
                            staticStyle: {
                              overflow: "hidden",
                              position: "relative",
                            },
                          },
                          [
                            _c("virtual-data-table-cell", {
                              attrs: {
                                context: _vm.context,
                                item: _vm.source,
                                header: header,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm.appendInit && _vm.context.hasRowActions
                  ? _c(
                      "div",
                      { staticClass: "item-action pa-2" },
                      [
                        _vm.checkAcl("patch")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "my-1",
                                attrs: {
                                  "alt-color": "teal",
                                  "alt-text": _vm.$t("basic.edit"),
                                  to: _vm.editPath,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.context.editItem(_vm.source)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "teal" } }, [
                                  _vm._v("edit"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkAcl("create")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "my-1",
                                attrs: {
                                  "alt-color": "purple",
                                  "alt-text": _vm.$t("basic.clone"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.context.editItem(
                                      _vm.source,
                                      true
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "purple" } }, [
                                  _vm._v("file_copy"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkAcl("remove")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "my-1",
                                attrs: {
                                  "alt-color": "pink",
                                  "alt-text": _vm.$t("basic.delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.context.deleteItem(_vm.source)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "pink" } }, [
                                  _vm._v("delete"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("renderer", {
                          attrs: {
                            isSlot: true,
                            render: _vm.context.$scopedSlots["rowActions"],
                            item: _vm.source,
                            context: _vm.context,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.idState.expanded && _vm.expand
                  ? _c(
                      "div",
                      [
                        _c("renderer", {
                          attrs: {
                            render: _vm.expand,
                            item: _vm.source,
                            isSlot: true,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              {
                class: ["py-2", "item-row", { checked: _vm.selected }],
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "word-break": "break-word",
                  position: "relative",
                },
                on: {
                  click: function ($event) {
                    _vm.idState.expanded = !_vm.idState.expanded
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-2 item-index text-center",
                    staticStyle: { "min-width": "50px", overflow: "hidden" },
                  },
                  [
                    _vm.selectable
                      ? _c("v-checkbox", {
                          staticStyle: { "max-width": "50px" },
                          attrs: {
                            primary: "",
                            "hide-details": "",
                            height: 20,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              void 0
                            },
                          },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "item-index-text grey--text-darken-3",
                        staticStyle: { "white-space": "nowrap" },
                      },
                      [_vm._v(_vm._s(_vm.context.pageStart + _vm.index + 1))]
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.context.headers, function (header, idx) {
                  return _c(
                    "div",
                    {
                      key: idx,
                      staticClass: "px-2 itemText",
                      staticStyle: {
                        width: "180px",
                        overflow: "hidden",
                        position: "relative",
                        minHeight: "50px",
                        "align-items": "center",
                        display: "flex",
                      },
                    },
                    [
                      _c("virtual-data-table-cell", {
                        attrs: {
                          context: _vm.context,
                          item: _vm.source,
                          header: header,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm.appendInit && _vm.context.hasRowActions
                  ? _c(
                      "div",
                      { staticClass: "item-action py-2" },
                      [
                        _vm.checkAcl("patch")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "alt-color": "teal",
                                  "alt-text": _vm.$t("basic.edit"),
                                  to: _vm.editPath,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    _vm.context.inlineEdit &&
                                      _vm.context.editItem(_vm.source)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "teal" } }, [
                                  _vm._v("edit"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        (
                          _vm.context.canClone === undefined
                            ? _vm.checkAcl("create")
                            : _vm.context.canClone
                        )
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "alt-color": "purple",
                                  "alt-text": _vm.$t("basic.clone"),
                                  to: _vm.clonePath,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    _vm.context.inlineEdit &&
                                      _vm.context.editItem(_vm.source, true)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "purple" } }, [
                                  _vm._v("file_copy"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkAcl("remove")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "alt-color": "pink",
                                  "alt-text": _vm.$t("basic.delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.context.deleteItem(_vm.source)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "pink" } }, [
                                  _vm._v("delete"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("renderer", {
                          attrs: {
                            isSlot: true,
                            render: _vm.context.$scopedSlots["rowActions"],
                            item: _vm.source,
                            context: _vm.context,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.appendRow
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "180px",
                          overflow: "hidden",
                          position: "relative",
                        },
                      },
                      [
                        _c(_vm.appendRow, {
                          tag: "component",
                          attrs: { item: _vm.source, context: _vm.context },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm.idState.expanded && _vm.expand
              ? _c(
                  "div",
                  [
                    _c("renderer", {
                      attrs: {
                        render: _vm.expand,
                        item: _vm.source,
                        isSlot: true,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }