var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex items-center gap-x-2 cursor-pointer select-none",
      class: {
        changed: _vm.changed,
        checked: _vm.inputValue,
        unchecked: !_vm.inputValue,
      },
      on: { click: _vm.toggleValue },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "aspect-square rounded items-center justify-center checker-bg d-flex",
          class: _vm.checkerClass,
          attrs: { role: "button" },
        },
        [
          _c("div", { staticClass: "flex-grow m-1" }, [
            _c(
              "svg",
              {
                staticClass: "w-full h-full",
                attrs: {
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  staticClass: "check-path-1",
                  attrs: {
                    d: "M3 9L6 12",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
                _c("path", {
                  staticClass: "check-path-2",
                  attrs: {
                    d: "M6 12L13 4",
                    stroke: "white",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm.text
        ? _c("div", { attrs: { role: "button" } }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }