
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class VirtualDataListSlot extends Vue {
    @Prop()
    source: any

    @Prop()
    remove: any

    @Prop()
    update: any

    render() {
        const c = (this as any)._t('item', [], {
            item: this.source,
            remove: this.remove,
            update: this.update,
        });
        if(Array.isArray(c)) {
            return c[0];
        }
        return c;
    }
}

