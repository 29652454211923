var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.config
    ? _c("virtual-data-table", {
        directives: [
          {
            name: "action",
            rawName: "v-action.add",
            value: _vm.addActionClick,
            expression: "addActionClick",
            modifiers: { add: true },
          },
          {
            name: "action",
            rawName: "v-action.refresh",
            value: _vm.refreshActionClick,
            expression: "refreshActionClick",
            modifiers: { refresh: true },
          },
          {
            name: "action",
            rawName: "v-action.export",
            value: _vm.exportActionClick,
            expression: "exportActionClick",
            modifiers: { export: true },
          },
          {
            name: "action",
            rawName: "v-action.import",
            value: _vm.importActionClick,
            expression: "importActionClick",
            modifiers: { import: true },
          },
        ],
        ref: "table",
        attrs: {
          path: _vm.config.service,
          "item-key": "_id",
          headers: _vm.headers,
          "export-headers": _vm.exportHeaders,
          "export-filter-override": _vm.exportFilterOverride,
          acl: _vm.acl,
          patch: _vm.canPatch,
          remove: _vm.canRemove,
          create: _vm.canCreate,
          canClone: _vm.canClone,
          query: _vm.query,
          "default-sort": _vm.config.defaultSort,
          "default-sort-desc": _vm.config.defaultSortDesc,
          default: _vm.defaultItem || _vm.config.defaultValue,
          preEdit: _vm.preEdit,
          editParams: _vm.editParams,
          nested: _vm.nested,
          hidden: _vm.hidden,
          "append-row": _vm.config.appendRow,
          "no-paginate":
            typeof _vm.config.paginate === "boolean" && !_vm.config.paginate,
          "inline-edit": _vm.inlineEdit,
          rowActions: _vm.rowActions,
          saveCallback: _vm.saveCallback,
          pager: _vm.nested ? false : _vm.pager,
          rootPath: _vm.rootPath,
          "multi-select": _vm.multiSelect,
          serializedState: _vm.serializedState,
        },
        on: { scroll: _vm.scroll },
        scopedSlots: _vm._u(
          [
            {
              key: "editor",
              fn: function (edit) {
                return [
                  _vm._t(
                    "editor",
                    function () {
                      return [
                        _c("editor-single-page", {
                          attrs: {
                            config: _vm.config,
                            item: edit.item,
                            canSave: "",
                            canRemove: edit.provider.remove,
                            canCancel: "",
                          },
                          on: {
                            save: function ($event) {
                              return edit.save()
                            },
                            remove: function ($event) {
                              return edit.provider.deleteItem(edit.item)
                            },
                            cancel: function ($event) {
                              return edit.cancel()
                            },
                          },
                        }),
                      ]
                    },
                    null,
                    edit
                  ),
                ]
              },
            },
            {
              key: "expand",
              fn: function (edit) {
                return [
                  _c("renderer", {
                    attrs: { render: _vm.renderExpand, item: edit.item },
                  }),
                  _vm.$scopedSlots.expand
                    ? _c("renderer", {
                        attrs: {
                          render: _vm.$scopedSlots.expand,
                          isSlot: true,
                          item: edit.item,
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "before",
              fn: function ({ context }) {
                return [
                  !_vm.nested
                    ? _c("editor-search-menu", {
                        attrs: {
                          config: _vm.config,
                          filter: _vm.filter,
                          showSearchMenu: _vm.showSearchMenu,
                          context: context,
                          setting: _vm.setting,
                          dateCutOffTime: _vm.dateCutOffTime,
                          serializedState: _vm.serializedState,
                        },
                        on: {
                          "update:showSearchMenu": function ($event) {
                            _vm.showSearchMenu = $event
                          },
                          "update:show-search-menu": function ($event) {
                            _vm.showSearchMenu = $event
                          },
                          "update:serializedState": function ($event) {
                            _vm.serializedState = $event
                          },
                          "update:serialized-state": function ($event) {
                            _vm.serializedState = $event
                          },
                        },
                        model: {
                          value: _vm.query,
                          callback: function ($$v) {
                            _vm.query = $$v
                          },
                          expression: "query",
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "headerActions",
              fn: function (item) {
                return [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.showViewSetting } },
                    [_c("v-icon", [_vm._v("view_list")])],
                    1
                  ),
                  _vm._t("headerActions", null, { addItem: _vm.addItem }, item),
                ]
              },
            },
            {
              key: "batchActions",
              fn: function () {
                return [
                  _vm.showBatchActions
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.multiEdit()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("edit_note")])],
                        1
                      )
                    : _vm._e(),
                  _vm.canClone && _vm.showBatchActions
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.multiEdit("clone")
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "purple" } }, [
                            _vm._v("file_copy"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._t("batchActions"),
                ]
              },
              proxy: true,
            },
            {
              key: "rowActions",
              fn: function (item) {
                return [_vm._t("rowActions", null, null, item)]
              },
            },
            {
              key: "export",
              fn: function (item) {
                return [_vm._t("export", null, null, item)]
              },
            },
            _vm._l(_vm.itemSlots, function (_, slot) {
              return {
                key: slot,
                fn: function (scope) {
                  return [_vm._t(slot, null, null, scope)]
                },
              }
            }),
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }