var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      [
        _vm.isContainer
          ? _c(
              _vm.field.component,
              _vm._b(
                {
                  tag: "component",
                  attrs: { "use-edit": false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function (scope) {
                          return [
                            _vm.field.inner
                              ? _vm._l(_vm.field.inner, function (entry) {
                                  return _c(
                                    "editor-multi",
                                    _vm._b(
                                      {
                                        key: entry.path,
                                        attrs: {
                                          field: entry,
                                          items:
                                            scope.items === undefined
                                              ? _vm.items
                                              : scope.items,
                                          slots: _vm.slots,
                                          parentPath: _vm.parentPath,
                                          formatHeader: _vm.formatHeader,
                                        },
                                      },
                                      "editor-multi",
                                      scope,
                                      false
                                    )
                                  )
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _vm.field.default
                              ? _vm._l(_vm.field.default, function (entry) {
                                  return _c("editor-multi", {
                                    key: entry.path,
                                    attrs: {
                                      field: entry,
                                      items: _vm.items,
                                      slots: _vm.slots,
                                      parentPath: _vm.parentPath,
                                      formatHeader: _vm.formatHeader,
                                    },
                                  })
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3742239409
                  ),
                },
                "component",
                _vm.props,
                false
              )
            )
          : _c("div", [
              _c("div", { staticClass: "d-flex items-center my-1" }, [
                _c(
                  "div",
                  { staticClass: "w-25" },
                  [_vm.selectedGroup ? void 0 : void 0],
                  2
                ),
                _vm.selectedGroup
                  ? _c(
                      "div",
                      { staticClass: "flex-grow" },
                      [_vm._t("item", null, { item: _vm.selectedGroup[0] })],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }