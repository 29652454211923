var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.supported
    ? _c("div", { staticClass: "absolute inset-0 pointer-events-none" })
    : _c("resize-observer", { on: { notify: _vm.handleResize } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }