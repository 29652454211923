
import { Component, Prop, Vue } from "@feathers-client";
import AsyncPopulate from "~/components/AsyncPopulate.vue";

@Component({
    components: { AsyncPopulate }
})
export default class ReadonlyFlat extends Vue {
    @Prop()
    value;
}
