var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        {
          staticClass:
            "w-full d-flex relative border-1 rounded-lg px-3 py-2.5 box-border gap-y-1 overflow-x-auto",
          class: [
            _vm.activeMenu ? _vm.activeClass : _vm.inactiveClass,
            { "flex-wrap": _vm.multiple },
          ],
          on: { click: _vm.clickOuter },
        },
        [
          _vm.multiple || !_vm.activeMenu
            ? _c(
                "draggable",
                {
                  staticClass: "d-flex gap-x-2 mr-2",
                  attrs: {
                    "ghost-class": "ghost",
                    handle: ".handle",
                    animation: 200,
                  },
                  model: {
                    value: _vm.cachedNames,
                    callback: function ($$v) {
                      _vm.cachedNames = $$v
                    },
                    expression: "cachedNames",
                  },
                },
                _vm._l(_vm.cachedNames, function (item) {
                  return _c(
                    "div",
                    {
                      key: item[0],
                      staticClass:
                        "whitespace-nowrap rounded d-flex items-center gap-x-3 px-2 chip-bg",
                      class: {
                        handle:
                          !_vm.readonly &&
                          _vm.multiple &&
                          _vm.cachedNames.length > 1,
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(item[1]))]),
                      !_vm.mandatory && !_vm.readonly
                        ? _c("div", {
                            staticClass:
                              "chip-remove cursor-pointer w-2.5 h-2.5",
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(item[0])
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.combo
            ? _c("div", {
                staticClass: "text-sm",
                class: { "text-orange100": _vm.activeMenu },
                domProps: { textContent: _vm._s(_vm.searchKeyword) },
              })
            : _vm._e(),
          _c("menu-list-input", {
            ref: "input",
            staticClass: "flex-grow min-w-10 outline-none text-sm",
            attrs: {
              "select-all": "",
              value: _vm.displayKeyword,
              enterkeyhint: _vm.enterkeyhint,
              readonly: _vm.readonly,
            },
            on: {
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              input: function ($event) {
                return _vm.setSearch($event)
              },
              backspace: _vm.onBackspace,
              submit: _vm.submit,
            },
          }),
        ],
        1
      ),
      _vm._t("items", null, { edit: _vm.cachedItemsEditor }),
      _vm.path && !_vm.returnObject
        ? _c("async-populate", {
            attrs: {
              value: _vm.inputValue,
              path: _vm.path,
              multiple: _vm.multiple,
              args: _vm.query,
              maybeValue: _vm.maybeValue,
              "hide-empty": "",
            },
            on: { cachedValue: _vm.onCachedLoaded },
          })
        : _vm._e(),
      _c("teleport-menu", {
        attrs: { overlay: false, wrap: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "pos-popup shadow-1 rounded-b-lg",
                    on: {
                      mousedown: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.onMouseDown.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("virtual-data-list", {
                      ref: "dataList",
                      staticClass:
                        "h-[min(25rem,50vh)] overflow-y-auto overflow-x-hidden",
                      attrs: {
                        path: _vm.path,
                        query: _vm.finalQuery,
                        reloadThrottle: 100,
                        reloadThrottleWhenLoading: 500,
                        staticItems: _vm.items,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("menu-list-item", {
                                staticClass: "px-5 py-2",
                                class: {
                                  "!text-green100": _vm.keySet[item._id],
                                },
                                attrs: {
                                  icon:
                                    (_vm.keySet[item._id] && _vm.activeIcon) ||
                                    _vm.rowIcon,
                                  iconClass: _vm.keySet[item._id]
                                    ? "!text-green100 w-6 h-6"
                                    : "w-6 h-6",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseItem(item)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "grow",
                                      fn: function () {
                                        return [
                                          _vm.ordered
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.keySet[item._id])
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.normalizedFields,
                                            function (field) {
                                              return _c("highlighter", {
                                                key: field.field,
                                                style: _vm.colStyle,
                                                attrs: {
                                                  keyword: _vm.searchKeyword,
                                                  value: _vm.getter(
                                                    item,
                                                    field
                                                  ),
                                                },
                                              })
                                            }
                                          ),
                                          _vm.searchFields
                                            ? _c("highlighter", {
                                                style: _vm.colStyle,
                                                attrs: {
                                                  item: item,
                                                  keyword: _vm.searchKeyword,
                                                  fields: _vm.searchFields,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.activeMenu,
          callback: function ($$v) {
            _vm.activeMenu = $$v
          },
          expression: "activeMenu",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }