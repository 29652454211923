
import { Component, Prop, Vue, Watch, mixins,FindType, VModel, getID } from "@feathers-client";
import ItemPickerBase from "../components-internal/ItemPickerBase.vue"
import RadioIcon from "../components-internal/RadioIcon.vue"
import CheckIcon from "../components-internal/CheckIcon.vue"

@Component({
    components: {
        RadioIcon,
        CheckIcon,
    }
})
export default class ItemPicker extends mixins(ItemPickerBase) {

    @Prop({ type: Boolean })
    bottomBar: boolean

    @Prop(Boolean)
    wrap: boolean

    @Prop(Boolean)
    light: boolean

    @Prop(Boolean)
    radio: boolean

    @Prop(Boolean)
    check: boolean

}

