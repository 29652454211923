
import { Component, Prop, Watch, Vue } from '@feathers-client'
import uuid from 'uuid/v4'

interface DialogItem {
    key: string
    component?: any
    modal?: any
    _show?: boolean
    props: any
    show: boolean
    loading: boolean
    hide(r? : any, e?: any)

}

@Component
export default class DialogHost extends Vue {
    dialogs : DialogItem[] = []

    openDialog(e) {
        let item : DialogItem;
        const hide = (result? : any, err? : any) => {
            if(!item._show) return;
            item._show = false;

            if(err) {
                e.reject(err);
            } else {
                e.resolve(result);
            }

            setTimeout(() => {
                const idx = this.dialogs.indexOf(item);
                idx !== -1 && this.dialogs.splice(idx, 1);
            }, 500)
        }
        item = {
            key: uuid(),
            props: e.props,
            loading: false,
            _show: true,
            get show() {
                return this._show;
            },
            set show(v) {
                if(!v) {
                    hide();
                }
            },
            hide,
            component: null,
        }
        if(e.component.then) {
            item.loading = true;
            item.modal = { maxWidth: '100px', persistent: true };
            e.component.then((c) => {
                item.loading = false;
                item.modal = e.modal;
                item.component = c.default || c;
            }).catch(e => {
                console.warn(e);
                hide(undefined, e);
            });
        } else {
            item.component = e.component;
            item.modal = e.modal;
        }
        this.dialogs.push(item)
    }

    modalResult(e : { id: string, result?: any }) {
        const item = this.dialogs.find(it => it.key === e.id);
        if(item) {
            item.hide(e.result);
        }
    }
}

