var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, bottom: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-badge",
                {
                  attrs: {
                    overlap: "",
                    dot: "",
                    "offset-y": "20",
                    color: "red",
                    value: !_vm.valid,
                  },
                },
                [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "" } }, on),
                    [_c("v-icon", [_vm._v("more_horiz")])],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            "min-width": "200px",
            height: "400px",
            "overflow-y": "auto",
          },
        },
        [
          _c("v-card-title", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "name",
                expression: "'name'",
              },
            ],
          }),
          _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("fileSource"),
                  items: _vm.fileSources,
                  "error-messages":
                    (_vm.source && _vm.source.currentError) || "",
                },
                model: {
                  value: _vm.sourceType,
                  callback: function ($$v) {
                    _vm.sourceType = $$v
                  },
                  expression: "sourceType",
                },
              }),
              _vm.source
                ? [
                    _c("renderer", { attrs: { render: _vm.source.renderer } }),
                    _c("v-treeview", {
                      attrs: {
                        items: _vm.source.fileRoot.child,
                        "item-children": "child",
                        "item-key": "path",
                        "item-text": "name",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }