var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: { label: _vm.$t("urlSource.baseUrl") },
        model: {
          value: _vm.baseUrl,
          callback: function ($$v) {
            _vm.baseUrl = $$v
          },
          expression: "baseUrl",
        },
      }),
      _c("v-text-field", {
        attrs: { label: _vm.$t("urlSource.accessToken") },
        model: {
          value: _vm.accessToken,
          callback: function ($$v) {
            _vm.accessToken = $$v
          },
          expression: "accessToken",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }