var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cachedValue || _vm.allowEmpty
    ? _c("div", [_vm._t("default", null, { item: _vm.cachedValue })], 2)
    : _vm.loading
    ? _c(
        "div",
        [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
        1
      )
    : _vm.value
    ? _c(
        "div",
        [
          _vm._t("not-found", function () {
            return [_vm._v(_vm._s(_vm.value))]
          }),
        ],
        2
      )
    : _c("div", [_vm._t("no-value")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }