var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.error,
            callback: function ($$v) {
              _vm.error = $$v
            },
            expression: "error",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.close",
                expression: "'basic.close'",
              },
            ],
            attrs: { text: "", color: "pink" },
            nativeOn: {
              click: function ($event) {
                _vm.error = false
              },
            },
          }),
        ],
        1
      ),
      _c("nuxt"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }