// @ts-ignore
import feathers, { MApplication } from '@feathersjs/feathers';
import type { CurrentApp } from './';
import fio from '@feathersjs/socketio-client';
import * as SocketIO from 'socket.io-client';
import authentication from '@feathersjs/authentication-client';
import Vue from 'vue';
import { EventEmitter } from 'events';
import decode from 'jsonwebtoken/decode'
import axios from 'axios'
import { Context } from '@nuxt/types/app';

export default function (ctx : Context) {
    let $store = ctx.store;

    let app : feathers.Application;
    let socket : SocketIOClient.Socket;

    if (!Vue.prototype.hasOwnProperty('$feathers')) {
        app = feathers();
    
        if(!process.env.NO_API_SSR || process.browser) {
            const apiUrl = ctx.$config.iapiUrl || ctx.$config.apiUrl;
            console.log('Feathers Client using url:', apiUrl)

            let useMsgPack = false;
            
            if(process.browser && process.env.MSGPACK5) {
                function iOS() {
                    return [
                        'iPad Simulator',
                        'iPhone Simulator',
                        'iPod Simulator',
                        'iPad',
                        'iPhone',
                        'iPod'
                    ].includes(navigator.platform)
                    // iPad on iOS 13 detection
                    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
                }

                function iOSversion() {
                    if (iOS()) {
                        const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
                        const major = version && version[1] ? version[1] : "";
                        const minor = version && version[2] ? version[2] : "";
                        const patch = version && version[3] ? version[3] : "";
                        return +(major + '.' + (minor || 0));
                    }
                }
        
                const iosVer = iOSversion();
                if(iosVer && iosVer >= 15.0) {
                    useMsgPack = true;
                }
            }

            socket = SocketIO.connect(apiUrl, {
                path: '/api/socket.io',
                transports: ['websocket'],
                query: {
                    ...(process.env.MSGPACK5 ? {
                        ...(
                            useMsgPack ? {
                                msgpack: 1
                            } : {}
                        ),
                    } : {
                    }),
                    ...(process.browser ? {} : {
                        requestHost: ctx.$config.apiUrl
                    }),
                }
            });

            if(process.browser && process.env.MSGPACK5 && useMsgPack) {
                let msgpack = null;
    
                function mapObj(item) {
                    switch(typeof item) {
                        case 'function':
                        case 'object':
                            if(!item) return null;
                            if(item.toJSON) {
                                return mapObj(item.toJSON());
                            }
                            if(item instanceof Buffer) return item;
        
                            if(Array.isArray(item)) {
                                for(let i = 0; i < item.length; i++) {
                                    item[i] = mapObj(item[i])
                                }
                            } else {
                                for(let key of Object.keys(item)) {
                                    item[key] = mapObj(item[key]);
                                }
                            }
                            return item;
                        
                        case 'symbol':
                        case 'undefined':
                            return null;
        
                        default:
                            return item;
                    }
                }
        
                const emit = (socket as any).emit.bind(socket);
                (socket as any).emit = (event, ...args) => {
                    let f = args[args.length - 1];
                    if(f instanceof Function) args.pop();
                    else f = null;
                    if(msgpack && args.length) {
                        const fargs = [msgpack.encode(mapObj(args))];
                        if(f) fargs.push(f);
                        emit(event, ...fargs);
                    } else {
                        const fargs = args;
                        if(f) fargs.push(f);
                        emit(event, ...fargs);
                    }
                };
        
                const onack = (socket as any).onack.bind(socket);
                (socket as any).onack = (packet) => {
                    if(packet.data && packet.data.length === 1 && packet.data[0] instanceof ArrayBuffer) {
                        packet.data = msgpack.decode(packet.data[0]);
                    }
                    onack(packet);
                }
        
                socket.once('initMsgPack', () => {
                    try {
                        msgpack = new (require('msgpack5'))();
                    } catch (e) {
                        console.warn("Received initMsgPack but msgpack5 not found in dependency")
                    }
                });
            }

            {
                const cbSet = new Set<(error:any) => void>();
                const emit = (socket as any).emit.bind(socket);
                (socket as any).emit = (event, ...args) => {
                    let f = args[args.length - 1];
                    if(f instanceof Function) args.pop();
                    else f = null;
                    const fargs = args;
                    if(f) {
                        cbSet.add(f);
                        fargs.push((...resp) => {
                            cbSet.delete(f);
                            f(...resp);
                        });
                    }
                    emit(event, ...fargs);
                };

                socket.on('disconnect', function() {
                    for(let cb of cbSet) {
                        cb({
                            name: 'Timeout',
                        })
                    }
                    cbSet.clear();
                });
            }

            
            if(process.server) {
                socket.on('error', function(e) {
                    console.log('Server Socket error', e);
                });

                socket.on('connect', function() {
                    console.log('Server Socket connected');
                });
                
                socket.on('reconnect', function() {
                });
                
                socket.on('disconnect', function() {
                    console.log('Server Socket disconnected');
                });
            }
            
            app.configure(fio(socket, { timeout: 30000 }));

            if(process.server) {
                const ssrController = (ctx.ssrContext as any)?.ssrController;
                if(ssrController) {
                    async function subscribeSSREvent() {
                        await app.service('ssrEvents/subscribe').create({
                        });
                    }
                    socket.on('connect', function() {
                        subscribeSSREvent().catch(e => console.log("Failed to subscribe ssr event", e))
                    });

                    app.service('ssrEvents').on('created', async function(event) {
                        try {
                            if(event.url) {
                                ssrController.clear(event.url);
                            } else if(event.regexp) {
                                ssrController.clear(new RegExp(event.regexp));
                            } else if(event.all) {
                                ssrController.clearAll();
                            }
                        } catch(e) {
                            console.warn("Failed to handle ssr purge event", e)
                        }
                    })
                }
            }
        }
    
        Object.defineProperty(Vue.prototype, '$feathers', {
            get(this : Vue) { return app; },
            enumerable: false
        });
    } else {
        app = Vue.prototype.$feathers;
    }

    ctx.app.$feathers = <any>app;

    if(process.client) {
        $store.commit('INIT');
        app.configure(authentication());
        let mapp : any = <any>app;
        let loggingIn;
        let connected = socket.connected;
        $store.commit('SET_CONNECTED', connected);

        socket.on('connect', function() {
            console.log('Socket connected');
            $store.commit('SET_CONNECTED', true);
            app.emit('connected');
            connected = true;
            loggingIn = null;
        });
        
        socket.on('reconnect', function() {
            // app.emit('connected');
            loggingIn = null;
        });
        
        socket.on('disconnect', function() {
            console.log('Socket disconnected');
            mapp.authenticated = false;
            mapp.authState = false;
            connected = false;
            $store.commit('SET_CONNECTED', false);
            mapp.emit('disconnected');
        });

        socket.on('pong', function(ping) {
            mapp.emit('pong', ping);
        })

        app.hooks({
            before: {
                async all(hook) {
                    if(!connected) {
                        await new Promise(resolve => socket.once('connect', resolve));
                    }
                    if(hook.path === "authentication" || hook.params.noAuthCheck) return;
                    if (!mapp.authState) await tryLogin();
                }
            },
            error: {
                async all(hook) {
                    if(hook.path === "authentication") return;
                    if (process.browser && hook.error.className === 'not-authenticated') {
                        if (!(await tryLogin())) {
                            if (!mapp.notifyLogin) {
                                mapp.notifyLogin = true;
                            }
                            throw hook.error;
                        }
                        if(hook.params.depth > 3) {
                            try {
                                await app.authentication.removeAccessToken();
                                await app.logout();
                            } catch(e) {
                                console.warn(e);
                            }
                            mapp.notifyLogin = true;
                            throw hook.error;
                        }
                        hook.params.depth = (hook.params.depth || 0) + 1;
                        let args: Array<any> = [hook.params];
                        switch (hook.method) {
                            case 'patch':
                            case 'update':
                            case 'create':
                                args.unshift(hook.data);
                                break;
                        }
                        switch (hook.method) {
                            case 'patch':
                            case 'update':
                            case 'remove':
                                args.unshift(hook.id);
                                break;
                        }
                        hook.result = await (hook as any).service[hook.method].apply(hook.service, args);
                    }
                }
            }
        });

        mapp.updateProfile = async function(userId, cacheId) {
            try {
                const picUrl = `${ctx.$config.apiUrl}/api/users/pic/${userId}?${cacheId}`;
                console.log('start download', picUrl);
                const buffer = await axios.get(picUrl, {
                    responseType: 'arraybuffer'
                })
                const pic = new Buffer(buffer.data).toString('base64');
                $store.commit('SET_USER_PROFILE', `data:${buffer.headers['content-type']};base64,${pic}`);
            } catch (e : any) {
                console.log(e.message);
            }
        };
        

        app.on('login', (authResult : any) => {
            try {
                $store.commit('SET_USER', authResult.user);
            } catch(e : any) {
                console.warn(e.message, e.stack);
            }
        });
    
        app.on('logout', () => {
            $store.commit('SET_USER', null);
        })
    
        const a = axios.create({ baseURL: ctx.$config.apiUrl + '/api' })
    
        mapp.post = <any>async function(url, data, params) {
            params = params || {};
            const accessToken = await app.authentication.getAccessToken();
            return a.post(`${url}`, data, {
                ...params,
                headers: { 
                    Authorization: `Bearer ${accessToken}`,
                    ...(params.headers || {}),
                }
            });
        }

        function tryLogin() {
            return (loggingIn || (loggingIn = tryLoginCore()));
        }

        async function tryLoginCore() {
            if (mapp.authState && !app.authentication.authenticated) {
                return app.authentication.authenticated;
            }
            try {
                await app.reAuthenticate(true);
                mapp.authState = true;
                console.log('reauth done');
                return true;
            } catch (e : any) {
                if (e.message === 'Authentication timed out') {
                    // console.log('try next');
                    mapp.authState = false;
                } else if(e.className === 'not-authenticated' || e.className === 'not-found') {
                    mapp.authState = true;
                    try {
                        await app.authentication.removeAccessToken();
                        await app.logout();
                    } catch(e) {
                        console.warn(e);
                    }
                    return false;
                }
                console.warn(e);
                return false;
            } finally {
                loggingIn = null;
            }
        }
    
        ctx.app.$feathers = mapp;
    }
}

declare module 'vue/types/options' {
    export interface ComponentOptions<
        V extends Vue,
        Data=DefaultData<V>,
        Methods=DefaultMethods<V>,
        Computed=DefaultComputed,
        PropsDef=PropsDefinition<DefaultProps>,
        Props=DefaultProps> {
            $feathers?: MApplication<CurrentApp>
    }
}
