
import moment from "moment";
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID, VModel } from "@feathers-client";
// @ts-ignore
import MenuListPicker, { SearchConfig } from "@feathers-client/components/MenuListPicker.vue";

@Component({
  components: {
    MenuListPicker,
  },
})
export default class EditorObjectPickerList extends Vue {
  @Prop()
  path: string;

  @Prop()
  label: string;

  @VModel()
  inputValue: any;

  @Prop(Boolean)
  multiple: boolean;

  @Prop({ type: Boolean, default: false })
  required: boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  returnObject: boolean;

  @Prop()
  name: SearchConfig[];

  @Prop()
  fields: SearchConfig[];

  @Prop()
  args: any;

  @Prop()
  items: any[];

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;
}
