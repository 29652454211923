
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID, VModel } from "@feathers-client";
// @ts-ignore
import ItemPicker from "@feathers-client/components/ItemPicker.vue";

@Component({
  components: {
    ItemPicker,
  },
})
export default class EditorObjectPickerNew extends Vue {
  @Prop()
  items: any[];

  mitems: any[] = [];

  get finalItems() {
    return this.items || this.mitems;
  }

  @Prop()
  label: string;

  @Prop()
  path: string;

  @VModel()
  inputValue: any;

  @Prop(Boolean)
  multiple: boolean;

  @Prop({ type: Boolean, default: false })
  required: boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop()
  args: any;

  beforeMount() {
    this.loadData();
  }

  async loadData() {
    if (this.path) {
      try {
        this.mitems = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
            $paginate: false,
          },
        });
      } catch (e) {
        const resp = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
          },
        });
        if (Array.isArray(resp)) {
          this.items = resp;
        } else if (Array.isArray(resp?.data)) {
          this.items = resp.data;
        }
      }
    }
  }
}
