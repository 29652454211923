var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.label))]),
      _c(
        "v-card-text",
        _vm._l(_vm.inputValue, function (entry, idx) {
          return _c(
            "v-layout",
            {
              key: idx,
              attrs: {
                row: "",
                "align-content-center": "",
                "justify-center": "",
                "align-center": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "flex-grow": "1" } },
                [
                  _vm._t("item", null, {
                    item: entry,
                    edit: {
                      get item() {
                        return entry
                      },
                      set item(v) {
                        _vm.inputValue = [
                          ..._vm.inputValue.slice(0, idx),
                          v,
                          ..._vm.inputValue.slice(idx + 1),
                        ]
                      },
                    },
                  }),
                ],
                2
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.inputValue = [
                        ..._vm.inputValue.slice(0, idx),
                        ..._vm.inputValue.slice(idx + 1),
                      ]
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              _vm.inputValue
                ? (_vm.inputValue = [..._vm.inputValue, _vm.getDefault()])
                : (_vm.inputValue = [_vm.getDefault()])
            },
          },
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }