import type {
  SchemaHelper,
  EditorConfig,
  EditorField,
  ImportOptions,
  SchemaTypeFullJson,
  ImportLinkField,
} from "./plugin";
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import type { LangType, LangArrType } from "@feathers-client/i18n";

import { ImportField, ImportAttachmentField, ImportConfig, ImportLinkedField } from "./importField";
import { ImportFileSource } from "./importFileSource";
import { Comment } from "./commentsReader";
import colCache from "exceljs/lib/utils/col-cache";
import { isUint16Array } from "util/types";
import uuid from 'uuid/v4'

export * from "./importField";
export * from "./importFileSource";

export class ImportConfigTree {
  inner: ImportConfigTree[] = [];
  temp: Record<string, any> = {};

  constructor(public path: string, public field: ImportField, public config: ImportConfig) {}
}

export function buildImportTree(configs: readonly ImportConfig[]) {
  const dict: Record<string, ImportConfigTree> = {};
  const roots: ImportConfigTree[] = [];

  for (let config of configs) {
    let cur = config.field;
    let curTree: ImportConfigTree;
    do {
      const lastTree = curTree;
      curTree = dict[cur.fullPath];
      if (curTree) {
        if (lastTree) {
          curTree.inner.push(lastTree);
        }
        break;
      }
      dict[cur.fullPath] = curTree = new ImportConfigTree(cur.fullPath, cur, config);
      if (!cur.parent) {
        roots.push(curTree);
      }
      if (lastTree) {
        curTree.inner.push(lastTree);
      }
      cur = cur.parent;
    } while (cur);
  }
  return { roots, dict };
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}
getTextWidth.canvas = null;

export interface SheetRow {
  row: number;
  values: string[];
  header: boolean;
  state?: string;
  sourceID?: string;
  result?: any;

  linkedIndex?: any;
  linkedResult?: any;
  linkedRows?: SheetRow[]
  linkedID?: string;
  linkedStatus?: string
  isSub?: boolean
}

export class WorksheetReader {
  constructor(wb: any, el?: HTMLElement) {
    this.id = wb.id;
    if (wb) {
      this.name = wb.name;
    }
    Object.defineProperty(this, "$el", {
      enumerable: false,
      value: el,
    });
    this.it = this.readWorkbook(wb);
  }

  id: number;

  $el: HTMLElement;
  name: string;
  it: AsyncGenerator<any>;
  initPromise: Promise<void>;
  loadPromise: Promise<void>;

  finished = false;
  paused = false;
  working = false;
  rowCount = 0;

  get rowWidth() {
    return _.sumBy(this.cols, (c) => c.width) + 30;
  }

  cols: {
    owidth?: number;
    width: number;
    textLength: number;
  }[] = [];
  rows: SheetRow[] = [];

  progress = -1;
  rowStart = 0;

  get store() {
    return this.rows;
  }

  rowSimilar(a: SheetRow, b: SheetRow) {
    const cols = Math.max(a.values.length, b.values.length);
    let simCnt = 0;

    for (let i = 0; i < cols; i++) {
      const va = `${a.values[i] || ""}`.trim();
      const vb = `${b.values[i] || ""}`.trim();

      if (!va && !vb) simCnt++;
      else if (va && vb && Math.abs((va.length - vb.length) / va.length) < 0.5) simCnt++;
    }

    return Math.abs(cols - simCnt) / cols < 0.3;
  }

  rowEmpty(row: any[]) {
    return _.every(row, (r) => !`${row || ""}`.trim().length);
  }

  updateRowStart(chunk: SheetRow[]) {
    if (this.rows.length) return;
    for (let i = Math.min(20, chunk.length - 1); i > 0; i--) {
      const row = chunk[i];
      if (this.rowEmpty(row.values)) {
        this.rowStart = i;
        continue;
      } else if (!this.rowSimilar(row, chunk[i - 1])) {
        this.rowStart = i;
      }
    }
    if (this.rowStart === 0) {
      this.rowStart = 1;
    }
  }

  getCellValue(cellValue: any) {
    if (cellValue && typeof cellValue === "object") {
      if (cellValue.richText) {
        return _.map(cellValue.richText, (r) => r.text ?? "").join("");
      }
    }
    return `${cellValue ?? ""}`;
  }

  async processChunk(chunk: SheetRow[]) {
    for (let processor of this.processors) {
      await processor(chunk);
    }
  }

  processors: ((chunk: SheetRow[]) => void | Promise<void>)[] = [];
  addChunkProcess(processor: (chunk: SheetRow[]) => void | Promise<void>) {
    this.processors.push(processor);
  }

  removeChunkProcess(processor: (chunk: SheetRow[]) => void | Promise<void>) {
    const idx = this.processors.indexOf(processor);
    idx !== -1 && this.processors.splice(idx, 1);
  }

  async *readWorkbook(wb: any) {
    let rows = 0;
    let chunk: SheetRow[] = [];
    const hasProgress =
      wb.iterator && wb.iterator.bytesRead !== undefined && wb.iterator.size !== undefined;
    if (hasProgress) this.progress = 0;
    for await (const row of wb) {
      let values = row.values;
      while (values.length > this.cols.length) {
        const curVal = this.getCellValue(values[this.cols.length]);
        const tw = this.$el
          ? Math.ceil((getTextWidth(curVal, window.getComputedStyle(this.$el).font) + 9) / 10) * 10
          : curVal.length * 16;
        const width = Math.min(300, Math.max(100, tw));

        this.cols.push({
          width,
          textLength: (curVal.length / tw) * width * 1.2,
        });
      }
      if (rows < 20) {
        for (let col = 0; col < values.length; col++) {
          const curVal = this.getCellValue(values[col]);
          const c = this.cols[col];
          if (curVal.length > c.textLength) {
            const tw = this.$el
              ? Math.ceil((getTextWidth(curVal, window.getComputedStyle(this.$el).font) + 9) / 10) *
                10
              : curVal.length * 16;
            const width = Math.min(300, Math.max(100, tw));
            c.width = width;
            c.textLength = (curVal.length / tw) * width * 1.2;
          }
        }
      }
      const r: SheetRow = {
        row: rows,
        values,
        header: false,
        state: "",
      };
      // Object.freeze(r);
      Object.freeze(values);
      chunk.push(r);
      rows++;
      if (rows % 1000 === 0) {
        if (hasProgress) {
          this.progress = (wb.iterator.bytesRead / wb.iterator.size) * 100;
        }
        this.rowCount = rows;
        this.updateRowStart(chunk);
        this.rows.push(...chunk);
        await this.processChunk(chunk);
        yield chunk;
        chunk = [];
        await new Promise((resolve) => setTimeout(resolve, 1));
      } else if (rows === 1001) {
        yield [];
      }
    }
    this.progress = 100;
    this.rowCount = rows;
    if (chunk.length) {
      this.updateRowStart(chunk);
      this.rows.push(...chunk);
      await this.processChunk(chunk);
      return chunk;
    }
  }

  init() {
    return this.initPromise || (this.initPromise = this.initCore());
  }

  async initCore() {
    let step = await this.it.next();
    if (step.done) {
      this.finished = true;
    } else {
      step = await this.it.next();
      if (step.done) {
        this.finished = true;
      } else {
        this.paused = true;
      }
    }
  }

  async loadAll() {
    await this.init();
    if (this.paused) {
      await (this.loadPromise || (this.loadPromise = this.loadAllCore()));
    }
  }

  async loadAllCore() {
    this.working = true;
    this.paused = false;
    try {
      for await (let chunk of this.it) {
      }
    } finally {
      this.working = false;
    }
    this.finished = true;
  }
}

export abstract class WorksheetProcessor extends WorksheetReader {
  constructor(public reader: WorksheetReader, $el: HTMLElement) {
    super(null, $el);
    this.name = this.reader.name;
  }

  chunk: SheetRow[];
  _rows = 0;

  push(row: SheetRow) {
    if (!this.chunk) this.chunk = [];
    while (row.values.length > this.cols.length) {
      const curVal = this.getCellValue(row.values[this.cols.length]);
      const tw = this.$el
        ? Math.ceil((getTextWidth(curVal, window.getComputedStyle(this.$el).font) + 9) / 10) * 10
        : curVal.length * 16;
      const width = Math.min(300, Math.max(100, tw));

      this.cols.push({
        width,
        textLength: (curVal.length / tw) * width * 1.2,
      });
    }
    if (this._rows < 20) {
      for (let col = 0; col < row.values.length; col++) {
        const curVal = this.getCellValue(row.values[col]);
        const c = this.cols[col];
        if (curVal.length > c.textLength) {
          const tw = this.$el
            ? Math.ceil((getTextWidth(curVal, window.getComputedStyle(this.$el).font) + 9) / 10) *
              10
            : curVal.length * 16;
          const width = Math.min(300, Math.max(100, tw));
          if (c.width !== width) c.width = width;
          const textLength = (curVal.length / tw) * width * 1.2;
          if (textLength !== c.textLength) c.textLength = textLength;
        }
      }
    }
    row.row = this._rows++;
    this.chunk.push(row);
  }

  abstract processRow(row: SheetRow);

  async *readWorkbook(wb: any) {
    await this.reader.init();
    let i = 0;
    this.chunk = [];
    for (i = 0; i < this.reader.rows.length; i) {
      this.processRow(this.reader.rows[i++]);
      if (i % 1000 === 0) {
        const chunk = this.chunk;
        this.chunk = [];
        this.updateRowStart(chunk);
        this.rows.push(...chunk);
        await this.processChunk(chunk);
        yield chunk;
      }
    }
    if (this.chunk.length) {
      const chunk = this.chunk;
      this.chunk = [];
      this.updateRowStart(chunk);
      this.rows.push(...chunk);
      await this.processChunk(chunk);
      yield chunk;
    }
    for await (const ch of this.reader.it) {
      for (; i < this.reader.rows.length; i) {
        this.processRow(this.reader.rows[i++]);
        if (i % 1000 === 0) {
          const chunk = this.chunk;
          this.chunk = [];
          this.updateRowStart(chunk);
          this.rows.push(...chunk);
          await this.processChunk(chunk);
          yield chunk;
        }
      }
    }
    if (this.chunk.length) {
      this.updateRowStart(this.chunk);
      this.rows.push(...this.chunk);
      await this.processChunk(this.chunk);
    }
    return this.chunk;
  }
}

export interface ImportIssue {
  id?: string;
  message: LangType;
  row?: SheetRow;
  col?: number;
  fix?: () => Promise<boolean>;
  done?: boolean;
  error?: string;
}

@Component
export class ImportContext extends Vue {
  fields: EditorField[] = [];
  mconfigs: ImportConfig[] = [];
  get configs() {
    return this.mconfigs as readonly ImportConfig[];
  }
  dict: { [key: string]: ImportConfig } = {};

  addConfig(config: ImportConfig) {
    this.mconfigs.push(config);
    this.dict[config.field.fullPath] = config;
    return config;
  }

  removeConfig(field: ImportField | ImportConfig | number) {
    const idx =
      typeof field === "number"
        ? field
        : this.configs.findIndex((it) => it.field === field || it === field);
    if (idx !== -1) {
      const config = this.mconfigs[idx];
      this.mconfigs.splice(idx, 1);
      delete this.dict[config.field.fullPath];
    }
  }

  get fileSources() {
    return this.mconfigs.filter((it) => it.field instanceof ImportAttachmentField);
  }

  chunkSize = 100;

  editParams: any = {};
  options: ImportOptions = {};
  mimportFields: ImportField[] = [];
  wb: WorksheetReader = null;
  config: EditorConfig;

  importResult: SheetRow[] = [];
  name: LangType = null;

  linked: ImportLinkedField = null;

  mrwb: WorksheetReader = null;
  get rwb() {
    return this.mrwb;
  }
  set rwb(v) {
    if (this.wb) {
      this.wb.removeChunkProcess(this.processChunk);
      this.wb = null;
    }
    this.mrwb = v;
    this.mconfigs = [];
    this.dict = {};
    this.prefillRowStart = -1;
    this.prefillRowCount = 0;
    if (this.options.importPreprocess) {
      this.wb = null;
      if (v) {
        (async () => {
          this.wb =
            (await this.$root.$schemas.importPreprocessors?.[this.options.importPreprocess]?.(
              this,
              v,
            )) ?? v;
          if (this.wb) {
            this.wb.addChunkProcess(this.processChunk);
            this.updatePrefill();
          }
        })();
      }
    } else {
      this.wb = v;
    }
    if (this.wb) {
      this.wb.addChunkProcess(this.processChunk);
      this.updatePrefill();
    }
  }

  init(config: EditorConfig) {
    this.config = config;
    this.name = { $t: config.name };
    let fields = this.config.fields;
    this.fields = this.$root.$schemas.sortFields(fields, false);
    this.options = this.config.importOptions || {};
    this.chunkSize = config?.importChunkSize ?? 100;

    if (this.config.linkedTable) {
      this.mimportFields.push(this.linked = new ImportLinkedField(this, config));
    }

    for (let field of this.fields) {
      const f = ImportField.create(this, field, null);
      if (!f) continue;
      this.mimportFields.push(f);
    }
  }

  get importFields() {
    const items: ImportField[] = [];
    function recursive(field: ImportField) {
      if (field.flatten) {
        field.expand();
        for (let item of field.items) {
          recursive(item);
        }
      } else {
        items.push(field);
      }
    }
    for (let field of this.mimportFields) {
      recursive(field);
    }
    return items;
  }

  reset() {
    this.wb = null;
  }

  prefillRowStart = -1;
  prefillRowCount = 0;
  prefillWorking = false;

  comments: Record<string, Comment> = {};

  async updatePrefill() {
    if (
      !this.wb.rows.length ||
      this.wb.rowStart === this.prefillRowStart ||
      (this.options.horizontal && this.prefillRowCount === this.wb.rows.length)
    )
      return;
    this.prefillWorking = true;
    try {
      console.log("update prefill");
      const colSize: number[] = [];
      if (this.options.horizontal) {
        for (let i = 0; i < this.wb.rows.length; i++) {
          const row = this.wb.rows[i];
          const textValue = this.wb.getCellValue(row.values[this.wb.rowStart]);
          if (!textValue) continue;
          const cur = this.configs.find((it) => it.source === i);

          if (cur) continue;
          const lines = textValue.split("\n");
          for (let line of lines) {
            for (let field of this.mimportFields) {
              const suggested = await field.suggest(this, i, line.trim());
              if (suggested) {
                this.addConfig(suggested);
                break;
              }
            }
          }
        }
        colSize.push(0);
      } else if (this.wb.rowStart !== -1) {
        const headerRow = this.wb.rows[this.wb.rowStart - 1]; //fix
        if (headerRow) {
          for (let i = 1; i < headerRow.values.length; i++) {
            const cellRef = colCache.n2l(i) + this.wb.rowStart;
            const comment = this.comments?.[cellRef];
            if (comment?.richText?.length) {
              for (let item of comment.richText) {
                const text = item.text?.trim?.();
                if (!text) continue;
                if (text === "ignored") {
                  continue;
                } else if (text.includes(":")) {
                  const lines = text.split("\n");
                  for (let line of lines) {
                    const text = line.trim();
                    if (!text) continue;
                    let path = text.substring(0, text.indexOf(":"));
                    const paramsJSON = text.substring(path.length + 1);
                    if (!paramsJSON) {
                      console.warn(text);
                      continue;
                    }
                    let params: any = {};
                    try {
                      params = JSON.parse(paramsJSON);
                    } catch (e) {
                      console.warn(e);
                      continue;
                    }

                    let source = i;
                    if (path.startsWith("@")) {
                      source = -1;
                      path = path.substring(1);
                    }

                    const field = await this.resolveAsync(path);
                    if (!field) {
                      console.warn(text);
                      continue;
                    }

                    field.activate();

                    let config = this.configs.find((it) => it.field === field);
                    if (!config) {
                      this.addConfig(
                        (config = new ImportConfig({
                          source,
                          field,
                        })),
                      );
                    }

                    if (params?.index !== undefined) config.index = params.index;
                    delete params.index;
                    config.props = params;
                    colSize.push(i);
                  }
                }
              }
            } else {
              const textValue = this.wb.getCellValue(headerRow.values[i]);
              if (!textValue) continue;
              const cur = this.configs.find((it) => it.source === i);

              if (cur) continue;
              const lines = textValue.split("\n");

              for (let line of lines) {
                for (let field of this.mimportFields) {
                  const suggested = await field.suggest(this, i, line.trim());
                  if (suggested) {
                    this.addConfig(suggested);
                    colSize.push(i);
                    break;
                  }
                }
              }
            }
          }
        }
      }
      for (let i of colSize) this.updateColSize(i);

      this.prefillRowStart = this.wb.rowStart;
      this.prefillRowCount = this.wb.rows.length;
    } finally {
      this.prefillWorking = false;
    }
  }

  async processChunk(chunk: SheetRow[]) {
    await this.updatePrefill();
  }

  getActiveItem(idx: number) {
    return this.configs.filter((it) => it.source === idx);
  }

  getFullName(item: ImportField) {
    const parts = [];
    do {
      parts.unshift((this as any).$td(item.name));
      item = item.parent;
    } while (item);
    return parts.join("/");
  }

  updateColSize(idx: number) {
    const cols = this.wb.cols;
    let mw: number;
    let empty = true;
    if (this.options.horizontal) {
      mw = _.max(
        _.map(
          _.groupBy(
            this.configs.filter((it) => it.source !== undefined),
            (it) => it.source,
          ),
          (items) => {
            return _.sumBy(items, (col) => {
              empty = false;
              const name = this.getFullName(col.field);
              return (
                Math.ceil(
                  (getTextWidth(name, window.getComputedStyle(this.wb.$el).font) + 9) / 10,
                ) *
                  10 +
                40
              );
            });
          },
        ),
      );
      idx = 0;
    } else {
      const cols = this.getActiveItem(idx);
      mw = _.sumBy(cols, (col) => {
        empty = false;
        const name = this.getFullName(col.field);
        return (
          Math.ceil((getTextWidth(name, window.getComputedStyle(this.wb.$el).font) + 9) / 10) * 10 +
          40
        );
      });
    }
    cols[idx].owidth = cols[idx].owidth || cols[idx].width;
    cols[idx].width = Math.max(!empty ? 200 : 0, cols[idx].owidth, mw + 120);
  }

  issueDict: Record<string, ImportIssue> = {};
  issues: ImportIssue[] = [];

  addIssue(issue: ImportIssue) {
    if (issue.id && this.issueDict[issue.id]) return;
    issue.done = false;
    issue.error = null;
    if (issue.id) {
      this.issueDict[issue.id] = issue;
    } else {
      issue.id = uuid();
    }
    this.issues.push(issue);
  }

  clearIssues() {
    this.issueDict = {};
    this.issues = [];
  }

  resolve(path: string): ImportField {
    let cur = null;
    let items = this.mimportFields || [];
    let spath = "";
    let ipath = path;
    while (ipath) {
      const part = ipath.indexOf("/") !== -1 ? ipath.substring(0, ipath.indexOf("/")) : ipath;
      const cpath = spath ? spath + "/" + part : part;
      const next = items.find(
        (it) => it.fullPath === cpath || path === it.fullPath || path.startsWith(it.fullPath + "/"),
      );
      if (!next) return null;
      cur = next;
      items = next.items;
      spath = cpath;
      ipath = path.substring(next.fullPath.length + 1);
    }

    return cur;
  }

  async resolveAsync(path: string): Promise<ImportField> {
    let cur = null;
    let items = this.mimportFields;
    let spath = "";
    let ipath = path;
    while (ipath) {
      const part = ipath.indexOf("/") !== -1 ? ipath.substring(0, ipath.indexOf("/")) : ipath;
      const cpath = spath ? spath + "/" + part : part;
      const next = items.find(
        (it) => it.fullPath === cpath || path === it.fullPath || path.startsWith(it.fullPath + "/"),
      );
      if (!next) return null;
      cur = next;
      items = await next.preload();
      spath = cpath;
      ipath = path.substring(next.fullPath.length + 1);
    }

    return cur;
  }
}
