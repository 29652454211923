<template>
    <div>
        <v-snackbar top v-model="error">
            {{ errorMessage }}
            <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>
        <nuxt></nuxt>
    </div>
</template>
<script>
export default {
    created() {
        this.$store.commit("SET_TITLE", "pages.users.delAc");
    },
    computed: {
        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },
        errorMessage() {
            return this.$store.state.error;
        },
    },
};
</script>
