var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex w-full" },
    [
      _vm.component
        ? _vm._l(_vm.source.items, function (item) {
            return _c(_vm.component, {
              key: item[_vm.itemKey],
              tag: "component",
              style: `flex-basis: ${100 / _vm.columns}%`,
              attrs: { source: item },
            })
          })
        : _vm._l(_vm.source.items, function (item) {
            return _c(
              "div",
              {
                key: item[_vm.itemKey],
                style: `flex-basis: ${100 / _vm.columns}%`,
              },
              [_vm._t("item", null, { item: item })],
              2
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }