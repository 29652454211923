var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "menu-list d-flex h-full",
        class: [_vm.wrap ? "flex-row flex-wrap" : "flex-col"],
      },
      [
        _vm._t("default"),
        _vm.$scopedSlots.bottom
          ? _c("div", { staticClass: "flex-grow !p-0" })
          : _vm._e(),
        _vm._t("bottom"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }