var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "space-x-3 d-flex items-center light list-item min-h-10",
      attrs: { role: "button" },
      on: { click: _vm.click },
    },
    [
      _vm.icon
        ? _c(
            "div",
            [
              _c(
                "v-icon",
                { staticClass: "custom-icon", class: _vm.iconClass },
                [_vm._v(_vm._s(_vm.icon))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
      _vm.text
        ? _c("div", { class: _vm.labelClass }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e(),
      _vm._t("grow", function () {
        return [_c("div", { staticClass: "p-0 flex-grow" })]
      }),
      _vm._t("append"),
      _vm.appendText
        ? _c("div", { staticClass: "text-grey600" }, [
            _vm._v(_vm._s(_vm.appendText)),
          ])
        : _vm._e(),
      _vm.appendIcon
        ? _c(
            "v-icon",
            {
              staticClass: "custom-icon",
              class: _vm.iconClass,
              on: {
                click: function ($event) {
                  return _vm.$emit("click:append", $event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.appendIcon))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }