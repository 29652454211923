var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.showIndex
        ? _c("v-chip", [_vm._v(_vm._s(_vm.sortIndex + 1))])
        : _vm._e(),
      _vm.descending !== null
        ? _c(
            "v-icon",
            { class: ["sort-arrow", { descending: _vm.descending }] },
            [_vm._v("arrow_upward")]
          )
        : _vm._e(),
      _c("span", { staticClass: "headerText subtitle-2" }, [
        _vm._v(_vm._s(_vm.$t(_vm.header.text))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }