var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "vue-dropzone dropzone dz-clickable",
          class: { "dz-drag-hover": _vm.dragging },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.addFile()
            },
            dragenter: _vm.dragenter,
            dragover: _vm.dragover,
            dragleave: _vm.dragleave,
            drop: _vm.drop,
          },
        },
        [
          !_vm.files.length
            ? _c("div", { staticClass: "dz-default dz-message" }, [
                _c("span", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "uploader.drag",
                      expression: "'uploader.drag'",
                    },
                  ],
                }),
              ])
            : _vm._e(),
          _vm._l(_vm.files, function (file) {
            return _c(
              "div",
              {
                key: file.id,
                class: {
                  "dz-preview": true,
                  "dz-processing": file.processing,
                  "dz-file-preview":
                    !file.thumbnail || _vm.editingItem === file,
                  "dz-image-preview":
                    !!file.thumbnail && _vm.editingItem !== file,
                  "dz-success": file.success,
                  "dz-complete": file.complete,
                  "dz-error": file.error,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.selectFile(file)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dz-image",
                    staticStyle: { width: "200px", height: "200px" },
                  },
                  [
                    _c("img", {
                      attrs: { "data-dz-thumbnail": "", src: _vm.$thumb(file) },
                    }),
                  ]
                ),
                _c("div", { staticClass: "dz-details" }, [
                  _c("div", { staticClass: "dz-size" }, [
                    _c("span", { attrs: { "data-dz-size": "" } }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm._f("smartSize")(file.size, true, false))
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("smartSize")(file.size, false, true))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "dz-filename" }, [
                    _vm.editingItem === file
                      ? _c("input", {
                          directives: [
                            { name: "focus", rawName: "v-focus" },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: file.name,
                              expression: "file.name",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: file.name },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.saveName(file)
                            },
                            blur: function ($event) {
                              return _vm.saveName(file)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(file, "name", $event.target.value)
                            },
                          },
                        })
                      : _c(
                          "span",
                          {
                            attrs: { "data-dz-name": "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                _vm.editingItem = file
                              },
                            },
                          },
                          [_vm._v(_vm._s(file.name))]
                        ),
                  ]),
                ]),
                _c("div", { staticClass: "dz-progress" }, [
                  _c("span", {
                    staticClass: "dz-upload",
                    style: { width: file.progress * 100 + "%" },
                    attrs: { "data-dz-uploadprogress": "" },
                  }),
                ]),
                _c("div", { staticClass: "dz-error-message" }, [
                  _c("span", { attrs: { "data-dz-errormessage": "" } }, [
                    _vm._v(_vm._s(file.error)),
                  ]),
                ]),
                _vm._m(0, true),
                _vm._m(1, true),
                _c(
                  "div",
                  { staticClass: "dz-remove", attrs: { "data-dz-remove": "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.deleteFile(file)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("delete")])],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { href: `${_vm.url}/${file.id}/download` },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return false.apply(null, arguments)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("get_app")])],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            transition: "v-dialog-bottom-transition",
            overlay: true,
          },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "icon", light: "" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.model = false
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dz-success-mark" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("done")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dz-error-mark" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("error")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }