
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import LRUCache from 'lru-cache'
import _ from 'lodash'
import { getID, checkID } from '@feathers-client'

@Component
export default class AsyncPopulate extends Vue {
    @Prop()
    path : string

    @Prop()
    value : string | string[]

    @Prop(Boolean)
    multiple: boolean

    @Prop()
    args : any

    @Prop(Boolean)
    allowEmpty: boolean

    @Prop(Boolean)
    local: boolean

    @Prop()
    maybeValue: any | any[]

    @Prop({ default: '_id', type: String })
    itemKey: string

    @Prop(Boolean)
    hideEmpty: boolean

    @Prop(Boolean)
    keepPrevious: boolean

    cachedValue : any | any[] = null;
    loading = false;

    get cacheKey() {
        return this.path + JSON.stringify(this.args || null) + this.itemKey;
    }

    get cache() {
        const ref = this.local ? (this as any).$parent : (this as any).$root;
        let cache : {
            [key : string]: CacheLoader
        } = (ref._asyncPopulates || (ref._asyncPopulates = {}));
        let loader : CacheLoader = cache[this.cacheKey];
        if(!loader) {
            Vue.set(cache, this.cacheKey, loader = new CacheLoader({
                parent: this.$root,
                key: this.cacheKey,
                propsData: {
                    path: this.path,
                    args: this.args,
                    itemKey: this.itemKey,
                }
            }))
        }
        return loader
    }

    beforeMount() {
        this.reload();
    }

    get getID() {
        return this.itemKey ? new Function('item', `return item ? typeof item === 'string' ? item : item.${this.itemKey} : null`) : getID
    }

    get checkID() {
        return this.itemKey === '_id' ? checkID : ((a, b) => this.getID(a) === this.getID(b));
    }
    
    dirty = false;

    @Watch('value')
    reload() {
        this.queueReload();
    }

    reloadInner() {
        if(this.multiple) {
            const v = this.value;
            if(v && Array.isArray(v)) {
                this.loading = false;
                const mv = Array.isArray(this.maybeValue) ? this.maybeValue : null;
                this.cachedValue = v.map(v => {
                    if(mv) {
                        const r = mv.find(it => this.checkID(it, v));
                        if(r !== undefined) return r;
                    }
                    return this.cache.query(v, () => {
                        this.loading = true
                    }, this.queueReload)
                }).filter(it => !!it);
                this.$emit('cachedValue', this.cachedValue);
            } else {
                if(!this.keepPrevious) this.cachedValue = [];
                this.$emit('cachedValue', []);
            }
        } else if(this.value) {
            if(typeof this.value === 'object') {
                this.cachedValue = this.value;
                this.$emit('cachedValue', this.cachedValue);
            } else {
                if(this.maybeValue && this.checkID(this.maybeValue, this.value)) {
                    this.cachedValue = this.maybeValue;
                    this.$emit('cachedValue', this.cachedValue);
                } else {
                    if(!this.keepPrevious) this.cachedValue = null;
                    const newValue = this.cache.query(this.value, () => {
                        this.loading = true;
                    }, (v) => {
                        this.cachedValue = v || null;
                        this.loading = false;
                        this.$emit('cachedValue', this.cachedValue);
                    }) || null
                    if(!this.keepPrevious || newValue) this.cachedValue = newValue;
                    this.$emit('cachedValue', this.cachedValue);
                }
            }
        } else {
            this.cachedValue = null;
            this.$emit('cachedValue', null);
        }
    }

    reloadTimer : any = null;
    queueReload() {
        if(this.reloadTimer) {
            clearTimeout(this.reloadTimer);
            this.reloadTimer = null;
        }
        this.reloadTimer = setTimeout(() => {
            this.reloadInner();
        }, 50);
    }
}

@Component
class CacheLoader extends Vue {
    lruCache : LRUCache<string, any>;
    queue : {
        key: string,
        resolve: (v : any) => void,
        reject: (e : any) => void,
    }[];

    @Prop()
    path : string

    @Prop()
    args : any

    @Prop({ default: '_id', type: String })
    itemKey: string

    beforeCreate() {
        this.lruCache = new LRUCache();
        this.queue = [];
    }

    query(id : string, beginLoad: () => void, endLoad: (v? : any) => void) {
        const v = this.lruCache.get(id);
        if(v) {
            if(v instanceof Promise) {
                beginLoad();
                v.then(endLoad, e => {
                    endLoad();
                })
            } else {
                return v;
            }
        } else {
            const needSchedule = this.queue.length === 0;
            const p = new Promise<void>((resolve, reject) => {
                this.queue.push({
                    key: id,
                    resolve,
                    reject,
                })
            });
            this.lruCache.set(id, p);
            beginLoad();
            p.then((v) => {
                this.lruCache.set(id, v);
                endLoad(v);
            }, e => {
                endLoad();
            })

            if(needSchedule) {
                this.scheduleQueue();
            }
        }
    }

    async scheduleQueue() {
        await new Promise(resolve => setTimeout(resolve, 0));
        const q = this.queue.slice();
        this.queue.splice(0, this.queue.length);

        for(let chunk of _.chunk(q, 100)) {
            try {
                const table = await this.$feathers.service(this.path).find({
                    query: {
                        [this.itemKey]: {
                            $in: chunk.map(v => v.key),
                        },
                        $limit: 100,
                        ...(this.args || {}),
                    }
                }) as any;
                const items = table.data ? table.data : table;
                if(Array.isArray(items)) {
                    const itemToDict : {
                        [key : string]: any
                    } = {};
                    for(let item of items) {
                        itemToDict[item[this.itemKey]] = item;
                    }

                    for(let item of chunk) {
                        const v = itemToDict[item.key];
                        if(v) {
                            item.resolve(v)
                        } else {
                            item.reject(new Error("Not Found"))
                        }
                    }
                }
            } catch(e) {
                console.warn(e);
                for(let item of chunk) {
                    item.reject(e);
                }
            }
        }
      
    }
}

