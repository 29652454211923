
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { 
    DataTableDef, 
    DataTableHeader, 
    DataTablePagination, 
    DataTablePaginated,
} from '../index'
import _ from 'lodash'
import uuid from 'uuid/v4'
import QueryProvider from "./QueryProvider";
import HeaderProvider from './HeaderProvider'
import qs from 'qs'

function VueDeepSet(item : any, path : string | string[], value : any) {
    if(typeof path === 'string') path = path.split('.');
    const last = path.pop();
    _.each(path, field => {
        if(!item[field]) {
            Vue.set(item, field, {});
        }
        item = item[field];
    })
    Vue.set(item, last, value);
}

@Component
export default class StateProvider extends mixins(QueryProvider, HeaderProvider) {
    @Prop()
    data : DataTableDef;

    @Prop()
    itemKey : string

    @Prop()
    items : any[]

    @Prop()
    headers : DataTableHeader[];

    @Prop()
    value : any;

    @Prop()
    exportFilter : any;

    @Prop({ type: Boolean, default: false })
    cursor : boolean;

    mdata: DataTablePaginated = null;

    fetchValue: any = null;
    currentDeltaTotal = 0;

    get total() {
        return this.data.static || !this.data.path ? 
            this.mitems.length : !this.source ? 0
            : this.cursor ? 999 : this.source.total + this.currentDeltaTotal
    }

    get source () {
        return this.data ? this.mdata || this.data.data : null;
    }

    get mitems () : any[] {
        const items = this.items || (this.data.subpath && _.get(this.mvalue, this.data.subpath)) || (this.source && this.source.data) || [];
        return (this.data.static && this.data.filter) ? items.filter(it => _.every(this.data.filter, (v, k) => it[k] === v || k.startsWith('$'))) : items;
    }

    get mItemKey () {
        if (this.itemKey) return this.itemKey;
        if (this.data.subpath) {
            return this.data.path ? '_id' : 'id';
        } else if (this.data.path) {
            return '_id';
        }
        return 'id';
    }

    setPropHandler(item, assign) {
        if(!item || !assign) return;
        _.each(assign, (v, k) => {
            Vue.set(item, k, v);
        });
    }

    get mvalue () {
        return this.value || this.fetchValue;
    }

    appendItem(item : any) {
        const service = this.data.path && (this as any).mfeathers.service(this.data.path);
        if(!service) {
            this.mitems.push(item);
        } else {
            const oldItem = this.mitems.find(it => it[this.mItemKey] === item[this.mItemKey]);
            if (oldItem) _.assign(oldItem, item);
            else this.mitems.push(item);
            this.currentDeltaTotal++;
        }
    }

    async save(item : any, origin? : any) {
        try {
            const editingId = _.get(item, this.mItemKey);
            let result;

            const service = this.data.path && (this as any).mfeathers.service(this.data.path);
            if(!service) {
                if (editingId) {
                    this.setPropHandler(origin, item);
                } else {
                    VueDeepSet(item, this.mItemKey, uuid());
                    this.mitems.push(item);
                }
            } else {
                if (this.data.subpath) {
                    result = await service.patch(this.data.id, {
                        [editingId ? '$set' : '$push']: {
                            [this.data.subpath + (editingId ? '.$' : '')]: item
                        }
                    }, {
                        query: editingId ? {
                            [this.data.subpath + '.' + this.itemKey]: this.itemKey,
                        } : {},
                    });
                    this.setPropHandler(this.mvalue, result);
                } else {
                    if (editingId) {
                        result = await service.patch(editingId,
                            item,
                            {
                                query: {
                                    ...this.data.filter || {},
                                    ...(this.data.populate ? { $populate: this.data.populate } : {})
                                }
                            }
                        );
                        _.assign(item, result);
                        this.setPropHandler(origin, item);
                    } else {
                        result = <any>await service.create(item);
                        let results = result instanceof Array ? result : [result];
                        if (this.data.populate) {
                            const resp = await this.runQuery(this.data, {}, {
                                ...this.data.filter,
                                [this.mItemKey]: {
                                    $in: _.map(results, it => _.get(it, this.mItemKey)),
                                }
                            });
                            results = resp.data;
                        }
                        _.each(results, item => {
                            const oldItem = this.mitems.find(it => it[this.mItemKey] === item[this.mItemKey]);
                            if (oldItem) _.assign(oldItem, item);
                            else this.mitems.push(item);
                            this.currentDeltaTotal++;
                        })
                    }
                }
            }
            this.$store.commit('SET_SUCCESS', this.$t('basic.saveSuccess'));
            return result;
        } catch(e : any) {
            this.$store.commit('SET_ERROR', e.message);
        }
    }


}