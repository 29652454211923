import type {
  ImportContext,
  ImportField,
  WorksheetProcessor,
  WorksheetReader,
} from "../importCommon";
import type {
  SchemaDefParamsService,
  SchemaDefJson,
  SchemaDefParams,
  SchemaFieldJson,
  SchemaTypeFullJson,
  EditorAddonField,
  EditorConfig as DBEditorConfig,
} from "@db/schema";
import { ImportConfig } from "../importField";
import type { LangArrType } from "@feathers-client/i18n";

export interface EditorViewSetting {
  headers?: string[];
  sort?: string[];
  sortDesc?: boolean[];
  searchPin?: string[];
}

export interface EditorField {
  importer?: (context: ImportContext, field: EditorField, parent?: ImportField) => ImportField;
  customType?: CustomType;
  component?: string;
  props?: Record<string, any>;
  propsFunc?: (scope: any) => any;
  name?: string | LangArrType;
  // path for root object in slot props (default item)
  rootPath?: string;
  // path in root object
  path?: string;
  nameField?: string;
  schema?: SchemaFieldJson;
  inner?: EditorField[];
  default?: EditorField[];

  _inner?: EditorField[];
  _default?: EditorField[];

  cond?: (scope: any) => boolean;
  defaultValue?: any;
  type: string;
  optional?: boolean;
  sort?: string;
  gp?: string;
  group?: EditorGroupOptions;
  displayPath?: string;
  header?: DataTableHeader;
}

export interface SearchField {
  name: string | LangArrType;
  path: string;
  edit: EditorField;
  color: string;
  conds: string[];
  cond: string;
  value1: any;
  value2: any;
  header: DataTableHeader;
}

export interface NormalizedRole {
  role: string;
  read?: boolean;
  write?: boolean;
}

export type RoleDef = string | NormalizedRole;

export interface ImportLinkField {
  from: string;
  to: string;
  params?: Partial<ImportConfig>;
}

export interface ImportOptions {
  horizontal?: boolean;
  importPreprocess?: string;
  links?: {
    path: string;
    mode: "single";
    fields?: ImportLinkField[];
  }[];
}

export interface GUIHeader {
  title: string;
  action: string;
  href: string;
  items?: GUIHeader[];
  gpKey?: string;
  gpIcon?: string;
  order: number;
}
[];

export interface DataTablePopObj {
  path: string;
  populate?: DataTablePop[];
}
export type DataTablePop = string | DataTablePopObj;
export interface DataTableHeader {
  computed?: boolean;

  // when sorting header path (ui) != data header path
  headerValue?: string | string[];
  linkValue?: string | string[];
  value?: string;

  source?: string | ((item: any) => string);

  noLink?: boolean;
  linkSource?: string | ((item: any) => string);
  trailingSlash?: boolean;
  direct?: boolean;

  unique?: boolean;
  limit?: number;

  path?: string;
  paths?: {
    path: string;
    format?: string | ((self: Vue, value: any, item: any) => string);
  }[];

  objectOnly?: boolean;
  format?: string | ((self: Vue, value: any, item: any) => string);

  itemKey?: string;

  filter?: any;
  populate?: DataTablePop[];
  select?: string[];

  type?: string;
  slot?: string;

  text?: string;
  hideDelete?: boolean;

  multiple?: boolean;
  sortable?: boolean;
  sortField?: string;
  action?: boolean;
  enumList?: Record<string, string>;
  hideEmpty?: boolean;
  inner?: DataTableHeader[];
}

export interface EditorFieldOptions {
  sizes?: string[];
  gp?: string;
  type?: string;
  hidden?: boolean;
  search?: boolean;
  optional?: boolean;
  sort?: string;
  props?: Record<string, any>;
  linked?: EditorFieldOptions;
  group?: EditorGroupOptions;
  cond?: string;
  name?: string;
  format?: string;
  headerValue?: string;
  objectFormat?: string;
  headerUnique?: boolean;
  headerLimit?: number;
  hideEmpty?: boolean;

  nameFields?: string | string[];
}

export interface EditorGroupOptions {
  props?: any;
  name?: string;
  preview?: boolean;
  hasInnerGroup?: boolean;
}

export interface CustomType {
  component?: string | (() => Promise<any>);
  importer?: (context: ImportContext, field: EditorField, parent?: ImportField) => ImportField;
  format?: string | ((self: Vue, value: any, item: any) => string);
  props?: Record<string, any>;
}

export type AclHandler = (
  context: Vue,
  action: "create" | "patch" | "remove" | "clone",
  item?: any,
) => boolean;

export default {};
