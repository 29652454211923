var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "grid grid-cols-[auto,auto,auto,auto] items-center content-center justify-center",
    },
    [
      _vm._l(_vm.btns, function (btn, idx) {
        return [
          btn !== ""
            ? _c(
                "div",
                {
                  key: idx,
                  staticClass:
                    "w-[min(4.5rem,5vw)] h-[min(4.5rem,5vw)] m--[min(0.5rem,1vw)] px-[min(0.875rem,1vw)] py-[min(0.875rem,1vw)] <md:( w-[min(4.5rem,12vw)] h-[min(4.5rem,12vw)] m--[min(0.5rem,3vw)] px-[min(0.875rem,2vw)] py-[min(0.875rem,2vw)] ) rounded-[min(0.75rem,2vw)] border-1 border-grey500 bg-grey500 !text-white cursor-pointer font-bold text-slg drop-shadow",
                  class: { disabled: !_vm.canAction(btn) },
                  attrs: { role: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.action(btn)
                    },
                  },
                },
                [
                  typeof btn === "number" ||
                  (!isNaN(+(btn + "0")) && btn !== "-")
                    ? _c("span", [_vm._v(" " + _vm._s(btn) + " ")])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "w-full h-full d-flex items-center justify-center text-red100",
                        },
                        [
                          btn[0] === "$"
                            ? _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "custom-icon w-6 h-6 !text-white",
                                },
                                [_vm._v(_vm._s(btn))]
                              )
                            : _c("span", [_vm._v(_vm._s(btn))]),
                        ],
                        1
                      ),
                ]
              )
            : _c("div", { key: idx }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }