

import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import type {
    SearchField,
    EditorConfig,
    EditorViewSetting,
} from "./plugin";
import _ from "lodash";
import components from './components'

export function regEscape(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

@Component({
    components: components,
})
export default class EditorSearchMenu extends Vue {

    @Prop()
    config: EditorConfig

    @Prop()
    filter: any

    @VModel()
    query: any

    @Prop()
    context: any

    @PropSync('showSearchMenu')
    showSearchMenuSync: boolean

    @PropSync('serializedState')
    serializedStateSync: any

    @Prop()
    setting: EditorViewSetting;

    @Prop()
    dateCutOffTime: string

    activeState : any = {};

    created() {
        this.updateSearch = _.throttle(this.updateSearchCore, 1000);
        this.keyword = this.serializedStateSync?.keyword;
        if(this.serializedStateSync?.fields) {
            for(let f of this.serializedStateSync?.fields) {
                const cur = this.config.searchFields.find(p => p.path === f.path);
                cur.cond = f.cond;
                cur.value1 = f.value1;
                cur.value2 = f.value2;
            }
        }
    }

    set currentSearch(newSearch: (SearchField | string)[]) {
        const stringSearch = _.findLast(
            newSearch,
            (it) => typeof it === "string"
        );
        if (stringSearch) this.keyword = <string>stringSearch;
        else this.keyword = "";
        const objSearch: SearchField[] = <any>(
            newSearch.filter((it) => typeof it === "object")
        );
        for (let current of this.config.searchFields) {
            if (
                !current.value1 ||
                (Array.isArray(current.value1) && !current.value1.length)
            )
                continue;
            const p = objSearch.find((it) => it.path === current.path);
            if (!p) {
                current.value1 = null;
                current.value2 = null;
            }
        }
        this.showSearchMenuSync = false;
    }

    @Watch("currentSearch")
    async onSearchChanged() {
        await Vue.nextTick();
        const view: any = this.$refs.searchMenu;
        if (view) {
            view.updateDimensions();
        }
    }

    keyword = "";

    @Watch("config.searchFields", { deep: true })
    @Watch("keyword")
    @Watch("filter")
    onSearchCommit() {
        if (!this.config) return;
        this.updateSearch();
    }

    updateSearchCore() {
        const query = _.merge({}, this.config.filter, this.filter);
        for (let field of this.config.searchFields.filter(
            (it) =>
                it.value1 !== null &&
                it.value1 !== "" &&
                !(Array.isArray(it.value1) && !it.value1.length)
        )) {
            let val;
            switch (field.cond) {
                case "contains":
                    val = {
                        $regex: regEscape(field.value1.trim()),
                        $options: "ig",
                    };
                    break;
                case "notContains":
                    val = {
                        $not: {
                            $regex: regEscape(field.value1.trim()),
                            $options: "ig",
                        },
                    };
                    break;
                case "inRange":
                    val = { $gte: field.value1, $lte: field.value2 };
                    break;
                case "eq":
                    val = field.value1;
                    break;
                default:
                    val = { ["$" + field.cond]: field.value1 };
                    break;
            }
            query[field.path] = val;
        }
        if (
            this.keyword &&
            (this.config.textFields.length ||
                this.config.translateTextFields.length)
        ) {
            const q = {
                $regex: regEscape(this.keyword.trim()),
                $options: "ig",
            };
            query.$or = _.map(this.config.textFields, (f) => ({
                [f]: q,
            }));
            query.$or.push(
                ..._.map(this.config.translateTextFields, (f) => ({
                    [f + ".value"]: q,
                }))
            );
        }
        this.query = {
            ...this.config.filter,
            ...query,
        };
        this.serializedStateSync = {
            keyword: this.keyword,
            fields: this.config.searchFields.map(f => ({
                path: f.path,
                cond: f.cond,
                value1: f.value1,
                value2: f.value2,
            })).filter(it => it.cond && it.value1)
        }
    }

    updateSearch: () => void;

    get searchFields() {
        return this.setting?.searchPin ?? this.config.searchPin ?? [];
    }

    get pinSearchFields() {
        const fields = this.config?.searchFields || [];
        return this.searchFields.map(f => fields.find(it => it.path === f)).filter(it => !!it);
    }

    isSearchActive(it: SearchField) {
        return it.value1 !== null &&
            it.value1 !== "" &&
            !(Array.isArray(it.value1) && !it.value1.length)
    }

    get currentSearch() {
        return [
            ...this.pinSearchFields,
            ...(this.config?.searchFields?.filter(
                (it) =>
                    !this.searchFields.includes(it.path) && this.isSearchActive(it)
            ) || []),
            ...(this.keyword ? [this.keyword] : []),
        ];
    }

    toggleCond(item: SearchField) {
        const idx = item.conds.indexOf(item.cond);
        item.cond = item.conds[(idx + 1) % item.conds.length];
    }

    clearCond(item: string | SearchField) {
        if (typeof item === "string") {
            this.keyword = "";
        } else {
            item.value1 = null;
            item.value2 = null;
        }
    }

}

