export { default as AsyncPopulate } from '../../dep/feathers-client/components/AsyncPopulate.vue'
export { default as BackToTopBtn } from '../../dep/feathers-client/components/BackToTopBtn.vue'
export { default as BoxsAppContainer } from '../../dep/feathers-client/components/BoxsAppContainer.vue'
export { default as DialogHost } from '../../dep/feathers-client/components/DialogHost.vue'
export { default as FullscreenHost } from '../../dep/feathers-client/components/FullscreenHost.vue'
export { default as IdleDialog } from '../../dep/feathers-client/components/IdleDialog.vue'
export { default as ItemPicker } from '../../dep/feathers-client/components/ItemPicker.vue'
export { default as ListTree } from '../../dep/feathers-client/components/ListTree.vue'
export { default as MenuList } from '../../dep/feathers-client/components/MenuList.vue'
export { default as MenuListButton } from '../../dep/feathers-client/components/MenuListButton.vue'
export { default as MenuListCheckbox } from '../../dep/feathers-client/components/MenuListCheckbox.vue'
export { default as MenuListInput } from '../../dep/feathers-client/components/MenuListInput.vue'
export { default as MenuListItem } from '../../dep/feathers-client/components/MenuListItem.vue'
export { default as MenuListNum } from '../../dep/feathers-client/components/MenuListNum.vue'
export { default as MenuListPicker } from '../../dep/feathers-client/components/MenuListPicker.vue'
export { default as NestedRouter } from '../../dep/feathers-client/components/NestedRouter.vue'
export { default as NumPad } from '../../dep/feathers-client/components/NumPad.vue'
export { default as PerfectScrollbar } from '../../dep/feathers-client/components/PerfectScrollbar.vue'
export { default as ResizeSensor } from '../../dep/feathers-client/components/ResizeSensor.vue'
export { default as SlotRender } from '../../dep/feathers-client/components/SlotRender.vue'
export { default as StaticRender } from '../../dep/feathers-client/components/StaticRender.vue'
export { default as StatusHost } from '../../dep/feathers-client/components/StatusHost.vue'
export { default as SwipeAction } from '../../dep/feathers-client/components/SwipeAction.vue'
export { default as TabPicker } from '../../dep/feathers-client/components/TabPicker.vue'
export { default as TeleportMenu } from '../../dep/feathers-client/components/TeleportMenu.vue'
export { default as Uploader } from '../../dep/feathers-client/components/Uploader.vue'
export { default as Highlighter } from '../../dep/feathers-client/components/highlighter.vue'
export { default as BoxsNavBarItem } from '../../dep/feathers-client/components/BoxsNavBar/Item.vue'
export { default as BoxsNavBar } from '../../dep/feathers-client/components/BoxsNavBar/index.vue'
export { default as BBtn } from '../../components/BBtn.js'
export { default as BForm } from '../../components/BForm.js'
export { default as ConstantSelect } from '../../components/ConstantSelect.vue'
export { default as DatePicker } from '../../components/DatePicker.vue'
export { default as EditorWithPermission } from '../../components/EditorWithPermission.vue'
export { default as GeoJson } from '../../components/GeoJson.vue'
export { default as ImagePicker } from '../../components/ImagePicker.vue'
export { default as MediaLibrary } from '../../components/MediaLibrary.vue'
export { default as ObjectPicker } from '../../components/ObjectPicker.vue'
export { default as PerfectScrollBar } from '../../components/PerfectScrollBar.vue'
export { default as SimpleList } from '../../components/SimpleList.vue'
export { default as Formatter } from '../../components/formatter.js'
export { default as QuillEditor } from '../../components/quillEditor.vue'
export { default as ChartsFrequencyChart } from '../../components/charts/FrequencyChart.vue'
export { default as ChartsLineChart } from '../../components/charts/LineChart.vue'
export { default as ChartsPie } from '../../components/charts/Pie.vue'
export { default as ChartsReactive } from '../../components/charts/reactive.js'
export { default as DialogsConfirmDialog } from '../../components/dialogs/ConfirmDialog.vue'
export { default as EasymatchCategoryTag } from '../../components/easymatch/CategoryTag.vue'
export { default as EasymatchReadonlyFlat } from '../../components/easymatch/ReadonlyFlat.vue'
export { default as EasymatchVerifyChip } from '../../components/easymatch/VerifyChip.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
