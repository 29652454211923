
import { Vue, Component, Prop, PropSync, VModel } from "@feathers-client";
import { Watch } from "vue-property-decorator";

export interface NavItem {
  href?: string;
  title: string;
  action?: string;
  exact?: boolean;
  items?: NavItem[];
  disabled?: boolean;
}

@Component
export default class BoxsNavBar extends Vue {
  @Prop()
  items: any[];

  @VModel()
  inputValue: boolean;

  @Prop()
  temporary: boolean;

  mobile = false;

  mounted() {
    this.mobile = window.innerWidth < 1280;
    window.addEventListener("resize", this.onResize);
  }

  onResize() {
    this.mobile = window.innerWidth < 1280;
  }

  @Watch("$route")
  onRouteChange() {
    this.inputValue = false;
  }

  @Watch("realTemp")
  onTemporary() {
    if (!this.realTemp) {
      this.inputValue = false;
    }
  }

  get realDrawer() {
    return this.realTemp ? this.inputValue : true;
  }

  @PropSync("mini")
  miniSync: boolean;

  hovering = false;
  get miniReal() {
    return !this.realTemp && this.miniSync && !this.hovering;
  }

  get realTemp() {
    return this.mobile ? true : this.temporary;
  }

  get widthStyle() {
    return [`--nav-full-width: 14.75rem`, `--nav-mini-width: 5rem`].join(";");
  }

  beforeDestroy() {
    this.resetDrawer();
    window.removeEventListener("resize", this.onResize);
  }

  toggleDrawer() {
    if (!this.realTemp) {
      this.miniSync = !this.miniSync;
      this.resetDrawer();
    } else {
      this.inputValue = !this.inputValue;
      this.miniSync = false;
    }
  }

  hoverTimer = null;
  leavingTimer = null;

  mouseover() {
    if (this.leavingTimer) {
      clearTimeout(this.leavingTimer);
      this.leavingTimer = null;
    }
    if (this.hoverTimer) return;
    this.hoverTimer = setTimeout(() => {
      this.hovering = true;
      this.hoverTimer = null;
    }, 300);
  }

  mouseleave() {
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
      this.hoverTimer = null;
    }
    if (this.hovering && !this.leavingTimer) {
      this.leavingTimer = setTimeout(() => {
        this.hovering = false;
        this.leavingTimer = null;
      }, 300);
    }
  }

  resetDrawer() {
    if (this.leavingTimer) {
      clearTimeout(this.leavingTimer);
      this.leavingTimer = null;
    }
    if (this.hoverTimer) {
      clearTimeout(this.hoverTimer);
      this.hoverTimer = null;
    }
    this.hovering = false;
  }
}
