var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("editor-text-field", {
    attrs: {
      label: _vm.label,
      value: _vm.getTranslate(_vm.locale),
      multiLine: _vm.multiLine,
      editor: _vm.editor,
    },
    on: {
      input: function ($event) {
        return _vm.setTranslate(_vm.locale, $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "nameAppend",
        fn: function () {
          return [_c("div", { staticClass: "world w-3 h-3" })]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }