var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("AsyncPopulate", {
    attrs: { path: "orders", value: _vm.value },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ item }) {
          return [
            _c("a", { attrs: { href: `orders/edit/${_vm.value}` } }, [
              _vm._v(
                _vm._s(item.orderID) + ": " + _vm._s(_vm.$td(item.address))
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }