<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :nudge-right="40"
        min-width="290px"
        :return-value.sync="date"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-on="on"
                :label="label"
                v-model="date"
                prepend-icon="event"
                readonly
                :clearable="clearable"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>

import moment from 'moment'

export default {
    props: {
        value: {},
        label: {},
        clearable: { type: Boolean, default: false, },
        local: { type: Boolean, default: false },
    },
    data () {
        return {
            menu: false
        }
    },
    computed: {
        date: {
            get () { 
                if(!this.value) return '';
                if(this.local) {
                    return moment(this.value || new Date()).format('YYYY-MM-DD')
                } else {
                    return moment(this.value || new Date()).utcOffset('+8').format('YYYY-MM-DD')
                }
            },
            set (val) { 
                this.$emit('input', val ? this.local ? moment(val, 'YYYY-MM-DD').toDate() : moment.parseZone(val, 'YYYY-MM-DD').toDate() : '')
            }
        }
    }
}
</script>