
import { Component, Prop, Vue } from "@feathers-client";

@Component
export default class extends Vue {
    @Prop()
    value;

    get color() {
        return this.value.color ?? "#23D7FF";
    }
}
