<template>
<div>
    <template v-if="loading">
        <v-progress-circular indeterminate/>
    </template>
    <template v-else>
        <line-chart :chartData="chartData" :options="{
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }"/>
    </template>
</div>

</template>


<script>

import moment from 'moment'
import LineChart from '~/components/charts/LineChart'

export default {
    components: {
        LineChart
    },
    props: {
        source: {},
        target: {},
        type: {},
    },
    data() {
        return {
            chartData: null,
            loading: true,
        }
    },

    mounted() {
        this.loadData()
    },

    methods: {
        async loadData() {
            const sums = await this.$feathers.service('adLogSums').find({
                query: {
                    source: this.source,
                    target: this.target,
                    type: this.type,
                }
            })

            _.each(sums, sum => sum.index = moment.duration(moment.utc({ year: sum.year, month: sum.month - 1, day: sum.day, hour: sum.hour }).diff(moment.utc({year:1970}))).asHours());
            const minIndex = (_.minBy(sums, it => it.index) || {}).index || 0;
            const maxIndex = (_.maxBy(sums, it => it.index) || {}).index || 0;

            let items = new Array(maxIndex - minIndex + 1);
            if(sums.length) {
                _.each(sums, sum => {
                    items[sum.index - minIndex] = sum;
                })

                for(let i = 0; i < items.length; i++) {
                    if(!items[i]) {
                        const cur = moment.utc({year:1970}).add(i + minIndex, 'hours');
                        // console.log(cur.toDate(), minIndex);

                        items[i] = {
                            year: cur.get('year'),
                            month: cur.get('month') + 1,
                            day: cur.get('day'),
                            hour: cur.get('hour'),
                            total: 0,
                            unique: 0,
                        }
                    }
                }
            } else {
                const cur = moment();
                items = [
                    {
                        year: cur.get('year'),
                        month: cur.get('month') + 1,
                        day: cur.get('day'),
                        hour: cur.get('hour'),
                        total: 0,
                        unique: 0,
                    }
                ]
            }


            this.chartData = {
                labels: items.map(sum => moment.utc({ year: sum.year, month: sum.month - 1, day: sum.day, hour: sum.hour }).local().format("YYYYMMDD HH:00")),
                datasets: [
                    {
                        label: this.$t('charts.unique'),
                        data: items.map(it => it.unique),
                    },
                    {
                        label: this.$t('charts.total'),
                        data: items.map(it => it.total),
                    },
                ]
            }
            this.loading = false;
        }
    }
}
</script>
