var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor-nested",
      class: { "show-separator": _vm.showSeparator },
    },
    [
      _vm.label
        ? _c("div", { staticClass: "p-4 text-grey600 font-bold" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "p-4" },
        [
          _vm.tabView
            ? [
                _c(
                  "div",
                  { staticClass: "d-flex items-center flex-wrap" },
                  [
                    _vm.overview
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color:
                                _vm.editingIdx === -1 ? "primary" : undefined,
                            },
                            on: {
                              click: function ($event) {
                                _vm.editingIdx = -1
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("overview")))]
                        )
                      : _vm._e(),
                    _vm._l(_vm.inputValue, function (item, idx) {
                      return [
                        _c(
                          "div",
                          {
                            key:
                              item[_vm.KeySymbol] || item[_vm.itemKey] || idx,
                            staticStyle: {
                              color: "#bdbdbd",
                              padding: "8px",
                              "padding-bottom": "20px",
                              cursor: "pointer",
                              "margin-right": "8px",
                              "border-bottom": "1px solid black",
                            },
                            style:
                              _vm.editingIdx === idx
                                ? "border-bottom-color: black; color: black;"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.editingIdx = idx
                              },
                            },
                          },
                          [
                            !_vm.headerFields.length
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$td(item.name || item._id)) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "tab-header-item d-flex" },
                              _vm._l(_vm.headerFields, function (header, idx) {
                                return _c(
                                  "span",
                                  { key: idx, staticClass: "d-flex" },
                                  [
                                    header.text
                                      ? _c("div", [
                                          _vm._v(_vm._s(header.text(item))),
                                        ])
                                      : header.path
                                      ? _c("async-populate", {
                                          attrs: {
                                            path: header.path,
                                            value: header.value(item),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$td(item.name)
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          2
                        ),
                        !_vm.readonly && idx === _vm.editingIdx && _vm.remove
                          ? _c(
                              "div",
                              {
                                key: "editing" + idx,
                                staticStyle: {
                                  "box-shadow":
                                    "0px 2px 12px rgba(150, 134, 190, 0.16)",
                                  "border-radius": "8px",
                                  background: "white",
                                  padding: "8px",
                                  cursor: "pointer",
                                  "margin-right": "8px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(_vm.editingIdx)
                                  },
                                },
                              },
                              [_c("remove")],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                    _c("div", { staticClass: "flex-grow-1" }),
                    !_vm.readonly && _vm.add
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "box-shadow":
                                "0px 2px 12px rgba(150, 134, 190, 0.16)",
                              "border-radius": "8px",
                              background: "#e68015",
                              padding: "8px",
                              cursor: "pointer",
                            },
                            on: { click: _vm.addItem },
                          },
                          [_c("add", { staticClass: "h-5 w-5 text-white" })],
                          1
                        )
                      : _vm._e(),
                    _vm._t("add-append"),
                  ],
                  2
                ),
                _vm.editingItem
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-col gap-y-6" },
                      [
                        _vm._t("item", null, {
                          item: _vm.editingItem,
                          edit: _vm.getEdit(_vm.editingItem, _vm.editingIdx),
                        }),
                      ],
                      2
                    )
                  : _vm.editingIdx === -1
                  ? [
                      _vm._t("items", null, {
                        items: _vm.inputValue,
                        formatHeader: _vm.formatHeader,
                      }),
                    ]
                  : _vm._e(),
              ]
            : _c(
                "div",
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "d-flex flex-col",
                      class: _vm.containerClass,
                      attrs: {
                        list: _vm.inputValue,
                        "ghost-class": "ghost",
                        handle: ".handle",
                        animation: 200,
                      },
                    },
                    _vm._l(_vm.inputValue, function (entry, idx) {
                      return _c(
                        "div",
                        {
                          key: entry ? entry._id || entry.id || idx : idx,
                          staticClass:
                            "d-flex list-entry align-center justify-center gap-x-2",
                        },
                        [
                          _vm.editing
                            ? _c("div", { staticClass: "handle-icon handle" })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-grow basis-0 d-flex flex-col gap-y-6",
                              class: { handle: _vm.editing },
                            },
                            [
                              _vm._t("item", null, {
                                item: entry,
                                edit: _vm.getEdit(entry, idx),
                              }),
                            ],
                            2
                          ),
                          _vm.editing
                            ? [
                                !_vm.readonly && _vm.remove
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "p-2 border-grey400 border rounded-lg",
                                        attrs: { role: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(idx)
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "delete-icon",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._t("actions", null, {
                            item: entry,
                            edit: _vm.getEdit(entry, idx),
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  (!_vm.inputValue || !_vm.inputValue.length) && _vm.readonly
                    ? _c("div", [_vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")])
                    : _vm._e(),
                ],
                1
              ),
          !_vm.readonly && _vm.add && !_vm.tabView
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-end justify-center relative gap-x-2 mt-5",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "border-1 rounded-lg border-orange100 text-orange100 py-2.5 px-3 gap-x-2 d-flex items-center",
                      attrs: { role: "button" },
                      on: { click: _vm.addItem },
                    },
                    [
                      _c("add", { staticClass: "text-orange100 w-5 h-5" }),
                      _c("span", { staticClass: "text-orange100" }, [
                        _vm._v(
                          _vm._s(_vm.$t("add")) + _vm._s(_vm.$td(_vm.itemName))
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.inputValue && _vm.inputValue.length && !_vm.readonly
                    ? _c(
                        "div",
                        {
                          staticClass: "!text-orange100 rounded-lg py-2.5 px-3",
                          attrs: { role: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editing = !_vm.editing
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editing
                                  ? _vm.$t("done_reorder")
                                  : _vm.$t("reorder")
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex-grow-1" }),
                  _vm._t("add-append"),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }