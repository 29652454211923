var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.collapse
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-col gap-y-6 border-grey200 border rounded-lg p-3",
          class: {
            "editor-nested p-4":
              _vm.label || (_vm.nameValue && _vm.nameComponent),
          },
        },
        [
          !_vm.editing || _vm.label || (_vm.nameValue && _vm.nameComponent)
            ? _c("div", { staticClass: "d-flex items-center" }, [
                _c("div", { staticClass: "d-flex flex-col gap-y-6" }, [
                  _vm.label || (_vm.nameValue && _vm.nameComponent)
                    ? _c(
                        "div",
                        { staticClass: "font-bold text-grey600" },
                        [
                          _vm.nameValue && _vm.nameComponent
                            ? _c(_vm.nameComponent, {
                                tag: "component",
                                attrs: { value: _vm.nameValue },
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.label))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editing &&
                  _vm.hasPreview &&
                  !_vm.label &&
                  !(_vm.nameValue && _vm.nameComponent)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-[minmax(10rem,1fr),minmax(0,1fr)] gap-x-5 gap-y-4",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              void 0
                            },
                          },
                        },
                        [_vm._t("item", null, { preview: true })],
                        2
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "flex-grow" }),
                !_vm.editing
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex gap-x-2 p-2.5 items-center whitespace-nowrap",
                        attrs: { role: "button" },
                        on: { click: _vm.toggleEdit },
                      },
                      [
                        _c("div", { staticClass: "edit-icon w-5 h-5" }),
                        _c("span", { staticClass: "text-orange400" }, [
                          _vm._v(_vm._s(_vm.$t("basic.edit"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          !_vm.editing &&
          _vm.hasPreview &&
          (_vm.label || (_vm.nameValue && _vm.nameComponent))
            ? _c(
                "div",
                { staticClass: "grid grid-cols-2 gap-y-6" },
                [_vm._t("item", null, { preview: true })],
                2
              )
            : _vm._e(),
          _vm.editing
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-col gap-y-6" },
                  [_vm._t("default")],
                  2
                ),
                _c("div", {
                  staticClass: "my-4 broder border-b-1 border-b-grey100",
                }),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.edit && _vm.edit.remove
                    ? _c(
                        "div",
                        {
                          staticClass: "!text-red100 p-2.5",
                          attrs: { role: "button" },
                          on: { click: _vm.edit.remove },
                        },
                        [_vm._v(_vm._s(_vm.$t("basic.delete")))]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex-grow" }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex gap-x-2 p-2.5 items-center whitespace-nowrap bg-orange400 rounded-lg",
                      attrs: { role: "button" },
                      on: { click: _vm.toggleEdit },
                    },
                    [
                      _c("span", { staticClass: "text-white" }, [
                        _vm._v(_vm._s(_vm.$t("basic.save"))),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _c("div", { staticClass: "editor-nested p-4" }, [
        _c("div", { staticClass: "d-flex flex-column mr-2" }, [
          _vm.label || (_vm.nameValue && _vm.nameComponent)
            ? _c(
                "div",
                {
                  staticClass: "mb-4",
                  staticStyle: {
                    "font-size": "1.125rem",
                    "font-weight": "700",
                  },
                },
                [
                  _vm.nameValue && _vm.nameComponent
                    ? _c(_vm.nameComponent, {
                        tag: "component",
                        attrs: { value: _vm.nameValue },
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.label))]),
                ],
                1
              )
            : _vm._e(),
          _vm.hasPreview
            ? _c(
                "div",
                {
                  staticClass: "mt-5",
                  class: { "group-flex": _vm.flex },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      void 0
                    },
                  },
                },
                [_vm._t("item")],
                2
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "d-flex flex-col gap-y-6" },
          [_vm._t("default")],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }