var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", {
        staticClass: "headline",
        domProps: { textContent: _vm._s(_vm.title) },
      }),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.cancel",
                expression: "'basic.cancel'",
              },
            ],
            attrs: { color: "red darken-1" },
            nativeOn: {
              click: function ($event) {
                return _vm.modalResult(false)
              },
            },
          }),
          _c("v-btn", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "basic.ok",
                expression: "'basic.ok'",
              },
            ],
            attrs: { color: "blue" },
            nativeOn: {
              click: function ($event) {
                return _vm.modalResult(true)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }