var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "action",
          rawName: "v-action.fullscreen",
          value: _vm.fullscreen,
          expression: "fullscreen",
          modifiers: { fullscreen: true },
        },
      ],
    },
    [
      _c(
        "v-fab-transition",
        [
          _vm.$store.state.fullscreen
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    fab: "",
                    fixed: "",
                    bottom: "",
                    right: "",
                  },
                  on: { click: _vm.exitFullscreen },
                },
                [_c("v-icon", [_vm._v("fullscreen_exit")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }