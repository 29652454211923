
import { WorksheetProcessor, SheetRow, WorksheetReader } from '@schemaEditor/importCommon'

export default class MallImportProcessor extends WorksheetProcessor {
    constructor(reader : WorksheetReader, $el? : HTMLElement) {
        super(reader, $el);
    }

    mallHeader : SheetRow = null;
    headerState : string[] = [];

    processRow(row : SheetRow) {
        if(!this.mallHeader) {
            this.mallHeader = row;
            return;
        }

        const firstColIndex = this.mallHeader.values.findIndex(it => !!it);
        let hasHeader = false;
        const curHeader : string[] = [];
        for(let i = 0; i < firstColIndex; i++) {
            const v = this.reader.getCellValue(row.values[i + 1]);
            if(v) {
                curHeader.push(v);
                hasHeader = true;
            } else if(hasHeader) {
                break;
            } else curHeader.push(this.headerState[i] || '');
        }
        this.headerState = curHeader;
        const name = curHeader.filter(it => !!it).join('/');

        for(let i = firstColIndex - 1; i < this.mallHeader.values.length; i++) {
            const v = row.values[i + 1];
            if(v) {
                this.push({
                    values: [
                        '',
                        name + '/' + this.reader.getCellValue(this.mallHeader.values[i + 1]),
                        v,
                    ],
                    header: false,
                    row: 0,
                })
            }
        }
    }

    updateRowStart() {
        this.rowStart = 1;
    }
}

