var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "navbar-item" },
    [
      _c(
        _vm.itemType,
        {
          tag: "component",
          staticClass: "block py-4 relative !text-inherit whitespace-nowrap",
          attrs: { to: _vm.item.href, exact: _vm.item.exact, role: "button" },
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex gap-x-2 items-center item-pad" },
            [
              _c("v-icon", { staticClass: "!w-6 !h-6 !text-inherit" }, [
                _vm._v(_vm._s(_vm.item.action)),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "mini-hide flex-grow text-ellipsis basis-0 overflow-hidden whitespace-nowrap",
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.item.title)) + " ")]
              ),
              _vm.hasChild
                ? _c("div", {
                    staticClass: "mini-hide expand-icon",
                    class: { expanded: _vm.expanded },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.expanded
        ? _c(
            "div",
            _vm._l(_vm.item.items, function (child, idx) {
              return _c("boxs-nav-bar-item", {
                key: child.href || idx,
                attrs: { item: child },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }