var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, bottom: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-badge",
                {
                  attrs: {
                    overlap: "",
                    dot: "",
                    "offset-y": "20",
                    color: "red",
                    value: !_vm.valid,
                  },
                },
                [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "" } }, on),
                    [_c("v-icon", [_vm._v("more_horiz")])],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            "min-width": "200px",
            height: "400px",
            "overflow-y": "auto",
            display: "flex",
          },
        },
        [
          _c(
            "v-card-text",
            {
              staticStyle: {
                "flex-grow": "1",
                overflow: "hidden",
                "flex-basis": "0",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "overflow-y": "auto", "padding-top": "12px" },
                },
                _vm._l(_vm.enumList, function (item) {
                  return _c(
                    "div",
                    { key: item._id },
                    [
                      _c("v-combobox", {
                        attrs: {
                          multiple: "",
                          label: _vm.$td(item.name),
                          outlined: "",
                          chips: "",
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }