
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ImportFileSource, ImportFileData } from './base'

@Component
export default class ImportFolderSource extends mixins(ImportFileSource) {
    type = 'url' as const

    baseUrl = '';
    accessToken = '';    

    @Watch('baseUrl')
    @Watch('accessToken')
    onUpdate() {
        this.reset();
    }

    async prepare() {
        return true;
    }



}

