
import { Component, Vue, Prop } from "@feathers-client";
import { EditorField } from "./plugin";
import components from "./components";

@Component({
  components: components,
})
export default class EditorFieldEdit extends Vue {
  @Prop()
  item: any;

  @Prop()
  field: EditorField;

  @Prop()
  edit: any;

  @Prop()
  slots: any;

  @Prop(Boolean)
  preview: boolean;

  @Prop({ type: String, default: "" })
  parentPath: string;

  get sizes() {
    return this.field.props?.sizes?.length ? [...this.field.props?.sizes, "flex"] : [];
  }

  get condCtx() {
    return {
      item: this.item,
      field: this.field,
    };
  }

  get fullPath() {
    const p = this.field.displayPath || this.field.path;
    return this.parentPath && p ? `${this.parentPath}.${p}` : p ?? this.parentPath ?? "";
  }

  get rootPath() {
    return this.field.rootPath ?? "item";
  }

  get itemValue() {
    return this.rootPath === "item" ? this.item : this[this.rootPath];
  }

  get props() {
    return {
      ...(this.field.propsFunc ? this.field.propsFunc(this.condCtx) : {}),
      ...(this.field.nameField && this.field.name
        ? {
            [this.field.nameField]: typeof this.field.name === 'string' ? this.$t(this.field.name) : this.$td(this.field.name),
          }
        : {}),
      ...this.field.props,
      ...(this.field.component === "editor-group"
        ? {
            edit: this.edit,
          }
        : {}),
    };
  }

  get inputValue() {
    return this.field.path === ""
      ? this.itemValue
      : this.field.path
      ? this.itemValue[this.field.path]
      : null;
  }

  set inputValue(v) {
    this.$emit("updated");
    if (this.field.path === "") {
      this.$emit("update:" + this.rootPath, v);
    } else if (this.field.path) {
      if (this.itemValue) Vue.set(this.itemValue, this.field.path, v);
    }
  }
}
