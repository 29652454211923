var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "51",
        height: "14",
        viewBox: "0 0 51 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0.285767 13.2216C2.12058 13.2216 3.95485 13.2303 5.78966 13.2115C7.54575 13.1932 9.51123 13.1672 11.2202 12.5553C13.0662 11.8941 14.2884 10.7379 15.518 9.37725C16.4033 8.3986 18.1422 6.31058 18.9889 5.30243C19.682 4.47587 21.0385 2.84208 21.7792 2.05163C22.7116 1.05722 23.8641 0.000238419 25.4649 0.000238419C27.0656 0.000238419 28.2176 1.05722 29.1495 2.05062C29.8902 2.84056 31.2467 4.47537 31.9403 5.30142C32.7859 6.30957 34.5249 8.39759 35.4107 9.37624C36.6424 10.7369 37.8624 11.893 39.708 12.5543C41.4174 13.1647 43.3835 13.1921 45.1385 13.2105C46.9738 13.2293 48.8081 13.2206 50.6429 13.2206",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }