import type { ImportContext, ImportConfigTree, SheetRow, ImportFileSource } from "./importCommon";
import type {
  SchemaHelper,
  EditorConfig,
  EditorField,
  ImportOptions,
  SchemaTypeFullJson,
} from "./plugin";
import type { LangType, LangArrType } from "@feathers-client/i18n";
import _ from "lodash";
import { ImportFileData } from "./importFileSource/base";
import crypto from "crypto";
import { getID } from "@feathers-client/util";
import { isTranslate, isEnum } from "./plugin/utils";

export class ImportConfig {
  field: ImportField;
  source: number = -1;
  index?: boolean;
  fileSource?: ImportFileSource;
  linkSource?: ImportContext;
  linkMode?: string;
  linkPath?: string;
  props: any = {};
  temp: any;

  constructor(opts?: Partial<ImportConfig>) {
    if (opts) {
      _.assign(this, opts);
    }
    let temp: any = {};
    Object.defineProperty(this, "temp", {
      enumerable: false,
      get() {
        return temp;
      },
      set(v) {
        temp = v;
      },
    });
  }

  getValue(row: SheetRow) {
    if (this.linkSource && this.linkPath) {
      if (this.linkMode === "single") {
        const res = this.linkSource.importResult[0]?.result;
        return _.get(res, this.linkPath);
      }
    } else if (this.source !== -1) {
      return row.values[this.source];
    }
  }

  getStringValue(row: SheetRow) {
    let value: any = this.getValue(row);
    if (value && typeof value === "object") {
      if (value instanceof Date) return value.toISOString();
      else if (value.richText) {
        return _.map(value.richText, (r) => r.text ?? "").join("");
      } else if (value.formula) {
        if (value.result !== undefined) return `${value.result}`;
        else if (value.formula === "FALSE()") return "false";
        else return value.formula;
      }
    } else {
      return value ?? "";
    }
  }

  getRawValue(row: SheetRow) {
    let value: any = this.getValue(row);
    if (value && typeof value === "object") {
      if (value instanceof Date) return value;
      else if (value.richText) {
        return _.map(value.richText, (r) => r.text ?? "").join("");
      } else if (value.formula) {
        if (value.result !== undefined) return value.result;
        else if (value.formula === "FALSE()") return false;
        else return value.formula;
      }
    } else {
      return value ?? "";
    }
  }
}

export interface PropItem {
  component: string | (() => Promise<any> | any);
  props: any;
  key: string;
}

export class ImportField {
  readonly root!: Vue;
  readonly context!: ImportContext;
  readonly field!: EditorField;
  readonly parent!: ImportField;

  props: Record<string, any> = {};
  flatten = false;

  component: string;
  componentProps: any = {};

  constructor(context: ImportContext, field: EditorField, parent?: ImportField) {
    this.name =
      typeof field.name === "string"
        ? {
            $t: field.name,
          }
        : field.name;
    this.path = field.path;
    Object.defineProperty(this, "root", {
      enumerable: false,
      value: context.$root,
    });
    Object.defineProperty(this, "context", {
      enumerable: false,
      value: context,
    });
    Object.defineProperty(this, "field", {
      enumerable: false,
      value: field,
    });
    Object.defineProperty(this, "parent", {
      enumerable: false,
      value: parent,
    });
    Object.defineProperty(this, "_itemTask", {
      enumerable: false,
      value: null,
      writable: true,
    });
  }

  static create(context: ImportContext, field: EditorField, parent: ImportField) {
    if (field.importer) {
      return field.importer(context, field, parent);
    }
    switch (field.type) {
      case "string": {
        if (field.schema && isEnum(field.schema)) {
          return new ImportEnumField(context, field, parent);
        }
        if (field.props?.editor) {
          return new ImportHTMLField(context, field, parent);
        }
        return new ImportField(context, field, parent);
      }

      case "boolean":
      case "number":
      case "date": {
        return new ImportField(context, field, parent);
      }

      case "id": {
        // lookup
        const refPath = field.schema.params?.ref;
        if (refPath === "Attachment") {
          return new ImportAttachmentField(context, field, parent);
        }
        if (field.props?.multiple) {
          return ArrayImportField.createArray(context, field, parent);
        }
        return new ImportObjectField(context, field, parent);
      }

      case "array": {
        if (isTranslate(field.schema.type)) {
          return new TranslateImportField(context, field, parent);
        }
        return ArrayImportField.createArray(context, field, parent);
      }

      case "object": {
        return new ImportNestedField(context, field, parent);
      }
    }
    return null;
  }

  name: LangType;
  path: string;

  get fullPath() {
    return (this.parent ? this.parent.fullPath + "/" : "") + this.path;
  }

  _items: ImportField[] = [];
  _itemTask: Promise<ImportField[]> | ImportField[];
  getItems(): ImportField[] | Promise<ImportField[]> {
    return undefined;
  }

  resetItems() {
    this._itemTask = null;
    this._items = [];
  }

  async preload() {
    if (!this._itemTask) {
      this._itemTask = this.getItems();
      let v = await this._itemTask;
      if ((v && !v.length) || !v) this._items = undefined;
    }
    return this._itemTask;
  }

  async expand() {
    let v = await this.preload();
    if ((v && !v.length) || !v) v = undefined;
    this._items = v;
  }

  get items(): ImportField[] {
    this.preload();
    return this._items;
  }

  activate() {
    if (this.parent) this.parent.activate();
  }

  convertValue(v: string) : any {
    return v;
  }

  apply(config: ImportConfigTree, item: any, row: SheetRow): void | Promise<void> {
    const v = config.config.getRawValue(row);
    if (v !== "" && v !== undefined) {
      item[this.path] = this.convertValue(v);
    }
  }

  prepare(config: ImportConfigTree, item: any, row: SheetRow): void | Promise<void>;
  async prepare(config: ImportConfigTree, item: any, row: SheetRow) {
    await this.apply(config, item, row);
  }

  async process(config: ImportConfigTree, row: SheetRow, pass: number): Promise<void | boolean> {
    let flag = false;
    if (config.inner) {
      for (let item of config.inner) {
        if (await item.field.process(item, row, pass)) {
          flag = true;
        }
      }
    }
    return flag;
  }

  async post(config: ImportConfigTree, pass: number): Promise<void | boolean> {
    if (config.inner) {
      for (let item of config.inner) {
        await item.field.post(item, pass);
      }
    }
  }

  _names: string[];
  getNames(): string[] {
    if (!this._names) {
      this._names = _.uniq((this.root as any).$i18n.availableLocales)
        .map((locale) => (this.root as any).$td(this.name, locale))
        .filter((it) => !!it && typeof it === "string");
    }
    return this._names;
  }

  suggestInnerName(value: string) {
    const names = this.getNames().map((it) => it.toLowerCase());
    const n = names.find((it) => (value || "").toLowerCase().indexOf(it) !== -1);
    if (n) value = value.substring(n.length);
    else value = value.substring(value.indexOf("/"));

    if (value.startsWith("/")) value = value.substring(1);
    return value;
  }

  checkSuggest(context: ImportContext, value: string) {
    const names = this.getNames()
      .filter((it) => !!it)
      .map((it) => it.toLowerCase());

    if (context.configs.find((it) => it.field === this)) return false;
    if (names.every((it) => (value || "").toLowerCase().indexOf(it) === -1)) return false;
    return true;
  }

  async suggestFallback(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    return null;
  }

  async suggest(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    const items = (await this.preload()) || [];
    if (!this.checkSuggest(context, value)) return;

    if (items.length) {
      // has inner

      const iname = this.suggestInnerName(value);
      for (let item of items) {
        const inner = await item.suggest(context, source, iname);
        if (inner) return inner;
      }

      const fallback = await this.suggestFallback(context, source, iname);
      if (fallback) return fallback;
    } else {
      return new ImportConfig({
        source: source,
        field: this,
      });
    }
  }

  _propList: PropItem[];

  get propList() {
    if (!this._propList) {
      let cur: ImportField = this;
      const props: PropItem[] = [];
      while (cur) {
        props.push(...cur.getProps());
        cur = cur.parent;
      }
      this._propList = props;
    }
    return this._propList;
  }

  getProps(): PropItem[] {
    return [];
  }
}

export class TranslateImportLangField extends ImportField {
  constructor(parent: TranslateImportField, public lang: string, public localeInfo: any) {
    super(parent.context, parent.field, parent);
    this.path = lang;
    this.name = localeInfo ? localeInfo.name || localeInfo : { $t: "basic.allLang" };
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow): Promise<void> {
    const langItem: LangArrType[0] = <any>item;
    const val = config.config.getStringValue(row);
    let v = `${val}`.trim();
    if (v) {
      langItem.lang = this.lang;
      if (this.field.props?.editor) {
        const DOMPurify = await import("dompurify");
        v = DOMPurify.sanitize(v);
      }
      langItem.value = v;
    }
  }

  getNames() {
    if (this.localeInfo) {
      return [this.name, ...(this.localeInfo.sname ? [this.localeInfo.sname] : [])];
    } else {
      return [];
    }
  }
}

const mappedLocales = {
  "zh-hk": "cht",
  "zh-cn": "chs",
  "en-us": "en",
  "en-hk": "en",
  en: "en",
};

export class TranslateImportField extends ImportField {
  constructor(context: ImportContext, field: EditorField, parent: ImportField) {
    super(context, field, parent);
  }
  getItems() {
    return [
      new TranslateImportLangField(this, "all", null),
      ...this.locales.map(([code, info]) => new TranslateImportLangField(this, code, info)),
    ];
  }

  get locales() {
    return _.map(this.root.$store.state.locales || (this.root as any).$i18n.locales, (v, k) => [
      mappedLocales[v.id || v.code || k] || v.id || v.code || k,
      v,
    ]);
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const langType: LangArrType = [];
    for (let inner of config.inner) {
      const langItem: LangArrType[0] = { lang: "", value: "" };
      await inner.field.apply(inner, langItem, row);
      if (langItem.lang) {
        if (langItem.lang === "all") {
          for (let [lang] of this.locales) {
            const current = langType.find((it) => it.lang === lang);
            if (current) {
              current.value = langItem.value;
            } else {
              langType.push(langItem);
            }
          }
        } else {
          const current = langType.find((it) => it.lang === langItem.lang);
          if (current) {
            current.value = langItem.value;
          } else {
            langType.push(langItem);
          }
        }
      }
    }
    if (langType.length) {
      item[this.path] = langType;
    }
  }

  async prepare(config: ImportConfigTree, item: any, row: SheetRow) {
    const conds: any[] = [];
    for (let inner of config.inner) {
      const langItem: LangArrType[0] = { lang: "", value: "" };
      await inner.field.apply(inner, langItem, row);
      if (langItem.lang) {
        if (langItem.lang === "all") {
          conds.push({
            value: langItem.value,
          });
        } else {
          conds.push(langItem);
        }
      }
    }
    if (conds.length) {
      item[this.path] = {
        $elemMatch:
          conds.length > 1
            ? {
                $or: conds,
              }
            : conds[0],
      };
    }
  }

  async suggestFallback(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    const items = await this.preload();
    let locale = (this.root as any).$i18n.locale;
    locale = mappedLocales[locale] || locale;
    const field = items.find((it) => it.path === locale);
    if (!field) return null;
    return new ImportConfig({
      source: source,
      field: field,
    });
  }
}

export class ArrayImportElement extends ImportField {
  constructor(parent: ArrayImportField, public index: number) {
    super(parent.context, parent.field, parent);
    this.path = `${index}`;
    this.name = `${index + 1}`;
  }

  readonly parent!: ArrayImportField;

  createFields() {
    if(this.parent.sharedLinked) {
      this.linked = this.parent.sharedLinked
    }
    else if (this.parent.field.component === "editor-list") {
      this.linkeds = (this.parent.field._inner || this.parent.field.inner)
        .map((it) => ImportField.create(this.context, it, this))
        .filter((it) => !!it);
    } else if (this.parent.field.component?.startsWith?.("editor-object-picker")) {
      this.linked = ImportField.create(
        this.context,
        { ...this.parent.field, props: { ...this.parent.field.props, multiple: false } },
        this,
      );
    } else {
      this.linked = ImportField.create(
        this.context,
        { ...this.parent.field, type: "object" },
        this,
      );
    }
  }

  linked: ImportField;
  linkeds: ImportField[];
  created = false;

  process(config: ImportConfigTree, row: SheetRow, pass: number) {
    if(this.linked) {
      return this.linked.process(config, row, pass);
    }
    return super.process(config, row, pass);
  }

  post(config: ImportConfigTree, pass: number) {
    if(this.linked) {
      return this.linked.post(config, pass);
    }
    return super.post(config, pass);
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    if (this.linked || this.linkeds) {
      const nitem: any = {};
      if(this.parent.sharedLinked) {
        await this.parent.sharedLinked.apply(config, nitem, row);
      } else {
        for (let inner of config.inner) {
          await inner.field.apply(inner, nitem, row);
        }
      }
     
      item[this.path] = this.linked ? nitem[this.linked.path] : nitem;
    } else await super.apply(config, item, row);
  }

  async getItems() {
    if (!this.created) {
      this.created = true;
      this.createFields();
    }
    if (this.linkeds) {
      return this.linkeds;
    } else if (this.linked) {
      return await this.linked.getItems();
    }
  }

  activate() {
    if (this.parent) this.parent.activateChild(this.index);
  }

  async suggestFallback(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    if (this.linked) {
      return this.linked.suggestFallback(context, source, value);
    }
    return null;
  }
}

export class ArrayImportField extends ImportField {
  constructor(context: ImportContext, field: EditorField, parent: ImportField) {
    super(context, field, parent);
    this.addItem();
  }
  isObject = false;
  child: ImportField[] = [];

  sharedLinked: ImportField;

  getItems() {
    return [...this.child];
  }

  activateChild(index: number) {
    if (index + 1 === this.child.length) this.addItem();
  }

  create(): ImportField {
    return new ArrayImportElement(this, this.child.length);
  }

  addItem() {
    this.child.push(this.create());
    this.resetItems();
    this._items = [...this.child];
  }

  static createArray(context: ImportContext, field: EditorField, parent: ImportField) {
    let isObject = false;
    if (field.component === "editor-list") {
      isObject = true;
    } else if (field.component?.startsWith?.("editor-object-picker")) {
      const inner = ImportField.create(
        context,
        { ...field, props: { ...field.props, multiple: false } },
        parent,
      );
      if (!inner) return null;
    } else {
      const inner = ImportField.create(context, { ...field, type: "object" }, parent);
      if (!inner) return null;
      isObject = true;
    }
    const item = new ArrayImportField(context, field, parent);
    item.isObject = isObject;
    return item;
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const items: any[] = [];
    for (let inner of config.inner) {
      const item: any = {};
      await inner.field.apply(inner, item, row);
      const val = item[inner.field.path];

      if (!val || (this.isObject && !Object.keys(val).length)) continue;
      items.push(val);
    }
    if (items.length) {
      item[this.path] = items;
    }
  }

  async suggest(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    if (!this.checkSuggest(context, value)) return;
    const items = (await this.preload()) || [];
    const last = items[items.length - 1];
    const iname = this.suggestInnerName(value);
    let inner: ImportConfig | void;
    if (!isNaN(+iname.split("/")[0])) {
      inner = await last.suggest(context, source, iname.split("/").slice(2).join("/"));
      if (!inner) {
        inner = await last.suggestFallback(context, source, iname.split("/").slice(2).join("/"));
      }
    } else {
      inner = await last.suggest(context, source, iname);
    }
    if (inner) {
      this.addItem();
      return inner;
    }
    return null;
  }
}

export function evalCond(cond: any, item: any) {
  return _.every(cond, (v, k) => {
    if (k.startsWith("$")) {
      switch (k) {
        case "$and":
          return _.every(v, (it) => evalCond(it, item));
        case "$or":
          return _.some(v, (it) => evalCond(it, item));
        default:
          throw new Error(`Unknown operator ${k}`);
      }
    } else {
      if (typeof v === "object" && _.some(_.keys(v), (k) => k.startsWith("$"))) {
        return _.every(v, (vv, kk) => {
          switch (kk) {
            case "$elemMatch":
              return _.some(_.get(item, k), (it) => evalCond(vv, it));
            default:
              throw new Error(`Unknown operator ${kk}`);
          }
        });
      }
      return _.get(item, k) == v; // use == to compare number and string
    }
  });
}

export function getCondKeys(cond: any): string[] {
  return Object.entries(cond).flatMap(([k, v]) => {
    if (k.startsWith("$")) {
      switch (k) {
        case "$and":
        case "$or":
          if (!Array.isArray(v)) return [];
          return v.flatMap((sub) => getCondKeys(sub));
        case "$elemMatch":
          return [];
        default:
          throw new Error(`Unknown operator ${k}`);
      }
    } else {
      if (typeof v === "object" && v && !!Object.keys(v).find((k) => k.startsWith("$"))) {
        return [k.split('.')[0], ...getCondKeys(v)];
      }
      return [k.split('.')[0]];
    }
  });
}

export function getCondSet(conds: any[]) {
  const set = new Set<string>();

  for (let item of conds) {
    for (let key of getCondKeys(item)) {
      set.add(key);
    }
  }
  set.add("_id");

  return set;
}

export async function lookupMulti(context: ImportContext, refPath: string, valueToFind: any[]) {
  let values = [];
  const selectKeys = Array.from(getCondSet(valueToFind));

  try {
    values = await context.$root.$feathers.service(refPath).find({
      query: {
        $or: valueToFind,
        $paginate: false,
        $select: selectKeys,
        $sort: { _id: 1 },
      },
      paginate: false,
    });
  } catch (e) {
    do {
      let resp = await context.$root.$feathers.service(refPath).find({
        query: {
          $or: valueToFind,
          $select: selectKeys,
          $sort: { _id: 1 },
          ...(values.length ? { _id: { $gt: values.at(-1)._id } } : {}),
        },
      });
      if (!Array.isArray(resp) && Array.isArray(resp?.data)) {
        if (!resp.data.length) break;
        values.push(...resp.data);
      } else if (Array.isArray(resp)) {
        if (!resp.length) break;
        values.push(...resp);
      }
    } while (true);
  }

  return values;
}

export abstract class ImportObjectFieldBase extends ImportField {

  refPath: string
  returnObject: boolean

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const dict = config.temp.dict as Record<string, any>;
    const dictValue = config.temp.dictValue as Record<string, string>;

    const nitem: any = await this.getInner(config, row);
    if(!nitem) {
      item[this.path] = null;
      return;
    }
    const key = JSON.stringify(nitem);
    if (key === "{}") {
      item[this.path] = null;
      return;
    }
    const val = dictValue[key];
    if (val === undefined) {
      if (dict[key]) {
        console.warn(
          `Cannot find value for key ${key} @ ${this.root.$schemas.getRefPath(this.field.schema)}`,
        );
        this.context.addIssue({
          row,
          col: config.config?.source,
          id: `${config.path}/${key}`,
          message: `Cannot find value for key ${key} @ ${this.root.$schemas.getRefPath(this.field.schema)}`
        })
      } else {
        console.warn(
          `Missing request for key ${key} @ ${this.root.$schemas.getRefPath(this.field.schema)}`,
        );
      }
      return;
    }
    item[this.path] = val;
  }

  async process(config: ImportConfigTree, row: SheetRow, pass: number) {
    if (!config.temp.posted) {
      let flag = false;
      for (let item of config.inner) {
        if (await item.field.process(item, row, pass)) flag = true;
      }
      if (flag) {
        return flag;
      }
    }
    if (pass === 0) {
      if (!config.temp.dict) {
        config.temp.dict = {};
      }
      if (!config.temp.dictValue) {
        config.temp.dictValue = {};
      }
      if (!config.temp.valueToFind) {
        config.temp.valueToFind = [];
      }
      const dict = config.temp.dict as Record<string, any>;
      const valueToFind = config.temp.valueToFind as any[];
      const item = await this.getInner(config, row);
      if (!item) return;
      const key = JSON.stringify(item);
      if (key === "{}") return;
      const ditem = config.config.props.createIfNotExists
        ? await this.getDetailInner(config, row)
        : true;

      if (!dict[key]) {
        dict[key] = ditem;
        valueToFind.push(item);
      }
      return true;
    }
  }

  async post(config: ImportConfigTree, pass: number) {
    if (!config.temp.posted) {
      let flag = false;
      for (let item of config.inner) {
        if (await item.field.post(item, pass)) flag = true;
      }
      if (flag) {
        return flag;
      }
      config.temp.posted = true;
    }

    const dict = config.temp.dict as Record<string, any>;
    const dictValue = (config.temp.dictValue as Record<string, string>) || {};
    const valueToFind = config.temp.valueToFind || [];

    if (valueToFind.length) {
      const values = await lookupMulti(this.context, this.refPath, valueToFind);

      for (let item of valueToFind) {
        const target = values.find((it) => evalCond(item, it));
        const key = JSON.stringify(item);
        if (target) {
          dictValue[key] = this.returnObject ? target : getID(target);
        } else if (config.config.props.createIfNotExists) {
          const toCreate = dict[key];
          if (toCreate) {
            try {
              const created = await this.context.$root.$feathers.service(this.refPath).create(toCreate);
              dictValue[key] = this.returnObject ? created : getID(created);
            } catch (e) {
              console.warn(e);
            }
          } else {
            console.warn("Missing create options for", key);
          }
        }
      }

      config.temp.valueToFind = [];
    }
  }

  abstract getInner(config: ImportConfigTree, row: SheetRow) : Promise<any>;

  getDetailInner(config: ImportConfigTree, row: SheetRow) {
    return this.getInner(config, row);
  }
}

export class ImportObjectField extends ImportObjectFieldBase {
  constructor(context: ImportContext, field: EditorField, parent?: ImportField) {
    super(context, field, parent);
    const refPath = this.root.$schemas.getRefPath(this.field.schema);
    let refRoute = refPath && this.root.$schemas.routes["/" + refPath + "/"];
    if (refPath && !refRoute) {
      refRoute = this.root.$schemas.getConfigByApiPath(refPath as any);
      if (!refRoute) {
        console.warn("Cannot find route for", refPath);
      }
    }

    Object.defineProperty(this, "refRoute", {
      value: refRoute,
      enumerable: false,
    });

    this.refPath = this.root.$schemas.getRefPath(this.field.schema);
  }

  readonly refRoute!: EditorConfig;

  getItems() {
    if (this.refRoute) {
      return this.refRoute.fields
        .map((field) => ImportField.create(this.context, field, this))
        .filter((it) => !!it);
    }
    return [];
  }

  async getInner(config: ImportConfigTree, row: SheetRow) {
    const item: any = {};
    for (let inner of config.inner) {
      await inner.field.prepare(inner, item, row);
    }
    return item;
  }

  async getDetailInner(config: ImportConfigTree, row: SheetRow) {
    const item: any = {};
    for (let inner of config.inner) {
      await inner.field.apply(inner, item, row);
    }
    return item;
  }

  getProps() {
    return [
      {
        key: this.fullPath + "/",
        component: () => import("./EditorImportObjectProps.vue"),
        props: {
          field: this,
        },
      },
    ] as PropItem[];
  }

  async suggestFallback(
    context: ImportContext,
    source: number,
    value: string,
  ): Promise<ImportConfig | void> {
    const items = await this.preload();
    if (this.refRoute) {
      const mainHead =
        this.refRoute.headers.find((it) => it.text.indexOf("name") !== -1) ||
        this.refRoute.headers[0];
      if (mainHead) {
        const field = this.refRoute.fields.find((it) => it.path === mainHead.value);
        if (field) {
          const f = items.find((it) => it.field === field);
          if (f) {
            let inner = await f.suggest(context, source, value);
            if (!inner) inner = await f.suggestFallback(context, source, value);
            if (!inner && !value.trim()) inner = await f.suggest(context, source, f.getNames()[0]);
            return inner;
          }
        }
      }
    }
    return null;
  }
}

export class ImportAttachmentField extends ImportField {
  constructor(context: ImportContext, field: EditorField, parent?: ImportField) {
    super(context, field, parent);
    this.component = require("./EditorImportFileSelect.vue").default;
  }

  getNames() {
    return ["images", "files", "圖片", "image", "file"];
  }

  async process(config: ImportConfigTree, row: SheetRow, pass: number): Promise<void | boolean> {
    if (pass === 0) {
      const dictMap: Record<string, boolean> = config.temp.dictMap || (config.temp.dictMap = {});
      const val = config.config.getStringValue(row);
      if (val) {
        dictMap[val] = true;
      }
    }
    return false;
  }

  async post(config: ImportConfigTree, pass: number): Promise<void | boolean> {
    if (pass === 0) {
      const dictMap: Record<string, ImportFileData[]> = (config.temp.fileMap = {});
      const paths = Object.keys(config.temp.dictMap);

      const fileToUpload: Set<ImportFileData> = new Set();

      for (let path of paths) {
        const files = await config.config.fileSource?.getFile(path, this.field.props.multiple);
        if(files) {
          for (let f of files) {
            fileToUpload.add(f);
          }
        }
        dictMap[path] = files;
      }

      for (let f of fileToUpload) {
        const hasher = crypto.createHash("sha256");

        if (f.data instanceof Blob) {
          const reader = (f.data.stream() as any as ReadableStream).getReader();
          while (true) {
            const buf = await reader.read();
            if (buf.value) hasher.update(buf.value);
            if (buf.done) break;
          }
        } else {
          hasher.update(f.data);
        }

        const hash = await hasher.digest("hex");

        const current = await (this.root as any).$feathers.service("attachments").find({
          query: {
            hash,
            $limit: 1,
          },
        });
        let attach = current.data[0];
        if (!attach) {
          var data = new FormData();
          const mimeType = f.mime;
          let blob = f.data instanceof Blob ? f.data : new Blob([f.data], { type: mimeType });
          data.append("file", blob, f.name);
          attach = (
            await (this.root as any).$feathers.post(`attachments/upload/imports`, data, {
              params: {
                hash,
              },
            })
          ).data.info;
        }
        f.attachment = attach._id;
      }
    }
    return false;
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const dictMap: Record<string, ImportFileData[]> = config.temp.fileMap;
    const val = config.config.getStringValue(row);

    if (val) {
      const list = dictMap[val];
      if (list) {
        const vv = list.map((it) => it.attachment).filter((it) => !!it);
        item[this.path] = this.field.props.multiple ? vv : vv[0];
      }
    }
  }
}

export class ImportNestedField extends ImportField {
  nesting = true;

  getItems() {
    if (!(this.field._inner || this.field.inner)) return [];
    return (this.field._inner || this.field.inner).map((it) =>
      ImportField.create(this.context, it, this),
    );
  }

  async prepare(config: ImportConfigTree, item: any, row: SheetRow) {
    const nitem: any = {};
    for (let inner of config.inner) {
      await inner.field.prepare(inner, nitem, row);
    }
    for (let [key, value] of Object.entries(nitem)) {
      item[this.nesting && config.path ? config.path + "." + key : key] = value;
    }
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const nitem: any = {};
    for (let inner of config.inner) {
      await inner.field.apply(inner, nitem, row);
    }
    item[config.path] = nitem;
  }

  async process(config: ImportConfigTree, row: SheetRow, pass: number) {
    if (!config.temp.posted) {
      let flag = false;
      for (let item of config.inner) {
        if (await item.field.process(item, row, pass)) flag = true;
      }
      if (flag) {
        return flag;
      }
    }
  }

  async post(config: ImportConfigTree, pass: number) {
    if (!config.temp.posted) {
      let flag = false;
      for (let item of config.inner) {
        if (await item.field.post(item, pass)) flag = true;
      }
      if (flag) {
        return flag;
      }
      config.temp.posted = true;
    }
  }
}

export class ImportEnumField extends ImportField {
  constructor(context: ImportContext, field: EditorField, parent?: ImportField) {
    super(context, field, parent);
    this.component = require("./EditorImportEnum.vue").default;
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    if (!config.temp.dict) {
      let dict = (config.temp.dict = {});
      for (let [k, v] of Object.entries(config.config.props.enums || {})) {
        for (let str of v as any) {
          dict[str.trim().toLowerCase()] = k;
        }
      }
    }
    const v = config.config.getRawValue(row);
    if (v !== "" && v !== undefined) {
      const dict = config.temp.dict as Record<string, string>;
      const e = dict[v.trim().toLowerCase()];
      if (e !== undefined) {
        item[this.path] = e;
      } else {
        console.warn("Missing enum value for", v);
      }
    }
  }
}

export class ImportHTMLField extends ImportField {
  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const v = config.config.getRawValue(row);
    if (v !== "" && v !== undefined) {
      const DOMPurify = await import("dompurify");
      item[this.path] = DOMPurify.sanitize(v);
    }
  }
}

export class ImportLinkedField extends ImportNestedField {
  constructor(context: ImportContext, config: EditorConfig) {
    const linked = config.linkedTable.table
      ? context.$root.$schemas.schemas[config.linkedTable.table]
      : context.$root.$schemas.pathToSchemas[config.linkedTable.service];
    const cfgs: any[] = Array.isArray(linked.params.editor)
      ? linked.params.editor
      : typeof linked.params.editor === "object"
      ? [linked.params.editor]
      : [{}];
    const schema = context.$root.$schemas.convertSchema(
      {
        path: config.linkedTable.service,
        paginate: true,
      },
      linked,
      cfgs[0],
    );

    const newFields = schema.fields.filter((it) => it.path !== config.linkedTable.field);
    const fields = context.$root.$schemas.sortFields(newFields, false);

    super(
      context,
      {
        name: schema.name,
        inner: fields,
        path: "$linked",
      } as any,
      null,
    );

    this.nesting = false;
    this.linkedField = config.linkedTable.field;

    Object.defineProperty(this, "schema", {
      value: schema,
      enumerable: false,
    });
  }

  schema!: EditorConfig;
  linkedField: string;

  async prepare(config: ImportConfigTree, item: any, row: SheetRow) {
    const linked: any = {};
    await super.prepare(config, linked, row);
    if(Object.keys(linked).length) {
      row.linkedIndex = linked;
    }
  }

  async apply(config: ImportConfigTree, item: any, row: SheetRow) {
    const linked: any = {};
    await super.apply(config, linked, row);
    row.linkedResult = linked["$linked"];
  }

  async applyLinked(root: SheetRow, importSession: string, editParams: any) {
    const rows = [...(root.linkedRows || []), root].filter((it) => !!it.linkedResult);

    const lookupRows = rows.filter((it) => !!it.linkedIndex);

    if (lookupRows.length) {
      const seenIds = new Map<string, number[]>();

      for (let row of lookupRows) {
        const item = row.linkedIndex;
        const condKey = JSON.stringify(item);
        const idList = seenIds.get(condKey);
        if (idList) {
          this.context.addIssue({
            message: { $t: "import.duplicatedKey", $ta: { value: condKey } },
            row,
          });
        } else {
          seenIds.set(condKey, [row.row]);
        }
      }

      if (root.sourceID) {
        const values = await lookupMulti(
          this.context,
          this.schema.path,
          lookupRows.map((it) => ({
            ...it.linkedIndex,
            [this.linkedField]: root.sourceID,
          })),
        );
        for (let row of lookupRows) {
          const resp = values.find((it) => evalCond(row.linkedIndex, it));
          if (resp) {
            row.linkedID = resp._id;
          } else {
            row.linkedID = null;
          }
        }
      }
    }

    for (let row of rows) {
      if (!row.linkedResult) {
        row.linkedStatus = "skip";
        continue;
      }
      try {
        row.linkedResult[this.linkedField] = getID(root.result);
        const service = (this.context.$root.$feathers as any).service("imports/entry" as any);
        const result = await service.create({
          service: this.schema.path,
          entry: row.linkedResult,
          query: editParams,
          importSession,
          sourceID: row.linkedID,
        });
        row.linkedResult = result;
        row.linkedStatus = "done";
      } catch (e) {
        row.linkedStatus = "error";
      }
    }

    return root.linkedStatus;
  }
}
