var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    _vm._l(_vm.parts, function (val, idx) {
      return val
        ? _c("span", { key: idx, class: { "text-orange100": idx % 2 === 1 } }, [
            _vm._v(_vm._s(val)),
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }