

import _ from 'lodash'
import moment from 'moment'
import cookie from 'js-cookie';
const expires = 365;

export const state = () => ({
    locales: {
        'en': { name: 'English', id: 'en', locale: 'en_US', code: 'en', region: 'US' },
        'chs': { name: '简体中文', id: 'chs', locale: 'zh_CN', code: 'zh', region: 'CN'  },
        'cht': { sname: '中文', name: '繁體中文', id: 'cht', locale: 'zh_HK', code: 'zh', region: 'HK', regions: ['HK', 'TW'] },
    },
    locale: 'cht',
    title: "Admin",
    args: {},
    actions: [],
    processing: null,
    fullscreen: false,
    fullPage: false,
    noDrawer: false,
    dark: false,
    currency: {
        symbol: '$',
    },
    error: '',
    success: '',

    user: {
        _id: '',
    },
    jwt: '',
    profile: '',
    inited: false,
})


export const getters = {
    margs: state => {
        return _.mapValues(state.args, it => it && it.toString())
    },

    localeCode: state => {
        const item = state.locales[state.locale];
        return item.locale;
    },

    isVerified() {
        return true;
    },

    userId: state => {
        return state.user && state.user._id;
    },

    isAdmin: state => {
        return state.user && state.user.role === 'admin' && state.user.status !== 'frozen';
    },

    mlocaleCode: state => {
        const item = state.locales[state.locale];
        return item.locale.replace('_', '-').toLowerCase();
    },
}

export const mutations = {
    SET_LANG(state, locale) {
        state.locale = locale
    },

    SET_TITLE(state, { title, actions, fullPage, args, noDrawer, dark }) {
        state.title = title || 'Admin';
        state.fullPage = fullPage || false;
        state.actions = actions || [];
        state.args = args || {};
        state.noDrawer = noDrawer || false;
        state.dark = dark || false;
    },

    SET_TITLE_ARGS(state, args) {
        state.args = args || {};
    },

    SET_PROCESSING(state, processing) {
        if (state.processing !== processing)
            state.processing = processing;
    },

    SET_FULLSCREEN(state, fullscreen) {
        state.fullscreen = fullscreen;
    },

    SET_ERROR(state, error) {
        state.error = error;
    },

    SET_SUCCESS(state, success) {
        state.success = success;
    },

    SET_CONNECTED(state, connected) {
        state.connected = connected;
    },

    INIT(state) {
        if(state.inited) return;
        state.inited = true;
        
        const login = localStorage.getItem("login")
        if(login) {
            const info = JSON.parse(login);
            state.user = info.user || {};
            state.jwt = info.jwt;
        }
        const profile = localStorage.getItem("profile")
        state.profile = profile;
    },

    SET_USER(state, user) {
        state.user = user || {};
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_JWT(state, jwt) {
        state.jwt = jwt;
        localStorage.setItem("login", JSON.stringify({
            user: state.user,
            jwt: state.jwt
        }));
    },

    SET_USER_PROFILE(state, profile) {
        state.profile = profile;
        // console.log('set user profile pic to ', (profile.substr(0, 100)), profile.length);
        localStorage.setItem("profile", profile || '');
    },
}

const cookieName = 'locale';

export const actions = {
    initLang({ state, dispatch }, { ctx }) {
        let locale = state.locale;
        const lang = (<any>window.navigator).userLanguage || window.navigator.language;
        const l = _.filter(state.locales, l => l.code === lang)[0];
        if (l) locale = l.id;
        dispatch('changeLang', { id: cookie.get(cookieName) || locale, ctx, init: true });
    },

    async changeLang({ commit, state }, { id, $i18n, $vuetify, init }) {
        const item = state.locales[id];
        if (item) {
            if (!init) {
                cookie.set(cookieName, id, { expires });
            }
            commit('SET_LANG', id);
            if ($i18n) $i18n.locale = id;
            if($vuetify) $vuetify.lang.current = item.vuetify;
            moment.locale(item.locale);
        }
    },



}
