var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("geojson.lat") },
            model: {
              value: _vm.lng,
              callback: function ($$v) {
                _vm.lng = $$v
              },
              expression: "lng",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("geojson.lng") },
            model: {
              value: _vm.lat,
              callback: function ($$v) {
                _vm.lat = $$v
              },
              expression: "lat",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }