<template>
    <v-app
        v-action.fullscreen="fullscreen"
        :light="!dark"
        :dark="dark"
        ref="app"
        style="width: 100%;"
        :class="{'full-page-app': fullPage}"
        @touchstart.native="startMove($event)"
        @touchmove.native="canMove($event)"
        @touchend.native="endMove($event)"
        v-event.openDialog="openDialog"
        v-event.modalResult="modalResult"
    >
        <v-snackbar top v-model="error">
            {{ errorMessage }}
            <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>

        <v-dialog
            v-for="dialog in dialogs"
            :key="dialog.key"
            v-model="dialog.show"
            v-bind="dialog.modal"
        >
            <template v-if="dialog.loading">
                <v-card>
                    <v-card-text style="text-align: center">
                        <v-progress-circular indeterminate color="blue"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <component :is="dialog.component" v-bind="dialog.props" :modalId="dialog.key" />
            </template>
        </v-dialog>

        <v-navigation-drawer
            dark
            persistent
            height="100%"
            style="padding: 0px !important"
            :width="300"
            v-model="drawer"
            :mini-variant.sync="mini"
            enable-resize-watcher
            app
            color="primary"
        >
            <v-layout column fill-height>
                <div :class="['app-logo', {'mini': mini}]">
                    <img src="~/assets/images/icon.png" />
                    <span v-if="!mini" class="app-name primary--text">二四轆</span>
                </div>
                <perfect-scrollbar class="scroll-area" :settings="{suppressScrollX: true}">
                    <v-list dense rounded>
                        <template v-for="(item, n) in items">
                            <v-list-group
                                :prepend-icon="item.action"
                                :key="item.title"
                                v-if="item.items"
                                :group="item.group"
                                color="white"
                                :append-icon="mini ? '' : undefined"
                            >
                                <template v-slot:activator>
                                    <v-list-item-title class="white--text" v-t="item.title"></v-list-item-title>
                                </template>
                                <template v-for="subItem in item.items">
                                    <v-list-item
                                        class="white--text"
                                        :key="subItem.title"
                                        :to="!subItem.target ? subItem.href : undefined"
                                        :href="subItem.target && subItem.href"
                                        :exact="subItem.exact"
                                        :nuxt="!subItem.target"
                                        :disabled="item.disabled || subItem.disabled"
                                        :target="subItem.target"
                                    >
                                        <v-list-item-content v-t="subItem.title"></v-list-item-content>
                                        <v-list-item-action v-if="subItem.action">
                                            <v-icon color="white">{{ subItem.action }}</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list-group>
                            <v-subheader :key="n" v-else-if="item.header" v-t="item.header"></v-subheader>
                            <v-divider :key="n" v-else-if="item.divider" light></v-divider>
                            <template v-else>
                                <v-list-item
                                    :disabled="item.disabled"
                                    :key="item.title"
                                    :to="item.href ? item.href : undefined"
                                    :exact="item.exact"
                                    nuxt
                                    ripple
                                >
                                    <v-list-item-action>
                                        <v-icon>{{ item.action }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-t="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </v-list>
                </perfect-scrollbar>
                <div style="display: flex; align-items: center;" class="justify-center px-2 pt-1">
                    <v-menu offset-y v-model="lang">
                        <template v-slot:activator="{ on }">
                            <v-btn small v-on="on" icon>
                                <v-icon>language</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="item in $store.state.locales"
                                :key="item.id"
                                :value="$store.state.locale === item.id"
                                @click="$store.dispatch('changeLang', {id: item.id, $i18n})"
                            >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-layout>
        </v-navigation-drawer>
        <v-app-bar
            class="top-bar"
            :height="dark ? undefined : 100"
            v-if="!$store.state.fullscreen"
            fixed
            app
        >
            <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
            <v-toolbar-title v-td="{ path: $store.state.title, args: $store.getters.margs }"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-fade-transition v-for="action in $store.state.actions" :key="action.action">
                <b-btn
                    v-if="!action.hide"
                    :alt-icon="action.badge"
                    :alt-text="action.altText ? $td(action.altText) : undefined"
                    :loading="action.action === $store.state.processing"
                    :to="action.to ? action.to : undefined"
                    :disabled="!!$store.state.processing"
                    @click.prevent.stop="action.action && $root.$emit(action.action, action)"
                >
                    <template v-if="action.badge">
                        <v-badge :color="action.badgeColor" left>
                            <v-icon slot="badge">{{action.badge}}</v-icon>
                            <v-icon>{{action.icon}}</v-icon>
                        </v-badge>
                    </template>
                    <template v-else>
                        <v-icon>{{action.icon}}</v-icon>
                    </template>
                </b-btn>
            </v-fade-transition>
        </v-app-bar>
        <v-content :class="{'fill-height': fullPage}">
            <v-container fluid style="height:100%;" class="pt-0">
                <transition name="fade" mode="out-in">
                    <nuxt></nuxt>
                </transition>
            </v-container>
        </v-content>
        <v-fab-transition>
            <v-btn
                @click="exitFullscreen"
                v-if="$store.state.fullscreen"
                dark
                fab
                fixed
                bottom
                right
            >
                <v-icon>fullscreen_exit</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import uuid from "uuid";

export default {
    data() {
        return {
            drawer: null,
            mini: false,
            lang: false,
            dialogs: [],
            schemas: null,
        };
    },
    head() {
        return {
            htmlAttrs: {
                style: this.fullPage
                    ? `overflow: hidden; height: 100%`
                    : "overflow: auto;",
            },
            bodyAttrs: {
                style: this.fullPage ? `overflow: hidden; height: 100%` : "",
            },
        };
    },
    computed: {
        fullPage() {
            return this.$store.state.fullPage;
        },
        mfullscreen() {
            return this.$store.state.fullscreen;
        },
        items() {
            return this.$store.getters["userId"]
                ? [
                      {
                          href: "/appSetting",
                          action: "fa fa-cog",
                          title: "pages.settings.$",
                      },
                      ...this.$schemas.pageList,
                      {
                          href: "/broadcast",
                          action: "fa fa-volume-up",
                          title: "pages.broadcasts.$",
                      },
                    //   {
                    //       href: "/versions",
                    //       action: "fa fa-code-compare",
                    //       title: "pages.versions.$",
                    //   },
                      {
                          href: "/logout",
                          title: "pages.logout",
                          action: "exit_to_app",
                      },
                  ]
                : [
                      {
                          href: "/login",
                          title: "pages.login",
                          action: "vpn_key",
                          exact: false,
                      },
                  ];
        },
        dark() {
            return this.$store.state.dark;
        },
        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },

        errorMessage() {
            return this.$store.state.error;
        },
        ...mapGetters(["attributes", "role"]),
    },
    components: {},
    beforeMount() {
        this.$vuetify.theme.dark = this.dark;
        this.schemas = this.$schemas;
    },
    async mounted() {
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        document.addEventListener(
            "webkitFullscreenchange",
            this.fullscreenChange
        );
        document.addEventListener("gesturestart", this.gesture);

        this.updateFullPage();
        this.$schemas.init();
    },
    beforeDestroy() {
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        document.removeEventListener(
            "webkitFullscreenchange",
            this.fullscreenChange
        );
        document.removeEventListener("gesturestart", this.gesture);
    },
    methods: {
        gesture(e) {
            e.preventDefault();
        },
        fullscreen() {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
                return;
            }
            if (document.webkitFullscreenElement) {
                if (document.webkitFullscreenElement) {
                    document.webkitExitFullscreen();
                    this.$store.commit("SET_FULLSCREEN", false);
                }
            }
            if (!window.navigator.standalone) {
                const elem = this.$refs.app.$el;
                const rfq =
                    elem.requestFullscreen ||
                    elem.webkitRequestFullscreen ||
                    elem.mozRequestFullScreen ||
                    elem.msRequestFullscreen;
                if (!rfq) return;
                rfq.call(elem);
            }
            this.$store.commit("SET_FULLSCREEN", true);
        },

        exitFullscreen() {
            if (document.fullscreenElement && document.exitFullscreen)
                document.exitFullscreen();
            if (
                document.webkitFullscreenElement &&
                document.webkitExitFullscreen
            )
                document.webkitExitFullscreen();
            this.$store.commit("SET_FULLSCREEN", false);
        },

        fullscreenChange(event) {
            this.$store.commit("SET_FULLSCREEN", event.fullscreen);
        },

        startMove(event) {
            if (event.targetTouches.length === 1) {
                this._clientX = event.targetTouches[0].clientX;
                this._clientY = event.targetTouches[0].clientY;
            }
        },

        canMove(event) {
            if (this.$store.state.fullPage) {
                event.stopPropagation();
                if (event.targetTouches.length === 1) {
                    if (
                        this.hasScrollable(
                            event.target,
                            event.targetTouches[0].clientX - this._clientX,
                            event.targetTouches[0].clientY - this._clientY
                        )
                    ) {
                        this._scrolling = true;
                    } else if (!this._scrolling) {
                        event.preventDefault();
                    }
                }
            }
        },

        hasScrollable(target, clientX, clientY) {
            if (
                target.classList.contains("scrollable") ||
                target.classList.contains("v-data-table__wrapper") ||
                target.classList.contains("v-menu__content")
            ) {
                if (
                    target.scrollHeight > target.clientHeight &&
                    Math.abs(clientY) > Math.abs(clientX)
                ) {
                    if (
                        (target.scrollTop > 0 || clientY < 0) &&
                        (target.scrollHeight - target.scrollTop >
                            target.clientHeight ||
                            clientY > 0)
                    ) {
                        return true;
                    }
                }
                if (
                    target.scrollWidth > target.clientWidth &&
                    Math.abs(clientX) > Math.abs(clientY)
                ) {
                    if (
                        (target.scrollLeft > 0 || clientX < 0) &&
                        (target.scrollWidth - target.scrollLeft >
                            target.clientWidth ||
                            clientX > 0)
                    ) {
                        return true;
                    }
                }
            }
            return (
                target.parentElement &&
                this.hasScrollable(target.parentElement, clientX, clientY)
            );
        },

        endMove(event) {
            if (event.targetTouches.length === 0) {
                this._scrolling = false;
            }
        },

        updateFullPage() {
            if (process.server) return;
            const nuxt = document.getElementById("__nuxt");
            if (this.fullPage) nuxt.classList.add("fill-height");
            else nuxt.classList.remove("fill-height");

            const layout = document.getElementById("__layout");
            if (this.fullPage) layout.classList.add("fill-height");
            else layout.classList.remove("fill-height");
        },

        toggleDrawer() {
            if (this.drawer) {
                this.mini = !this.mini;
            } else {
                this.drawer = true;
                this.mini = false;
            }
        },

        openDialog(e) {
            let item;
            const hide = (result, err) => {
                if (!item._show) return;
                item._show = false;

                if (err) {
                    e.reject(err);
                } else {
                    e.resolve(result);
                }

                setTimeout(() => {
                    const idx = this.dialogs.indexOf(item);
                    idx !== -1 && this.dialogs.splice(idx, 1);
                }, 500);
            };
            item = {
                key: uuid(),
                props: e.props,
                loading: false,
                _show: true,
                get show() {
                    return this._show;
                },
                set show(v) {
                    if (!v) {
                        hide();
                    }
                },
                hide,
            };
            if (e.component.then) {
                item.loading = true;
                item.modal = { maxWidth: "100px", persistent: true };
                e.component
                    .then((c) => {
                        item.loading = false;
                        item.modal = e.modal;
                        item.component = c.default || c;
                    })
                    .catch((e) => {
                        console.warn(e);
                        hide(undefined, e);
                    });
            } else {
                item.component = e.component;
                item.modal = e.modal;
            }
            this.dialogs.push(item);
        },

        modalResult(e) {
            const item = this.dialogs.find((it) => it.key === e.id);
            if (item) {
                item.hide(e.result);
            }
        },
    },
    watch: {
        fullPage(val) {
            this.updateFullPage();
        },
        mfullscreen(val) {
            if (val) this.drawer = false;
        },
        drawer(val) {
            if (val && this.mfullscreen) {
                Vue.nextTick(() => {
                    this.drawer = false;
                });
            }
        },
        dark(val) {
            this.$vuetify.theme.dark = val;
            if (val) {
                this.mini = true;
            } else {
                this.mini = false;
            }
        },
    },
};
</script>

<style>
.app-logo {
    height: 56px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: 0.5s all;
    padding-left: 8px;
    background: white;
}

/* .theme--dark .app-logo {
    background: #222222;
} */

.app-logo.mini {
    justify-content: center;
    padding-left: 0px;
}

.app-logo img {
    width: 50px;
}

.app-logo.mini img {
    width: 32px;
}

.app-name {
    padding-left: 8px;
    /* color: black; */
    font-size: 24px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

/* .theme--dark .app-name {
    color: white;
} */

.v-navigation-drawer .v-list__tile::before {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 8px;
    left: 40px;
    background: #626262;
    z-index: 1;
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
}

.v-navigation-drawer .v-list__group__items .v-list__tile::before {
    left: 0px;
}

.v-navigation-drawer .v-list__tile--link:hover,
.v-navigation-drawer .v-list__group--link:hover {
    background: inherit !important;
}

.v-navigation-drawer .v-list__tile:hover::before {
    width: 100px;
    opacity: 1;
    transform: translate(20px);
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter {
    opacity: 0;
    transform: translateY(30px);
}

.fade-leave-active {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
}

.v-applicaiton {
    width: "100%";
}

.full-page-app {
    height: 100%;
}

.full-page-app .v-application--wrap {
    min-height: initial !important;
}

.full-width {
    width: 100%;
}
</style>

<style scoped>
.v-toolbar {
    transition: 0.5s all;
}

.v-list__tile__action {
    justify-content: center;
    min-width: 28px;
    margin-right: 16px;
}

.v-list__tile__content
    ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
    margin-right: 0px;
    margin-left: 16px;
    justify-content: center;
}

.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-grow: 1;
}

.v-navigation-drawer >>> .v-navigation-drawer__content {
    overflow: hidden;
}
</style>
