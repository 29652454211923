
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import EditorQuill from "./EditorQuill.vue";

@Component({
  components: {
    EditorQuill
  }
})
export default class EditorTextField extends Vue {

  @Prop()
  label: string

  @VModel()
  inputValue: string

  @Prop(Boolean)
  multiLine: boolean

  @Prop(Boolean)
  editor: boolean

  @Prop({ type: Number, default: 6 })
  rows: number

  @Prop(Boolean)
  readonly: boolean
}

