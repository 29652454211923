var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "event",
          rawName: "v-event.openDialog",
          value: _vm.openDialog,
          expression: "openDialog",
          modifiers: { openDialog: true },
        },
        {
          name: "event",
          rawName: "v-event.modalResult",
          value: _vm.modalResult,
          expression: "modalResult",
          modifiers: { modalResult: true },
        },
      ],
    },
    [
      _vm._l(_vm.dialogs, function (dialog) {
        return [
          _vm._t(
            "item",
            function () {
              return [
                _c(
                  "v-dialog",
                  _vm._b(
                    {
                      key: dialog.key,
                      model: {
                        value: dialog.show,
                        callback: function ($$v) {
                          _vm.$set(dialog, "show", $$v)
                        },
                        expression: "dialog.show",
                      },
                    },
                    "v-dialog",
                    dialog.modal,
                    false
                  ),
                  [
                    dialog.loading
                      ? [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("v-progress-circular", {
                                    attrs: { indeterminate: "", color: "blue" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            dialog.component,
                            _vm._b(
                              {
                                tag: "component",
                                attrs: { modalId: dialog.key },
                              },
                              "component",
                              dialog.props,
                              false
                            )
                          ),
                        ],
                  ],
                  2
                ),
              ]
            },
            { item: dialog }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }