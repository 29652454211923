<template>
<v-card>
    <v-card-title class="headline" v-text="title"></v-card-title>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" @click.native="modalResult(false)" v-t="'basic.cancel'"></v-btn>
        <v-btn color="blue" @click.native="modalResult(true)" v-t="'basic.ok'"></v-btn>
    </v-card-actions>
</v-card>
</template>

<script>

import Dialog from '~/mixins/Dialog'

export default {
    mixins: [
        Dialog(
        )
    ],
    props: {
        title: {},
    }

}

</script>
