var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-file-input", {
    class: { readonly: _vm.readonly },
    attrs: {
      "show-size": "",
      loading: _vm.loading,
      error: _vm.error,
      clearable: !_vm.readonly,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend-inner",
          fn: function () {
            return [_vm._t("prepend-inner")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v
      },
      expression: "inputValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }