var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "w-full whitespace-nowrap",
      class: [
        _vm.wrap ? "" : "md:(overflow-x-auto overflow-y-hidden) scrollable",
      ],
    },
    [
      _c(
        "transition-group",
        {
          staticClass: "d-flex items-center btn-container <md:(flex-wrap)",
          class: {
            bottomBar: _vm.bottomBar,
            "flex-wrap": _vm.wrap,
            light: _vm.light,
          },
          attrs: { name: "list-complete", tag: "div" },
        },
        [
          _vm._t("items", function () {
            return _vm._l(_vm.items, function (item, idx) {
              return _c(
                "div",
                {
                  key: item._id || idx,
                  staticClass: "py-4",
                  class: [
                    { selected: _vm.isSelected(item), disabled: _vm.disabled },
                    _vm.itemClass,
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.toggleSelected(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "list-item", attrs: { role: "button" } },
                    [
                      _vm.radio
                        ? _c("radio-icon", {
                            staticClass: "mr-2",
                            attrs: { value: _vm.isSelected(item) },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.toggleSelected(item, true)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.check
                        ? _c("check-icon", {
                            staticClass: "mr-2",
                            attrs: { value: _vm.isSelected(item) },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.toggleSelected(item, true)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._t(
                        "itemContent",
                        function () {
                          return [
                            _vm._v(" " + _vm._s(_vm.$td(item.name)) + " "),
                          ]
                        },
                        { item: item, index: idx }
                      ),
                    ],
                    2
                  ),
                ]
              )
            })
          }),
          _vm._t("after"),
          _c(
            "div",
            {
              key: "after",
              staticClass: "d-flex self-end space-x-1.5 py-2 px-1.5",
            },
            [
              _vm.clearable
                ? _c(
                    "div",
                    {
                      key: "clear",
                      staticClass: "text-sm btn-container text-center p-2",
                      class: [
                        { disabled: _vm.disabled, light: _vm.light },
                        _vm.itemClass,
                      ],
                      on: { click: _vm.clearSelected },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-sm text-center rounded-lg px-3 h-10 not-item d-flex items-center",
                          attrs: { role: "button" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("import.clear")) + " ")]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm.bottomBar
            ? _c("div", { key: "append", staticClass: "flex-grow self-end" })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }