var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "container" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.error.statusCode) + " "),
      ]),
      _c("h2", { staticClass: "info" }, [
        _vm._v(" " + _vm._s(_vm.error.message) + " "),
      ]),
      _vm.error.statusCode === 404
        ? _c("nuxt-link", { staticClass: "button", attrs: { to: "/" } }, [
            _vm._v("Homepage"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }