
import moment from "moment";
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID } from "@feathers-client";
import MenuListInput from "@feathers-client/components/MenuListInput.vue";

@Component({
  components: {
    MenuListInput,
  },
})
export default class EditorDatePicker extends Vue {
  @Prop()
  value: string | Date;
  @Prop()
  label: string;

  @Prop({ type: Boolean, default: false })
  dateTime: boolean;

  @Prop({ type: Boolean, default: false })
  utc: boolean;

  @Prop({ type: Boolean, default: false })
  clearable: boolean;

  @Prop(Boolean)
  readonly: boolean;

  currentDate = "";

  onClick(e) {
    if (this.readonly) return;
    if (e.target?.type === "text") {
      this.menu = true;
    }
  }

  beforeMount() {
    this.currentDate = this.value ? moment(this.value).format(this.formatString) : "";
  }

  @Watch("value")
  onValue(o, ov) {
    if (+o === +ov) return;
    this.currentDate = this.value ? moment(this.value).format(this.formatString) : "";
  }

  @Watch("currentDate")
  onCurrentDate(v) {
    if (v === (this.value ? moment(this.value).format(this.formatString) : "")) return;
    const d = moment(this.currentDate, "YYYY-MM-DDTHH:mm");
    if (d.isValid()) {
      this.$emit("input", d.toDate());
    }
  }

  get dateValid() {
    return moment(this.currentDate, "YYYY-MM-DDTHH:mm").isValid();
  }

  menu = false;

  get mutc() {
    return !this.dateTime || this.utc;
  }
  get formatString() {
    return this.dateTime ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD";
  }
  get dateString() {
    return this.value
      ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format(this.formatString)
      : "";
  }
  set dateString(val) {
    this.$emit(
      "input",
      val
        ? (this.mutc
            ? moment.utc(val, this.formatString).startOf("minute")
            : moment(val, this.formatString).startOf("minute")
          ).toISOString()
        : null,
    );
  }
  get date() {
    return this.value
      ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format("YYYY-MM-DD")
      : "";
  }
  set date(val) {
    const t = this.dateTime ? `${val} ${this.time}` : val;
    this.$emit(
      "input",
      val
        ? (this.mutc
            ? moment.utc(t, this.formatString).startOf("minute")
            : moment(t, this.formatString)
          )
            .startOf("minute")
            .toISOString()
        : null,
    );
  }
  get time() {
    return this.value
      ? (this.mutc ? moment(this.value).utcOffset(0) : moment(this.value)).format("HH:mm:ss")
      : "";
  }
  set time(val) {
    const t = `${this.date} ${val}`;
    this.$emit(
      "input",
      val
        ? (this.mutc
            ? moment.utc(t, this.formatString).startOf("minute")
            : moment(t, this.formatString).startOf("minute")
          ).toISOString()
        : null,
    );
  }
}
