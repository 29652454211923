import type {
  SchemaDefParamsService,
  SchemaDefJson,
  SchemaDefParams,
  SchemaFieldJson,
  SchemaTypeFullJson,
  EditorAddonField,
  EditorConfig as DBEditorConfig,
} from "@db/schema";
import expressions from "angular-expressions";

import _ from "lodash";

import { EditorFieldOptions } from "./defs";

export function lookupType(type: string | SchemaTypeFullJson) {
  return typeof type === "string" ? type : type.type;
}

export function isSortable(type: string) {
  switch (type) {
    case "string":
    case "number":
    case "date":
    case "boolean":
      return true;
  }
  return false;
}

export function isTranslate(type: string | SchemaTypeFullJson) {
  if (typeof type === "string") return false;
  if (type.type !== "array") return false;
  const innerType = type.itemType;
  if (!innerType || typeof innerType === "string" || innerType.type !== "object") return false;

  return (
    innerType.fields.find((it) => it.name === "lang" && lookupType(it.type) === "string") &&
    innerType.fields.find((it) => it.name === "value" && lookupType(it.type) === "string")
  );
}

export function isTranslateField(type: string | SchemaTypeFullJson) {
  if (typeof type === "string") return false;
  if (type.type !== "array") return false;
  const innerType = type.itemType;
  if (!innerType || typeof innerType === "string" || innerType.type !== "object") return false;

  return (
    innerType.fields.find((it) => it.name === "lang" && lookupType(it.type) === "string") &&
    innerType.fields.find((it) => it.name === "value")
  );
}

export function isEnum(field: SchemaFieldJson) {
  return lookupType(field.type) === "string" && !!field.params?.enum;
}

export const defHeaders = ["name", "value", "price", "status", "modifiedBy", "modified"];

export const readonlyHeaders = ["modifiedBy", "modified"];

export const colors = ["green", "purple", "indigo", "cyan", "teal", "orange"];
export function stringHash(text: string) {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getSearchColor(value: string) {
  return colors[Math.abs(stringHash(value || "")) % colors.length];
}

export type SchemaFieldJsonWithPath = SchemaFieldJson & { path?: string; headerPath?: string[] };

export function resolveField(
  def: SchemaDefJson,
  path: string,
  resolveArray = false,
): SchemaFieldJsonWithPath {
  if (path === "_id") {
    return {
      path,
      headerPath: [path],
      name: "_id",
      type: "id",
    };
  }
  const parts = path.split(".");
  if (resolveArray && def.schema?.type === "array") {
    const child = resolveField({ schema: def.schema.itemType as any }, path, resolveArray);
    if (!child) return null;
    const subPath = child.headerPath || (child.path || child.name).split?.(".") || [];
    return { ...child, path, headerPath: ["*", ...subPath] };
  }
  const field = def.schema?.fields?.find((it) => it.name === parts[0]);
  if (parts.length > 1) {
    const child = resolveField({ schema: <any>field.type }, parts.slice(1).join("."), resolveArray);
    if (!child) return null;
    const subPath = child.headerPath || (child.path || child.name).split?.(".") || [];
    return { ...child, path, headerPath: [parts[0], ...subPath] };
  }
  return field;
}

export function resolveRoot(def: SchemaDefJson, path: string): SchemaFieldJsonWithPath {
  const parts = path.split(".");
  const headerPath = [];
  const curParts = [];

  let cur: SchemaFieldJsonWithPath = {
    type: def.schema as any,
    path,
    headerPath,
    name: "",
  };

  for (let part of parts) {
    if (typeof cur.type === "object" && cur.type.type === "array") {
      headerPath.push("*");
      cur = {
        type: cur.type.itemType,
        path: curParts.join("."),
        name: "",
      };
    }

    if (part === "$") {
      cur = {
        path: curParts.join("."),
        headerPath,
        name: "",
        type: cur.type,
      };
    } else {
      headerPath.push(part);
      curParts.push(part);
      if (part === "_id") {
        cur = {
          path: curParts.join("."),
          headerPath,
          name: "_id",
          type: "id",
        };
      } else if (typeof cur.type === "object" && cur.type.type === "object") {
        const field = cur.type.fields?.find((it) => it.name === part);
        if (!field) return null;

        cur = {
          path: curParts.join("."),
          headerPath,
          name: "",
          type: field.type,
        };
      } else {
        return null;
      }
    }
  }

  return cur;
}

export function getNameField(def: SchemaDefJson): SchemaFieldJson {
  if (!def.nameField) {
    def.nameField =
      def.schema?.fields?.find(
        (it) => (it.name === "name" && lookupType(it.type) === "string") || isTranslate(it.type),
      ) ||
      def.schema?.fields?.find(
        (it) => (lookupType(it.type) === "string" && !isEnum(it)) || isTranslate(it.type),
      );
    if (def.params?.nameField) {
      const field = resolveField(def, def.params?.nameField);
      if (field) {
        def.nameField = field;
      }
    }
  }

  return def.nameField;
}

export function getNameFields(def: SchemaDefJson): SchemaFieldJson[] {
  if (!def.nameFields) {
    if (def.params?.nameFields) {
      def.nameFields = def.params?.nameFields
        .map((field) => resolveField(def, field))
        .filter((it) => !!it);
    } else {
      def.nameFields = [];
    }
  }

  return def.nameFields;
}

export function getHeaderFields(def: SchemaDefJson): SchemaFieldJson[] {
  if (!def.headerFields) {
    if (def.params?.headerFields) {
      def.headerFields = def.params?.headerFields
        .map((field) => resolveField(def, field))
        .filter((it) => !!it);
    } else {
      def.headerFields = [];
    }
  }
  return def.headerFields;
}

export function getHeaderFieldsByName(def: SchemaDefJson, names: string[]): SchemaFieldJson[] {
  return names.map((field) => resolveField(def, field)).filter((it) => !!it);
}

export function parseEditor(editor: string | EditorFieldOptions) {
  editor = editor || {};
  if (typeof editor === "string") {
    const args = editor.split(" ");
    editor = {};

    const sizes = ["xs", "sm", "md", "lg", "xl"];
    let cur = editor;
    const sub: EditorFieldOptions = {};
    for (let arg of args) {
      if (arg.startsWith(".")) {
        let part = arg.substring(1);
        if (!part) cur = editor;
        else {
          let newVal: EditorFieldOptions = _.get(sub, part) || {};
          if (part[0] === "=") {
            part = part.substring(1);
            _.assign(newVal, editor);
          }
          _.set(sub, part, newVal);
          cur = newVal;
        }
        continue;
      }
      if (sizes.find((s) => arg.startsWith(s))) {
        cur.sizes = cur.sizes || [];
        cur.sizes.push(arg);
        continue;
      }

      if (arg.indexOf("=") !== -1) {
        const k = arg.substring(0, arg.indexOf("="));
        const v = arg.substring(arg.indexOf("=") + 1);
        _.set(cur, k, v);
      } else {
        _.set(cur, arg, true);
      }
    }
    _.assign(editor, sub);
  }

  return editor;
}

export function getDefaultHeaders(cfg: DBEditorConfig, item: SchemaDefJson) {
  const fields = item?.schema?.fields || [];

  return cfg.headers?.length
    ? cfg.headers
        .map((headerPath) => {
          const field = resolveField(item, headerPath, true);
          return field;
        })
        .filter((it) => !!it)
    : fields.filter((it) => {
        const type = lookupType(it.type);
        if (type === "object") return false;

        if (it?.params?.index) {
          return true;
        }
        if (defHeaders.indexOf(it.name) !== -1) return true;
        if (_.indexOf(cfg.headers, it.name) !== -1) return true;
        return false;
      });
}

export function compileProps(result: any, props: any) {
  let dynamic = false;
  const compiled = _.mapValues(props, (v) => {
    if (typeof v === "string" && v.startsWith("$")) {
      dynamic = true;
      return expressions.compile(v.substring(1));
    } else {
      return v;
    }
  });
  if (dynamic) {
    result.propsFunc = (scope) =>
      _.mapValues(compiled, (v) => (typeof v === "function" ? v(scope) : v));
  } else {
    _.assign(result.props, props);
  }
}
