var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "51",
        viewBox: "0 0 16 51",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M15.917 50.3571C15.917 48.5223 15.9275 46.688 15.9048 44.8532C15.8828 43.0971 15.8515 41.1317 15.1149 39.4227C14.3188 37.5766 12.9269 36.3545 11.2888 35.1249C10.1107 34.2396 7.59693 32.5006 6.38323 31.6539C5.38815 30.9609 3.42125 29.6044 2.46965 28.8637C1.27249 27.9313 5.90105e-06 26.7788 5.90105e-06 25.178C5.90105e-06 23.5772 1.27249 22.4253 2.46842 21.4934C3.41942 20.7527 5.38754 19.3962 6.38201 18.7026C7.5957 17.857 10.1094 16.118 11.2876 15.2322C12.9257 14.0004 14.3176 12.7805 15.1136 10.9349C15.8485 9.22544 15.8815 7.25942 15.9036 5.50441C15.9262 3.66906 15.9158 1.83479 15.9158 -2.45571e-05",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }