var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "overlay-color": _vm.overlayColor,
            "overlay-opacity": _vm.overlayOpacity,
            width: _vm.width,
            "max-width": _vm.maxWidth,
          },
          model: {
            value: _vm.isIdle,
            callback: function ($$v) {
              _vm.isIdle = $$v
            },
            expression: "isIdle",
          },
        },
        [
          _vm._t("default", null, {
            continueAction: _vm.continueAction,
            clearAction: _vm.clearAction,
            timeLeftToClear: _vm.timeLeftToClear,
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }