var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-col gap-y-2" },
    [
      _c(
        "div",
        { staticClass: "text-sm text-grey500 d-flex items-center gap-x-1.5" },
        [_c("span", [_vm._v(_vm._s(_vm.label))]), _vm._t("nameAppend")],
        2
      ),
      _c("menu-list-input", {
        staticClass:
          "max-w-100 outline outline-grey200 rounded-lg px-3 py-2.5 text-sm focus:outline-orange100 calendar-picker",
        class: { "text-grey300": !_vm.dateValid },
        attrs: {
          type: _vm.dateTime ? "datetime-local" : "date",
          enterkeyhint: "next",
          placeholder: _vm.label,
          readonly: _vm.menu || _vm.readonly,
          pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.onClick.apply(null, arguments)
          },
          focus: function ($event) {
            return _vm.onClick.apply(null, arguments)
          },
        },
        model: {
          value: _vm.currentDate,
          callback: function ($$v) {
            _vm.currentDate = $$v
          },
          expression: "currentDate",
        },
      }),
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            absolute: "",
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "nudge-right": 40,
            "min-width": "290px",
          },
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: { scrollable: "" },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            [_c("v-spacer")],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dateTime
                        ? _c(
                            "v-flex",
                            [
                              _c("v-time-picker", {
                                attrs: { scrollable: "" },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }