
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class MenuListItem extends Vue {
    @Prop()
    text: string

    @Prop({ default: "w-6 h-6" })
    checkerClass: any

    @VModel()
    inputValue: boolean

    changed = false;

    toggleValue() {
        this.inputValue = !this.inputValue;
        this.changed = true;
    }
}

