var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-col gap-y-2" },
    [
      _vm.label
        ? _c(
            "div",
            {
              staticClass: "text-sm text-grey500 d-flex items-center gap-x-1.5",
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("item-picker", {
        attrs: {
          items: _vm.finalItems,
          light: "",
          multiple: _vm.multiple,
          radio: !_vm.multiple,
          check: _vm.multiple,
          clearable: !_vm.required,
          mandatory: _vm.required,
          readonly: _vm.readonly,
          wrap: "",
          preferSingle: _vm.preferSingle,
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }