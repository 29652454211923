var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "rounded-lg d-flex items-center justify-center space-x-2 p-3 min-w-30 max-w-100",
      class: [_vm.bgClass, { loading: _vm.loading, disabled: _vm.disabled }],
      attrs: { role: "button" },
      on: { click: _vm.click },
    },
    [
      _vm.icon
        ? _c(
            "v-icon",
            { staticClass: "custom-icon w-6 h-6", class: _vm.iconClass },
            [_vm._v(_vm._s(_vm.icon))]
          )
        : _vm._e(),
      _vm.text
        ? _c(
            "div",
            { staticClass: "text-sm font-bold", class: _vm.textClass },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }