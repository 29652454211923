var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "h-full grid grid-cols-[auto,minmax(0,1fr)] relative",
      class: [
        _vm.fullPage
          ? "grid-rows-[auto,1fr]"
          : "grid-rows-[auto,minmax(0,1fr)]",
      ],
      attrs: { root: "" },
    },
    [
      _c(
        "div",
        { staticClass: "row-start-1 col-start-2 sticky top-0 z-1" },
        [_vm._t("appbar")],
        2
      ),
      _c(
        "div",
        { staticClass: "col-start-1 row-start-1 row-span-2" },
        [_vm._t("nav")],
        2
      ),
      _c(
        "main",
        {
          staticClass: "col-start-2 row-start-2 relative d-flex flex-col",
          class: {
            "overflow-hidden": _vm.fullPage,
            "mx-4 my-6": _vm.noPadding,
          },
        },
        [
          _c(
            "div",
            { class: { "flex-grow basis-0 overflow-hidden": _vm.fullPage } },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }