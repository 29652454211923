var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hidden
    ? _c("div")
    : _c(
        "div",
        {
          staticClass: "fill-height",
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            position: "relative",
          },
        },
        [
          _c(
            "div",
            {
              class: [
                "table-container",
                { isMobile: _vm.isMobile, flat: _vm.flat },
              ],
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "overflow-x": "auto",
                "overflow-y": "hidden",
                "flex-grow": "1",
              },
            },
            [
              _vm.mloader
                ? _c("slot-render", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-space-between",
                                  staticStyle: { margin: "24px 24px 0" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _vm.mselect
                                        ? _c("v-checkbox", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              indeterminate:
                                                _vm.selectedIndeterminate,
                                            },
                                            model: {
                                              value: _vm.selectedAll,
                                              callback: function ($$v) {
                                                _vm.selectedAll = $$v
                                              },
                                              expression: "selectedAll",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            `${_vm.$t("basic.totals")}: ${
                                              _vm.mloader.total
                                            } ${_vm.$t("basic.items")}`
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex gap-x-2 items-center",
                                    },
                                    [
                                      _vm.mselect
                                        ? [
                                            _vm.remove
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: _vm.multiDelete,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "pink",
                                                        },
                                                      },
                                                      [_vm._v("delete")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._t("batchActions", null, {
                                              save: _vm.save,
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.multiSelect
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.mselect = !_vm.mselect
                                                  _vm.mselected = []
                                                },
                                              },
                                              model: {
                                                value: _vm.mselect,
                                                callback: function ($$v) {
                                                  _vm.mselect = $$v
                                                },
                                                expression: "mselect",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-check-double"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._t("headerActions", null, {
                                        save: _vm.save,
                                        beginExport: _vm.beginExport,
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    "flex-grow": "1",
                    "flex-basis": "0",
                    overflow: "hidden",
                  },
                },
                [
                  _vm._loader
                    ? _c("virtual-list", {
                        ref: "scroller",
                        staticClass: "scrollable fill-height",
                        staticStyle: { "overflow-y": "auto" },
                        attrs: {
                          "estimate-size": _vm.currentRowSize,
                          "bottom-threshold":
                            (_vm.currentRowSize * _vm.pageCount) / 2,
                          "data-loader": _vm._loader,
                          "data-key": _vm.itemKey,
                          "data-component": _vm.VirtualDataTableRow,
                          "extra-props": _vm.extraProps,
                        },
                        on: {
                          resized: _vm.resize,
                          tobottom: _vm.checkUpdate,
                          scroll: _vm.checkScroll,
                        },
                        nativeOn: {
                          "&scroll": function ($event) {
                            return _vm.onScroll.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _vm._t("before", null, {
                                    context: _vm.context,
                                  }),
                                  !_vm._loader.loading &&
                                  !_vm._loader.store.length
                                    ? _vm._t("noData")
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "sticky",
                              fn: function () {
                                return [
                                  !_vm.isMobile && _vm.header
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "py-2 item-row",
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "word-break": "break-word",
                                            background: "white",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-2 item-cell item-index",
                                              staticStyle: {
                                                "min-width": "50px",
                                                overflow: "hidden",
                                                opacity: "0",
                                              },
                                            },
                                            [_vm._v("000")]
                                          ),
                                          _vm._l(
                                            _vm.headers,
                                            function (header, idx) {
                                              return _c(
                                                "virtual-data-table-header",
                                                {
                                                  directives: [
                                                    {
                                                      name: "press",
                                                      rawName: "v-press",
                                                    },
                                                  ],
                                                  key: idx,
                                                  class: [
                                                    "px-2",
                                                    "item-cell",
                                                    "item-header",
                                                    {
                                                      sortable: header.sortable,
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "180px",
                                                    overflow: "hidden",
                                                  },
                                                  attrs: {
                                                    header: header,
                                                    sort: _vm.sort,
                                                    sortDesc: _vm.sortDesc,
                                                  },
                                                  on: {
                                                    longpress: function (
                                                      $event
                                                    ) {
                                                      return _vm.toggleSort(
                                                        header,
                                                        true
                                                      )
                                                    },
                                                    shortpress: function (
                                                      $event
                                                    ) {
                                                      return _vm.toggleSort(
                                                        header
                                                      )
                                                    },
                                                  },
                                                }
                                              )
                                            }
                                          ),
                                          _vm.appendRow
                                            ? _c("div", {
                                                staticStyle: {
                                                  width: "180px",
                                                  overflow: "hidden",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "footer",
                              fn: function () {
                                return [
                                  _vm._loader.loading
                                    ? _vm._t("loader", function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                display: "flex",
                                                "justify-content": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "lds-ring",
                                                  style: _vm.loaderStyle,
                                                },
                                                [_c("div")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      })
                                    : _vm._e(),
                                  _vm._t("footer"),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.mpager
            ? _c("slot-render", {
                staticStyle: { "align-self": "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "table-container page-control mt-4 py-5 px-8",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    { outBounds: _vm.curPage <= 0 },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage - 1)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    { outBounds: _vm.curPage - 1 <= 0 },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage - 2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.curPage - 1 <= 0
                                        ? ""
                                        : _vm.curPage - 1
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    { outBounds: _vm.curPage <= 0 },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage - 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.curPage <= 0 ? "" : _vm.curPage)
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "active mx-1",
                                  attrs: { icon: "" },
                                },
                                [_vm._v(_vm._s(_vm.curPage + 1))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    {
                                      outBounds: _vm.curPage + 1 >= _vm.pageMax,
                                    },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage + 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.curPage + 1 >= _vm.pageMax
                                        ? ""
                                        : _vm.curPage + 2
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    {
                                      outBounds: _vm.curPage + 2 >= _vm.pageMax,
                                    },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage + 2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.curPage + 2 >= _vm.pageMax
                                        ? ""
                                        : _vm.curPage + 3
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "mx-1",
                                    {
                                      outBounds: _vm.curPage + 1 >= _vm.pageMax,
                                    },
                                  ],
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage(_vm.curPage + 1)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("keyboard_arrow_right"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1058405913
                ),
              })
            : _c(
                "v-fab-transition",
                [
                  _vm.curPage >= 1
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-8 mr-2",
                          attrs: {
                            fab: "",
                            absolute: "",
                            bottom: "",
                            right: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goPage(0)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("arrow_upward")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }