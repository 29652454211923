var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-chip", { attrs: { color: _vm.color, "text-color": "white" } }, [
    _vm._v(" " + _vm._s(_vm.$td(_vm.value.name)) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }