<template>
<div style="margin-left: 8px;">
    <v-switch v-if="def.editor === 'boolean'" :label="name" v-model="inputValue" hide-details>
    </v-switch>
    <v-text-field :clearable="clearable" hide-details v-else-if="def.editor === 'string'" :label="name" v-model="inputValue">
    </v-text-field>
    <v-text-field hide-details v-else-if="def.editor === 'number'" :label="name" v-model.number="inputValue">
    </v-text-field>
    <dim-picker :number="type && type.args && type.args.number" v-else-if="def.editor === 'dim'" standalone :label="name" v-model="inputValue">
    </dim-picker>
    <icon-select 
        v-else-if="def.editor === 'icon'" 
        :label="name" 
        v-model="inputValue"/>
    <template v-else-if="def.editor === 'array'">
        <div>
            <v-layout row align-content-center justify-center align-center v-for="(entry, idx) in inputValue" :key="idx">
                <div style="flex-grow: 1">
                    <constant-select :type="def.elementType" :value="inputValue[idx]" @input="inputValue = [...inputValue.slice(0, idx), $event, ...inputValue.slice(idx + 1)]" />
                </div>
                <v-btn icon @click="inputValue = [...inputValue.slice(0, idx), ...inputValue.slice(idx + 1)]">
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-layout>
        </div>
        <v-btn icon @click="inputValue ? (inputValue = [...inputValue, def.elementType.create()]) : (inputValue = [])"><v-icon>add</v-icon></v-btn>
    </template>
    <template v-else-if="def.editor === 'imagePicker'">
        <image-picker :label="name" v-model="inputValue"/>
    </template>
    <template v-else-if="def.editor === 'videoPicker'">
        <video-picker :label="name" v-model="inputValue"/>
    </template>   
    <template v-else-if="def.editor === 'css'">
        <css-picker :adown.sync="madown" :label="name" v-model="inputValue" :element="element" @focus="$emit('focus', $event)"/>
    </template>  
    <template v-else-if="def.editor === 'object'">
        <object-select :label="name" constant :type="type" v-model="inputValue" :clearable="clearable"/>
    </template>
    <template v-else-if="def.editor === 'enum'">
        <v-select :label="name" v-model="inputValue" :items="getEnum()" :clearable="clearable"/>
    </template>
    <template v-else-if="def.editor === 'multiEnum'">
        <v-input :label="name" v-model="multiValue" class="mt-2">
            <v-btn-toggle multiple v-model="multiValue" class="ml-2" style="flex-grow: 1">
                <v-btn v-for="item in getEnum()" :key="item.value" :value="item.value">{{item.text}}</v-btn>
            </v-btn-toggle>
            <v-btn v-if="clearable" icon @click="inputValue = null"><v-icon>clear</v-icon></v-btn>
        </v-input>
    </template>
    <template v-else-if="def.editor === 'toggle'">
        <v-input :label="name" v-model="inputValue">
            <v-btn-toggle mandatory v-model="inputValue" class="ml-2">
                <v-btn v-for="item in getEnum()" :key="item.value" :value="item.value">{{item.text}}</v-btn>
            </v-btn-toggle>
        </v-input>
    </template>
    <template v-else-if="def.editor === 'page'">
        <page-picker :label="name" v-model="inputValue"/>
    </template>
    <template v-else-if="def.editor === 'color'">
        <color-picker hex :label="name" v-model="inputValue"/>
    </template>
    <template v-else-if="def.editor === 'menuItems'">
        <menus-editor :label="name" :type="type" v-model="inputValue"/>
    </template>
    <template v-else-if="def.editor === 'range'">
        <v-input :label="name" v-model="inputValue">
            <v-range-slider
                :thumb-color="inputValue ? undefined : 'grey'"
                class="mx-3"
                v-model="rangeValue"
                thumb-label
                :min="minValue"
                :max="maxValue"
            ></v-range-slider>
            <v-btn v-if="clearable" icon @click="inputValue = null"><v-icon>clear</v-icon></v-btn>
        </v-input>
    </template>
</div>
</template>

<script>

import _ from 'lodash'
import { Type } from '~/plugins/framework/index'

export default {
    props: {
        'name': {}, 
        'value': {}, 
        'type': {}, 
        'element': {}, 
        'adown': {}, 
        'default': {}, 
        'clearable': { type: Boolean },
    },
    computed: {
        def() {
            return this.$types.resolve(this.type);
        },

        madown: {
            get() { return this.adown },
            set(val) { this.$emit('update:adown', val);}
        },

        inputValue: {
            get() {
                return this.value === undefined ? (this.default || this.def && this.def.default || undefined) : this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },

        multiValue: {
            get() {
                if(!this.inputValue) return [];
                return this.inputValue;
            },
            set(val) {
                if(val.length === 0) this.inputValue = null;
                else this.inputValue = val;
            }
        },

        rangeValue: {
            get() {
                if(!this.inputValue) return [this.minValue, this.maxValue];
                return [
                    this.inputValue.$gte,
                    this.inputValue.$lte,
                ]
            },
            set(val) {
                if(val[0] === this.minValue && val[1] === this.maxValue) {
                    this.inputValue = null;
                } else {
                    this.inputValue = {
                        $gte: val[0],
                        $lte: val[1],
                    }
                }
            },
        },

        minValue() {
            const type = this.type instanceof Type ? this.def : this.def;
            return type && type.args && type.args.min;
        },

        maxValue() {
            const type = this.type instanceof Type ? this.def : this.def;
            return type && type.args && type.args.max;
        },
    },
    methods: {
        getEnum() {
            const type = this.type instanceof Type ? this.def : this.def;
            return _.map(type && type.enums, it => ({
                ...it,
                text: this.$td(it.text)
            }))
        }
    }
}
</script>

<style scoped>

.v-btn-toggle {
    display: flex;
    flex-wrap: wrap;
    background: none;
    box-shadow: none;
}

.v-btn-toggle .v-btn {
    min-width: 100px;
    margin-right: 10px;
}

.v-input >>> .v-label {
    min-width: 100px;
}

</style>
